<div
  *ngFor="
    let option of props.options | formlySelectOptions : field | async;
    let i = index
  "
  class="form-check"
  [class.form-check-inline]="props.formCheck === 'inline'"
>
  <input
    type="radio"
    [id]="id + '_' + i"
    class="form-check-input"
    [name]="field.name || id"
    [class.is-invalid]="showError"
    [attr.value]="option.value"
    [value]="option.value"
    [formControl]="option.disabled ? disabledControl : formControl"
    [formlyAttributes]="field"
  />
  <label class="form-check-label" [for]="id + '_' + i">
    {{ option.label }}
  </label>
</div>

<ng-container *ngIf="otherOptionLabel" [formGroup]="otherOptionForm">
  <div
    class="form-check"
    [class.form-check-inline]="props.formCheck === 'inline'"
  >
    <input
      type="radio"
      [id]="id + '_other'"
      id="other-option"
      class="form-check-input"
      formControlName="toggle"
      [value]="true"
    />
    <label class="form-check-label" [for]="id + '_other'">
      {{ otherOptionLabel }}
    </label>
  </div>

  <div *ngIf="otherOptionToggleControl.value">
    <input
      autocomplete="off"
      formControlName="text"
      class="form-control"
      type="text"
    />
  </div>
</ng-container>
