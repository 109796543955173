<div class="position-absolute"
  style="height: 400px; overflow: auto; width: 240px">
  <ul class="list-group list-group-flush">
    <li *ngFor="let result of results" class="list-group-item bg-light">
      <!-- IF href -->
      <span *ngIf="result.href">
        <a href="{{ result.href }}" class="stretched-link">
          <span class="fw-bold me-2">{{ result.text1 }}</span>
          <span *ngIf="result.text2" class="text-muted">{{ result.text2 }}</span>
        </a>
      </span>
      <!-- IF route -->
      <span *ngIf="result.route">
        <a [routerLink]="result.route" class="stretched-link">
          <span class="fw-bold me-2">{{ result.text1 }}</span>
          <span *ngIf="result.text2" class="text-muted">{{ result.text2 }}</span>
        </a>
      </span>
    </li>
  </ul>
</div>
