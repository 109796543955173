/* eslint-disable @angular-eslint/no-output-native */
import { environment } from '../../../environments/environment';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TemplateHeaderSearchService } from '../_services/template-header-search.service';

@Component({
  selector: 'app-template-header-search',
  templateUrl: './template-header-search.component.html',
  styleUrls: ['./template-header-search.component.css']
})
export class TemplateHeaderSearchComponent implements OnInit {

  /** Search form control */
  public searchField = new UntypedFormControl();
  /** Boolean to trigger searching response in top navbar. */
  public searching = false;
  /** Custom search input field text */
  public searchFieldStringDisplayed: string;
  /** Search action */
  public searchAction = environment.appSpecifics.searchAction;
  /** Search string */
  private searchString = '';

  /** Events */
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();
  @ViewChild('topSearch') topSearch: any;

  /** Watch for outside element click to revert back to icon only display. */
  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
      this.onBlur();
  };

  constructor(
    private elementRef: ElementRef,
    private templateHeaderSearchService: TemplateHeaderSearchService
  ) {
    /** Set the placeholder text. */
    const str = environment.appSpecifics.searchPlaceHolderString;
    this.searchFieldStringDisplayed = str;
    // console.log(typeof this.searchFieldStringDisplayed);
  }

  ngOnInit(): void {

    /** When a text search is performed... */
    this.searchField.valueChanges
    .pipe(debounceTime(500))
    .subscribe((searchStringValue:string) => {
        this.searchString = searchStringValue;
        this.searching = true;
        /** Search string set */
        this.setSearchString({ term: searchStringValue, display: true });
    });

  }

  /** onFocus: Transitions the display fromicon to the full input. */
  public onFocus = () => {
    this.focus.emit();
  };

  /** Clears the search field and reverts back to just displaying the search icon. */
  public onBlur = () => {
    /** Locally */
    this.searching = false;
    /** Globally */
    this.blur.emit();
    /** Enable click detection outside of input to clear the search term */
    if (!environment.appSpecifics.searchKeepStringOnClickOut) {
      this.searchField.setValue({});
    }
  };

  /** Set the search string for other components to use */
  private setSearchString(obj:any) {
    try {
      this.templateHeaderSearchService.setSearching(obj);
    } catch (error) {
      console.log(error);
    }
  }

}
