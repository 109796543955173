export enum Modal {
  ConfirmationModal = 1,
  ChangeProjectStatus,
  Intercept,
  InvalidAnswer,
  InviteRespondent,
  Maintenance,
  ReopenQuestionnaire,
  RepeaterForm,
  ResetProject,
  RespondentBulkOperations,
  RollBackQuestionnaire,
}
