import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { AnswerFormat } from 'src/app/models/enums';

@Component({
  selector: 'app-plain-text-input',
  templateUrl: './plain-text-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainTextInputComponent extends FieldType<FormlyFieldConfig> {
  get format(): AnswerFormat {
    return this.props['format'];
  }

  get multivalue(): boolean {
    return this.props['multivalue'];
  }

  get useIcon(): boolean {
    return this.props['useIconDisplay'];
  }
}
