import { Injectable } from '@angular/core';
import {
  Client,
  ClientWithNameAndNumber,
  Matter,
  MatterWithDescriptionAndNumber,
} from 'src/app/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class EntityMapperService {
  mapClient(client: Client): ClientWithNameAndNumber {
    return {
      ...client,
      clientNameAndNumber: `${client.clientName} [${client.clientNumber}]`,
    };
  }

  mapMatter(matter: Matter): MatterWithDescriptionAndNumber {
    return {
      ...matter,
      matterDescriptionAndNumber: `${matter.matterDescription} [${matter.matterNumber}]`,
    };
  }
}
