import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { Subject, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/models/interfaces';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  compareWith: (o1: any, o2: any) => boolean;
}

@Component({
  selector: 'app-formly-field-select',
  templateUrl: './select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent
  extends FieldType<FieldTypeConfig<SelectProps>>
  implements OnInit, OnDestroy
{
  override defaultOptions = {
    props: {
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
    },
  };
  destroyed$ = new Subject<boolean>();
  formGroup = new FormGroup({
    select: new FormControl<string>('', {
      nonNullable: true,
    }),
    text: new FormControl<string>('', {
      nonNullable: true,
      updateOn: 'blur',
    }),
  });

  get optionValues(): string[] {
    return (this.props.options as DropdownOption[]).map(({ value }) => value);
  }

  get otherOptionLabel(): string {
    return (this.props as any)['otherOptionText'];
  }

  get showOtherOptionText(): boolean {
    return this.otherOptionLabel &&
      this.otherOptionLabel === this.formGroup.controls.select.value
      ? true
      : false;
  }

  ngOnInit(): void {
    // if there is an extra value that is outside
    // of the current options, turn other option toggle on
    const formValue = this.formControl.value as string;
    if (formValue && this.optionValues.includes(formValue)) {
      this.formGroup.controls.select.setValue(formValue);
    } else if (formValue) {
      this.formGroup.controls.select.setValue(this.otherOptionLabel);
      this.formGroup.controls.text.setValue(formValue);
    }

    // Listen to other option select value changes.
    this.formGroup.controls.select.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((text) => {
        if (text !== this.otherOptionLabel) {
          this.formControl.markAsDirty();
          this.formControl.patchValue(text);
          this.formControl.markAsTouched();
        }
      });

    // Listen to other option text value changes.
    this.formGroup.controls.text.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((text) => {
        this.formControl.markAsDirty();
        this.formControl.patchValue(text);
        this.formControl.markAsTouched();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
