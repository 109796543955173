import { Component, OnInit, Output } from '@angular/core';
import { TemplateHeaderSearchService } from '../_services/template-header-search.service';

@Component({
  selector: 'app-template-body-search',
  templateUrl: './template-body-search.component.html',
  styleUrls: ['./template-body-search.component.css']
})
export class TemplateBodySearchComponent implements OnInit {

  @Output() public displayedSearchString = '';
  constructor(
    private templateHeaderSearchService: TemplateHeaderSearchService
  ) {
    //
   }

  ngOnInit(): void {
    this.templateHeaderSearchService._searching$.subscribe((value) => {
      let str = '';
      str = value.term;
      if (str) {
        this.displayedSearchString = value.term;
      // } else {
      //   this.displayedSearchString = '';
      }
    });
  }

  public closeSearchDisplay() {
    this.displayedSearchString = '';
    this.templateHeaderSearchService.setSearching({});
  }

}
