export enum DistributionStatus {
  Empty,
  NeedsContactInfo,
  ReadyToDistribute,
  NotYetStarted,
  PendingResponse,
  ChangesDetected,
  CompleteSigned,
  RequiresResignature,
}
