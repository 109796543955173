<div *ngIf="displayHelp">
  <div *ngIf="linkToEmail">
    <li class="nav-item">
      <a href="mailto:{{ emailAddr }}?subject={{ emailSub }}" role="button" aria-label="Help via email" class="nav-link">
        <svg ibmIconHelp="" fill="currentColor" size="24" xmlns="http://www.w3.org/2000/svg"
          focusable="false" preserveAspectRatio="xMidYMid meet" aria-hidden="true" width="24" height="24"
          viewBox="0 0 32 32">
          <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"></path>
          <circle cx="16" cy="23.5" r="1.5"></circle>
          <path
            d="M17,8H15.5A4.49,4.49,0,0,0,11,12.5V13h2v-.5A2.5,2.5,0,0,1,15.5,10H17a2.5,2.5,0,0,1,0,5H15v4.5h2V17a4.5,4.5,0,0,0,0-9Z">
          </path>
        </svg>
      </a>
    </li>
  </div>
  <div *ngIf="linkToBaseHelp">
    <li class="nav-item">
      <a href="#" class="nav-link" role="menuitem" [attr.aria-label]="'Help'" [routerLink]="['help']"
        routerLinkActive="active">
        <svg ibmIconHelp="" fill="currentColor" size="24"
          xmlns="http://www.w3.org/2000/svg" focusable="false" preserveAspectRatio="xMidYMid meet" aria-hidden="true"
          width="24" height="24" viewBox="0 0 32 32">
          <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"></path>
          <circle cx="16" cy="23.5" r="1.5"></circle>
          <path
            d="M17,8H15.5A4.49,4.49,0,0,0,11,12.5V13h2v-.5A2.5,2.5,0,0,1,15.5,10H17a2.5,2.5,0,0,1,0,5H15v4.5h2V17a4.5,4.5,0,0,0,0-9Z">
          </path>
        </svg>
      </a>
    </li>
  </div>
</div>
