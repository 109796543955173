import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormlyFieldInput } from '@ngx-formly/bootstrap/input';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-date-field-input',
  templateUrl: './date-field-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFieldInputComponent
  extends FormlyFieldInput
  implements OnInit, OnDestroy
{
  destroyed$ = new Subject<boolean>();

  get isDateInput(): boolean {
    const { inputmethod } = this.field.props as any;
    return inputmethod === 'Calendar';
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (!this.isDateInput && value.length === 4) {
          this.formControl.setValue(`${value}-01-01`, {
            emitEvent: false,
            onlySelf: true,
          });
          this.formControl.markAsDirty();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
