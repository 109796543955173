<div class="modal-header border-0 p-3">
  <h5>Unacceptable character detected</h5>
  <button
    aria-label="Close"
    class="btn-close"
    (click)="closeModal()"
    type="button"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="mb-4">
    An unacceptable character has been detected in the text provided. This is
    likely due to copying and pasting text from an external source which is
    causing certain characters to be corrupted. We noticed this primarily with
    hyphens but could affect other characters. The problematic characters have
    been removed from the text and replaced with a space. Please review and edit
    accordingly.
  </div>
  <textarea
    class="form-control"
    [(ngModel)]="sanitizedAnswer"
    rows="6"
  ></textarea>
</div>
<div class="modal-footer border-0">
  <button class="btn btn-primary cx-btn" type="button" (click)="apply()">
    Apply
  </button>
</div>
