import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-change-report-group-wrapper',
  templateUrl: './change-report-group-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeReportGroupWrapperComponent extends FieldWrapper {
  get guidance(): string {
    return this.props['guidance'];
  }

  get groupVisible(): boolean {
    return this.field.fieldGroup?.length
      ? this.field.fieldGroup?.some(({ props }) => !props?.hidden) ?? true
      : true;
  }

  get unchangedGroup(): boolean {
    return Boolean(
      this.field.fieldGroup?.every(
        (fieldConfig) =>
          fieldConfig.props &&
          !fieldConfig.props['hasChanged'] &&
          !fieldConfig.key?.toLocaleString().includes('_repeat') &&
          !(fieldConfig.fieldArray as any)?.fieldGroup?.some(
            (config: any) => config.props['hasChanged']
          )
      )
    );
  }
}
