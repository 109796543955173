import { Component } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-change-report-repeat-group',
  templateUrl: './change-report-repeat-group.component.html',
})
export class ChangeReportRepeatGroupComponent extends FieldArrayType {
  get fieldArray() {
    return this.field.fieldArray as FormlyFieldConfig;
  }

  get summaryCount(): number[] {
    return new Array(this.props['length'] || 0);
  }

  get currentModel() {
    return this.props['currentModel'];
  }

  get previousModel() {
    return this.props['previousModel'];
  }

  get titles(): string[] {
    return this.props['titles'] || [];
  }

  keepOrder(): number {
    return 0;
  }

  repeatValuesAreEqual(i: number): boolean {
    return this.currentModel && this.previousModel
      ? isEqual(this.currentModel[i], this.previousModel[i])
      : false;
  }
}
