<!--
[COMMONUI STARTER TEMPLATE: TEMPLATE ABOUT: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
The CommonUI optionally requires an About page. You may configure the brief text displayed by updating your environment appropriately.

[COMPLIANCE]
Expect this file to be routinely scanned for compliance.

Do not edit or remove this content.

[EXCEPTIONS]
Exceptions to this guidance are not permitted. Simplified - you cannot edit this file.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
-->
<cx1ui-about [aboutText]="aboutText"></cx1ui-about>
