import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { TemplateHeaderService } from 'src/app/_framing/_services/template-header.service';
import { templateUserProfileServiceService } from 'src/app/_framing/_services/template-user-profile-service.service';
import { Roles } from 'src/app/models/enums';
import { Person } from 'src/app/models/interfaces';
import { OdataBackendService } from '..';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private _currentUser = new BehaviorSubject<Person | null>(null);
  get currentUser$(): Observable<Person | null> {
    return this._currentUser.asObservable();
  }
  get currentUser(): Person | null {
    return this._currentUser.getValue();
  }
  set currentUser(value: Person | null) {
    this._currentUser.next(value);
  }

  get isAppAdmin$(): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => user?.appRole === 'AppAdmin'));
  }

  get isClientAdmin$(): Observable<boolean | undefined> {
    return this.currentUser$.pipe(
      map((user) =>
        user?.projectPeople?.some(
          ({ role }) => role?.roleName === Roles.ClientAdmin,
        ),
      ),
    );
  }
  get isClientAdmin(): boolean | undefined {
    return this.currentUser?.projectPeople?.some(
      ({ role }) => role?.roleName === Roles.ClientAdmin,
    );
  }

  get isClientCollaborator$(): Observable<boolean | undefined> {
    return this.currentUser$.pipe(
      map((user) =>
        user?.projectPeople?.some(
          ({ role }) => role?.roleName === Roles.ClientCollaborator,
        ),
      ),
    );
  }
  get isClientCollaborator(): boolean | undefined {
    return this.currentUser?.projectPeople?.some(
      ({ role }) => role?.roleName === Roles.ClientCollaborator,
    );
  }

  get isDelegate$(): Observable<boolean> {
    return this.currentUser$.pipe(
      map((user) => {
        const loggedInPerson = user?.projectPeople.find(
          ({ personId }) => personId === user.id,
        );

        return loggedInPerson?.role?.roleName?.trim()?.toLowerCase() ===
          'respondent delegate'
          ? true
          : false;
      }),
    );
  }

  constructor(
    private headerService: TemplateHeaderService,
    private odataBackendService: OdataBackendService,
    private userProfileService: templateUserProfileServiceService,
  ) {}

  fetchCurrentUser(): Observable<Person | null> {
    return this.odataBackendService
      .getEntity<Person>('CurrentUser', '', {
        expand: {
          projectPeople: {
            expand: ['delegates/respondent/person', 'respondent', 'role'],
          },
        },
      })
      .pipe(
        tap((person) => {
          this.currentUser = person;
          this.headerService.showHeader = true;
          this.userProfileService.currentUserName = person.name;
        }),
        catchError((error) => {
          console.error(error);
          return of(null);
        }),
      );
  }
}
