/** Imports */
import { Component, OnInit } from '@angular/core';
/** Component declaration */
@Component({
  selector: 'app-template-diagnostics',
  templateUrl: './template-diagnostics.component.html',
  styleUrls: ['./template-diagnostics.component.css']
})
/** Class */
export class TemplateDiagnosticsComponent {

}
