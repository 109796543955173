import { Injectable } from '@angular/core';
import { catchError, Observable, of, take } from 'rxjs';
import { Client } from 'src/app/models/interfaces';
import { OdataBackendService } from '..';

@Injectable({
  providedIn: 'root',
})
export class ClientMatterService {
  constructor(private odataBackendService: OdataBackendService) {}

  searchClients(searchTerm: string): Observable<Client[]> {
    return this.odataBackendService
      .getEntitySet<Client>('Clients', {
        filter: {
          or: [
            `contains(clientName, '${searchTerm}')`,
            `contains(clientNumber, '${searchTerm}')`,
          ],
        },
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      );
  }
}
