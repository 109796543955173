/** Imports */
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { templateHeaderMenuService } from '../../_framing/_services/template-header-menu.service';
/** Component definitions */
@Component({
  selector: 'app-base-topnav-menu',
  templateUrl: './base-topnav-menu.component.html',
  styleUrls: []
})
/** Class: BaseTopnavMenuComponent */
export class BaseTopnavMenuComponent implements OnInit {

  /** Menu object */
  private topMenu: unknown;
  /** Route */
  private currentRoute = '';
  /** Constructor */
  constructor(
    /** Menu setting service */
    private templateHeaderMenuService: templateHeaderMenuService,
    private router: Router
  ) {
  /** Subscribe to route changes */
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url.replace('/','').toLowerCase();
        /** Set left menu */
        this.setMenu();
      }
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
      if (event instanceof NavigationError) {
        console.log(event.error);
      }
    });
  }

  /** Lifecycle */
  ngOnInit(): void {
    /** Template menu: replace as needed. */
    this.topMenu = {
      "data": [
        {
          "text": "Discover",
          "route": "discover",
        },
        {
          "text": "Design",
          "route": "design",
        },
        {
          "text": "Develop",
          "route": "develop",
        },
        {
          "text": "Support",
          "route": "support",
        },
      ]};
    /** Set menu */
    // this.setMenu();
  }

  /** Set menu */
  private setMenu() {
    try {
      // this.templateHeaderMenuService.topMenuData = this.topMenu;
      this.templateHeaderMenuService.setTopMenu(this.topMenu);
    } catch (error) {
      console.log(error);
    }
  }
}
