/** Imports */
import { Component, OnInit } from '@angular/core';
import { Offcanvas } from 'bootstrap';
import { templateDeviceDetectionService } from '../_services/template-device-detection.service';
import { environment } from  '../../../environments/environment';
import { templateLeftMenuService } from '../_services/template-left-menu.service';
/** Component declaration */
@Component({
  selector: 'app-template-leftnav',
  templateUrl: './template-leftnav.component.html',
  styleUrls: ['./template-leftnav.component.css']
})
/** Class */
export class TemplateLeftnavComponent implements OnInit {

  /** leftMenuData: Source data for left menu. Static example provided. You may use any methdos appropriate to generate but it must have the same JSON format. */
  private leftMenuData: unknown; // Type required
  /** leftNav: This is the object used to populate the component. */
  public leftNav: any;
  /** Boolean used to hold off displaying the menu until ready. */
  public display = false;
  /** Is mobile device? */
  public isMobile = false;
  /** Displayed title in offcanvas */
  public offCanvasTitle = '';
  /** Construct */
  constructor(
    /** Template service */
    private templateDeviceDetectionService: templateDeviceDetectionService,
    private templateLeftMenuService: templateLeftMenuService
  ) {
    //
  }

  /** Lifecycle */
  ngOnInit(): void {
    /** Required */
    this.setOffCanvasTitle();
    this.isMobile = this.templateDeviceDetectionService.isMobile();
    /** Required */
    try {
      this.templateLeftMenuService._leftMenuData$.subscribe((value) => {
        if (value) {
          this.processLeftMenu(value);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /** Sets the OffCanvas title if left menu is enabled in environment.  */
  private setOffCanvasTitle() {
    /** You can replace this string value sourcing with a custom service/other if needed. */
    this.offCanvasTitle = environment.appSpecifics.title;
  }
  /** Process left menu for display */
  private processLeftMenu(t: any) {
    this.leftNav = t.data;
    this.display = true;
  }
  /** Click event for left menu */
  public processMenuClickEvent(event: unknown) {
    /**
     * Place any additionally needed methods/calls/etc.here.
     * You do NOT need to add anything extra if all you wish to do is have a clickable left menu.
     * If you do, place your additional code below the comment /** PLACE ANY OF YOUR ADDITIONAL CODE BELOW HERE * /
     */
    /** This is required. Do not remove. */
    this.toggleLeftNavbar();
  }

  /** Toggle left offcanvas menu closed if in mobile */
  public toggleLeftNavbar() {
    try {
      const leftNavOffcanvas = <HTMLElement>document.getElementById('offcanvasLeftNavMobile');
      const lmOffcanvas = new Offcanvas(leftNavOffcanvas);
      lmOffcanvas.hide()
    } catch (error) {
      // Ignore
    }

  }

  /** ============================================ */
  /** PLACE ANY OF YOUR ADDITIONAL CODE BELOW HERE */
  /** ============================================ */

}
