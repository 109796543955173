import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { IconsModule } from 'src/app/core/icons.module';
import { TOAST_DATA } from 'src/app/core/services';
import { ToastData } from 'src/app/models/interfaces';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IconsModule],
})
export class ToastComponent {
  closed$ = new Subject<boolean>();

  constructor(@Inject(TOAST_DATA) public data: ToastData) {}

  close(): void {
    this.closed$.next(true);
  }
}
