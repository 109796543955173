<div class="modal-header p-3">
  <div>
    <div class="mb-2 small">Term definition</div>
    <h5 class="text-capitalize">{{ term }}</h5>
  </div>
  <button aria-label="Close" class="btn-close" (click)="close()" type="button">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div [innerHtml]="description"></div>
</div>
