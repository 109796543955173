import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from '@progress/kendo-file-saver';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResourcesService } from 'src/app/core/services';
import { GlossaryTermModalComponent } from '..';

@Directive({
  selector: '[appGlossaryTerm]',
})
export class GlossaryTermDirective implements OnInit, OnDestroy {
  private element?: HTMLElement;
  private observer?: MutationObserver;

  constructor(
    private bsModalService: BsModalService,
    private elementRef: ElementRef,
    private resourceService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.element = this.elementRef.nativeElement;

    if (this.element) {
      this.handleMoreInfoNodes();
      this.handleExhbitLinks();

      this.observer = new MutationObserver(() => {
        this.handleMoreInfoNodes();
        this.handleExhbitLinks();
      });

      this.observer.observe(this.element, { childList: true });
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  /**
   * Find and convert more info nodes.
   */
  private handleMoreInfoNodes(): void {
    this.element?.querySelectorAll('more, morei').forEach((node) => {
      const term = node.getAttribute('open');
      const description = node.innerHTML;
      if (term && description) {
        node.innerHTML = term;
        node.setAttribute('role', 'button');
        node.setAttribute('title', 'View term definition');
        node.classList.add('text-decoration-underline', 'text-primary');
        node.addEventListener('click', () =>
          this.bsModalService.show(GlossaryTermModalComponent, {
            class: 'modal-dialog-centered',
            initialState: { term, description },
          })
        );
      }
    });
  }

  /**
   * Find exhibit links and replace their click handler.
   */
  private handleExhbitLinks(): void {
    this.element?.querySelectorAll('[data-exhibit-id]').forEach((node) => {
      const exhibitId = node.getAttribute('data-exhibit-id');
      if (exhibitId) {
        node.addEventListener('click', (event) => {
          event.preventDefault();
          this.resourceService
            .downloadResource(exhibitId)
            .subscribe((data) => saveAs(data, node.textContent || exhibitId));
        });
      }
    });
  }
}
