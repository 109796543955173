import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IconsModule } from 'src/app/core/icons.module';
import { ActivePageService } from 'src/app/core/services';
import { Modal } from 'src/app/models/enums';

@Component({
  selector: 'app-invalid-answer-modal',
  templateUrl: './invalid-answer-modal.component.html',
  standalone: true,
  imports: [FormsModule, IconsModule],
})
export class InvalidAnswerModalComponent {
  @Input() question = '';
  @Input() sanitizedAnswer: string | null = '';

  constructor(
    private activePageService: ActivePageService,
    private bsModalService: BsModalService
  ) {}

  apply(): void {
    this.activePageService.validationFix$.next([
      this.question,
      this.sanitizedAnswer || '',
    ]);
    this.closeModal();
  }

  closeModal(): void {
    this.bsModalService.hide(Modal.InvalidAnswer);
  }
}
