/** Imports */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root'
})
/** Class */
export class TemplateHeaderSearchService {

  /** Search string */
  private _searchingSource$ = new BehaviorSubject<any>({});
  _searching$ = this._searchingSource$.asObservable();

  /** Search results: top links */
  private _searchingResultsLinks$ = new BehaviorSubject<any>({});
  _searchResultsLinks$ = this._searchingResultsLinks$.asObservable();

  /** Constructor */
  constructor( ) {
    //
  }

  /** Search string */
  setSearching(searching: any) {
    this._searchingSource$.next(searching);
  }

  /** Search results: top links */
  setSearchResultsLinks(resultsLinks: any) {
    this._searchingResultsLinks$.next(resultsLinks);
  }

}
