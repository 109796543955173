import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormlyFieldInput } from '@ngx-formly/bootstrap/input';

@Component({
  selector: 'app-numeric-field-input',
  templateUrl: './numeric-field-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumericFieldInputComponent extends FormlyFieldInput {
  get hasCurrencyPrefix(): boolean {
    const { defaultformat } = this.field.props as any;
    return defaultformat === 'currencydigits';
  }
}
