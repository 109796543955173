import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-questionnaire-group-wrapper',
  templateUrl: './questionnaire-group-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireGroupWrapper extends FieldWrapper {
  get guidance(): string {
    return this.props['guidance'];
  }

  get groupVisible(): boolean {
    return this.field.fieldGroup?.length
      ? this.field.fieldGroup?.some(
          // Previous version prior to fixing #28078
          // ({ fieldGroup, props }) => !props?.hidden || fieldGroup?.length
          ({ props }) => !props?.hidden
        ) ?? true
      : true;
  }
}
