import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  Observable,
  take,
  tap,
  throwError,
} from 'rxjs';
import { OdataBackendService } from 'src/app/core/services';
import { ProjectType } from 'src/app/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProjectTypeService {
  private _projectTypes$ = new BehaviorSubject<ProjectType[]>([]);
  get projectTypes$(): Observable<ProjectType[]> {
    return this._projectTypes$.asObservable();
  }
  set projectTypes(value: ProjectType[]) {
    this._projectTypes$.next(value);
  }

  constructor(private odataBackendService: OdataBackendService) {}

  fetchProjectTypes(): Observable<ProjectType[]> {
    return this.odataBackendService
      .getEntitySet<ProjectType>('ProjectTypes', {
        orderBy: [['projectTypeName', 'desc']],
      })
      .pipe(
        take(1),
        tap((projectTypes) => (this.projectTypes = projectTypes)),
        catchError((error) => throwError(error)),
      );
  }
}
