import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import { Modal } from 'src/app/models/enums';

@Component({
  selector: 'app-repeat-form',
  templateUrl: './repeat-form.component.html',
  styleUrls: ['./repeat-form.component.scss'],
})
export class RepeatFormComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  fields: FormlyFieldConfig[] = [];
  form = new FormGroup({});
  model: QuestionnaireFormModel = {};
  onSave$ = new Subject<QuestionnaireFormModel>();
  operation: 'add' | 'update' = 'add';
  options: FormlyFormOptions = {
    showError: () => false,
  };

  get modalTitle(): string {
    return this.operation === 'update' ? 'Edit entry' : 'Add new entry';
  }

  get submitLabel(): string {
    return this.operation === 'update' ? 'Update' : 'Add';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.cdr.detectChanges());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  closeModal() {
    this.modalService.hide(Modal.RepeaterForm);
  }

  save() {
    this.onSave$.next(this.model);
    this.closeModal();
  }
}
