import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplatesecondaryHeaderDisplayedService {

  /** Secondary header display observable */
  public _secondaryHeaderDisplayedSource$ = new BehaviorSubject<any>({});
  public _secondaryHeaderDisplayedData$ = this._secondaryHeaderDisplayedSource$.asObservable();
  /** Constructor */
  constructor() {
    //
  }

  /** Set the left menu obj value */
  toggleSecondaryHeaderDisplay(display: boolean) {
    console.log(display);
    this._secondaryHeaderDisplayedSource$.next(display);
  }


}
