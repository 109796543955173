/** Imports */
import { Component, OnInit } from '@angular/core';
import { environment } from  '../../../environments/environment';
/** Component declaration */
@Component({
  selector: 'app-template404',
  templateUrl: './template404.component.html',
  styleUrls: ['./template404.component.css']
})
/** Class */
export class Template404Component implements OnInit {

  /** Displayed error message */
  public errorStr!: string;

  /** Constructor */
  constructor() {
    //
   }

  /** Lifecycle */
  ngOnInit(): void {
    /** Set custom error message if available */
    this.errorStr = environment.appSpecifics.errorString;
  }

}
