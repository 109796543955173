import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateHeaderService {
  private readonly _showHeader = new BehaviorSubject<boolean>(false);
  get showHeader$() {
    return this._showHeader.asObservable();
  }
  get showHeader() {
    return this._showHeader.getValue();
  }
  set showHeader(value: boolean) {
    this._showHeader.next(value);
  }
}
