import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Type,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { ALL_OPTION_TEXT } from 'src/app/core/services/contract-express/contract-express.service';
import { DropdownOption } from 'src/app/models/interfaces';

interface MultiCheckboxProps extends FormlyFieldProps {
  formCheck: 'default' | 'inline' | 'switch' | 'inline-switch';
}

export interface FormlyMultiCheckboxFieldConfig
  extends FormlyFieldConfig<MultiCheckboxProps> {
  type: 'multicheckbox' | Type<MultiCheckboxComponent>;
}

@Component({
  selector: 'app-formly-field-multicheckbox',
  templateUrl: './multicheckbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiCheckboxComponent
  extends FieldType<FieldTypeConfig<MultiCheckboxProps>>
  implements OnInit, OnDestroy
{
  override defaultOptions = {
    props: {
      formCheck: 'default' as const, // 'default' | 'inline' | 'switch' | 'inline-switch'
    },
  };
  destroyed$ = new Subject<boolean>();
  otherOptionForm = new FormGroup({
    toggle: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
    text: new FormControl<string>('', {
      nonNullable: true,
      updateOn: 'blur',
    }),
  });

  get otherOptionLabel(): string {
    return (this.props as any)['otherOptionText'];
  }

  get otherOptionTextControl() {
    return this.otherOptionForm.controls.text;
  }

  get otherOptionToggleControl() {
    return this.otherOptionForm.controls.toggle;
  }

  get optionValues(): string[] {
    return (this.props.options as DropdownOption[])
      .filter(({ value }) => value !== ALL_OPTION_TEXT)
      .map(({ value }) => value);
  }

  get unknownOptionText(): string {
    return (this.props as any)['unknownoptiontext'];
  }

  ngOnInit(): void {
    // if there are extra values that are outside
    // of the current options, turn other option toggle on
    const formValues = this.formControl.value as string[];
    const extraValue = Array.isArray(formValues)
      ? formValues?.find((value) => !this.optionValues.includes(value))
      : null;
    if (extraValue && !this.otherOptionToggleControl.value) {
      this.otherOptionToggleControl.setValue(true);
      this.otherOptionTextControl.setValue(extraValue);
    }

    // If this field doesn't have unknown option text,
    // then null value is not allowed and should be filtered out.
    if (
      !this.unknownOptionText &&
      Array.isArray(formValues) &&
      formValues?.some((v) => v === null)
    ) {
      this.formControl.setValue(formValues.filter((v) => v !== null));
    } else if (!this.unknownOptionText && !formValues) {
      this.formControl.setValue([]);
    }

    // mark control as dirty in order to save default value
    // into database and CE.
    if (formValues === undefined) {
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }

    // Listen to other option toggle value changes.
    this.otherOptionToggleControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((enabled) => {
        if (!enabled) {
          this.otherOptionTextControl.reset();
        }
      });

    // Listen to other option text value changes.
    this.otherOptionTextControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((text) => {
        const values = (this.formControl.value || []) as string[];
        const selectedOptions = values.filter((value) =>
          this.optionValues.includes(value)
        );
        this.formControl.markAsDirty();
        this.formControl.patchValue(
          text ? [...selectedOptions, text] : selectedOptions
        );
        this.formControl.markAsTouched();
      });

    // Listen to other option form value changes.
    this.otherOptionForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ toggle, text }) => {
        if (toggle && !text) {
          this.formControl.setErrors({ otherOptionRequired: true });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onChange(value: any, checked: boolean) {
    if (value === ALL_OPTION_TEXT) {
      this.formControl.markAsDirty();
      this.formControl.patchValue(checked ? this.optionValues : []);
      this.formControl.markAsTouched();
      return;
    }

    this.formControl.markAsDirty();
    if (this.props.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...(this.formControl.value || []), value]
          : [...(this.formControl.value || [])].filter((o) => o !== value)
      );
    } else {
      this.formControl.patchValue({
        ...this.formControl.value,
        [value]: checked,
      });
    }
    this.formControl.markAsTouched();
  }

  isChecked(option: any) {
    const value = this.formControl.value;

    if (option.value === ALL_OPTION_TEXT) {
      return this.optionValues.every((o) => (value || []).includes(o));
    }

    if (option.value === null && value?.indexOf(null) === 0) {
      return true;
    }

    return (
      value &&
      (this.props.type === 'array'
        ? value.indexOf(option.value) !== -1
        : value[option.value])
    );
  }

  isDisabled(option: any) {
    // Don't disable options, if there is no unknown option text (value === null).
    if (this.optionValues.every((optionValue) => optionValue !== null)) {
      return false;
    }

    const value = this.formControl.value;

    if (!value || value.length === 0) {
      return false;
    }

    const hasNull = value.indexOf(null) !== -1;
    return (hasNull && option.value !== null) ||
      (!hasNull && option.value === null)
      ? true
      : false;
  }
}
