import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/** Template route modules */
import { Template404Component } from './_template-route-404/template404.component';
import { TemplateAboutComponent} from  './_template-route-about/template-about.component';
import { TemplateDiagnosticsComponent } from './_template-route-diagnostics/template-diagnostics.component';
import { TemplateHomeComponent } from './_template-route-home/template-home.component';
import { TemplateBodyHelpComponent } from './_template-body-help/template-body-help.component';
import { TemplateThemeDemoHtmlComponent } from './_template-theme-demo-html/template-theme-demo-html.component';

/** Template routes */
const routes: Routes = [
  { path: '', component: TemplateHomeComponent }, // Required - do not remove or edit
  { path: 'about', component: TemplateAboutComponent }, // Required - do not remove or edit
  { path: 'themehtml', component: TemplateThemeDemoHtmlComponent }, // Required - do not remove or edit
  { path: 'diagnostics', component: TemplateDiagnosticsComponent }, // Required - do not remove or edit
  { path: 'help', component: TemplateBodyHelpComponent }, // Required - do not remove or edit
  { path: 'home', component: TemplateHomeComponent }, // Required - do not remove or edit
  { path: '**',  component: Template404Component } // Required - do not remove or edit
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommonUITemplateRoutingModule {}
