<div
  *ngFor="let s of summaryCount; let i = index; let count = count"
  class="border-bottom row"
  [ngClass]="{
    'alert alert-info': !repeatValuesAreEqual(i),
    'unchanged-group': repeatValuesAreEqual(i)
  }"
>
  <div class="col">
    <h6 class="my-3 text-muted">{{ i + 1 }} of {{ count }}</h6>
    <div
      *ngFor="
        let item of fieldArray.fieldGroup | keyvalue : keepOrder;
        let j = index
      "
      class="row mb-2"
    >
      <div class="col-8">{{ titles[j] }}</div>
      <div class="col-1"></div>
      <div class="col-3">
        <ng-container
          *ngTemplateOutlet="
            changeValues;
            context: {
              hasChanges: !repeatValuesAreEqual(i),
              current:
                (currentModel && currentModel[i]
                  ? currentModel[i][$any(item.value).key]
                  : null
                ) | answer,
              previous:
                (previousModel && previousModel[i]
                  ? previousModel[i][$any(item.value).key]
                  : null
                ) | answer
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template
  #changeValues
  let-current="current"
  let-previous="previous"
  let-hasChanges="hasChanges"
>
  <div *ngIf="previous">
    <del class="text-muted" [class.d-none]="previous === current">
      {{ previous }}
    </del>
  </div>
  <div *ngIf="current">
    {{ current }}
    <span *ngIf="current === previous && hasChanges" class="text-muted">
      (no changes)
    </span>
  </div>
</ng-template>
