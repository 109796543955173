import { Component, OnInit } from '@angular/core';
import { TemplateHeaderSearchService } from '../../_framing/_services/template-header-search.service';

@Component({
  selector: 'app-base-search-links',
  templateUrl: './base-search-links.component.html',
  styleUrls: ['./base-search-links.component.css']
})
export class BaseSearchLinksComponent implements OnInit {

  public searchString: any;
  public exampleResultsLinks:any;
  constructor(
    private templateHeaderSearchService: TemplateHeaderSearchService
  ) {
    // this.exampleResultsLinks = [
    //   {
    //     "text1": "Res 1a",
    //     "text2": "1b" + this.searchString,
    //     "route": "home",
    //   },
    //   {
    //     "text1": "Res 2a",
    //     "text2": "2b" + this.searchString,
    //     "route": "home",
    //   },
    //   {
    //     "text1": "Res 3a",
    //     "text2": "3b" + this.searchString,
    //     "route": "home",
    //   }
    // ]
    /** Subscribe to on searching */
      this.templateHeaderSearchService._searching$.subscribe((search) => {
        if (search.term) {
          console.log(search);
          this.searchString = search.term;
          this.exampleResultsLinks = [
            {
              "text1": "Res 1a",
              "text2": "1b - " + this.searchString,
              "route": "home",
            },
            {
              "text1": "Res 2a",
              "text2": "2b - " + this.searchString,
              "route": "home",
            },
            {
              "text1": "Res 3a",
              "text2": "3b - " + this.searchString,
              "route": "home",
            }
          ]
          console.log(this.exampleResultsLinks);
          this.templateHeaderSearchService.setSearchResultsLinks(this.exampleResultsLinks);
        }
      });
  }

  ngOnInit(): void {
    //
  }

}
