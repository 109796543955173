import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-template-header-help',
  templateUrl: './template-header-help.component.html',
  styleUrls: ['./template-header-help.component.css']
})
export class TemplateHeaderHelpComponent implements OnInit {

  /** Flag to display help  */
  public displayHelp = false;
  private envHelp:any;
  /** Displays a link with a mailto */
  public linkToEmail = false;
  /** Or app base help component */
  public linkToBaseHelp = false;
  /** Email address and subject */
  public emailAddr = '';
  public emailSub = '';
  constructor() {
    //
   }

  ngOnInit(): void {
    this.envHelp = environment.appSpecifics.displayTopHelp;
    if (this.envHelp.display) {
      /** Set help display */
      this.processHelp();
    }
  }

  /** processHelp */
  private processHelp(){
    try {
      if (this.envHelp.action === 'email') {
        /** Set the email addr and sub */
        this.emailAddr = this.envHelp.emailAddr;
        this.emailSub = this.envHelp.emailSub;
        this.linkToEmail = true
        this.displayHelp = true;
      } else if (this.envHelp.action === 'link') {
        /** Display the base help component */
        this.linkToBaseHelp = true
        this.displayHelp = true;
      } else {
        this.displayHelp = false;
      }

    } catch (error) {
      this.displayHelp = false;
      console.log(error);
    }

  }

}

    // // Help display in top nav
    // displayTopHelp: {
    //   display: true, // REQUIRED: boolean: determines if the help icon is displayed in the top nav
    //   action: 'email', // REQUIRED: two possible values "email" or "link" - If email uses the next two values and will open an email on click. If "link" on click opens the base-help component.
    //   emailAddr: 'whom@cooley.com', // REQUIRED: email address for anyone who has questions to use.
    //   emailSub: 'Email subject goes here' // REQUIRED: Pre generated email subject text.
    // }
