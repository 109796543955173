import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, of, take } from 'rxjs';
import { AccessTokenResponse, AuthPolicies } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  apiUrl = environment.appLeveragedApiEndpoints.apiUrl;

  constructor(private http: HttpClient) {}

  getLoginPolicies(personEmail: string): Observable<AuthPolicies> {
    return this.http
      .post<AuthPolicies>(`${this.apiUrl}/auth/policies`, { personEmail })
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          // 503 - Maintenance mode
          if (error?.status === 503) {
            throw error;
          }

          console.error(error);
          return of({ policies: [] });
        })
      );
  }

  loadAccessToken(key: string): Observable<AccessTokenResponse> {
    return this.http
      .get<AccessTokenResponse>(`${this.apiUrl}/auth/${key}`)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      );
  }

  requestMagicLink(personEmail: string): Observable<unknown> {
    return this.http
      .post(`${this.apiUrl}/magiclink/invite`, { personEmail })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return of(false);
        })
      );
  }
}
