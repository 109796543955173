import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, retry, Subject, takeUntil } from 'rxjs';
import { MaintenanceService } from 'src/app/core/services';

@Component({
  selector: 'app-maintenance-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './maintenance-modal.component.html',
})
export class MaintenanceModalComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  retryAfter = 10;

  constructor(private maintenanceService: MaintenanceService) {}

  ngOnInit() {
    this.startHeartbeat();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private startHeartbeat(): void {
    this.maintenanceService
      .heartbeat()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.headers.has('Retry-After')) {
            this.retryAfter = Number(error.headers.get('Retry-After'));
          }
          throw error;
        }),
        retry({ delay: this.retryAfter * 1000 }),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.maintenanceService.deactivate());
  }
}
