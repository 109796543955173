import { Injectable } from '@angular/core';
import { endOfMonth, setDayOfYear, startOfMonth, subMonths } from 'date-fns';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { EventFilters } from 'src/app/features/admin/events/events.component';
import { PeopleFilters } from 'src/app/features/admin/people/people.component';
import { ProjectFilters } from 'src/app/features/admin/projects/projects.component';
import {
  ActivityLog,
  DropdownOption,
  Person,
  Project,
  ProjectType,
  Respondent,
} from 'src/app/models/interfaces';
import { OdataBackendService } from '..';

export const PAGE_SIZE = 50;

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly _peopleCount = new BehaviorSubject<number>(0);
  get peopleCount$() {
    return this._peopleCount.asObservable();
  }
  get peopleCount() {
    return this._peopleCount.getValue();
  }
  set peopleCount(value: number) {
    this._peopleCount.next(value);
  }

  private readonly _projectsCount = new BehaviorSubject<number>(0);
  get projectsCount$() {
    return this._projectsCount.asObservable();
  }
  get projectsCount() {
    return this._projectsCount.getValue();
  }
  set projectsCount(value: number) {
    this._projectsCount.next(value);
  }

  private readonly _projectTypesCount = new BehaviorSubject<number>(0);
  get projectTypesCount$() {
    return this._projectTypesCount.asObservable();
  }
  get projectTypesCount() {
    return this._projectTypesCount.getValue();
  }
  set projectTypesCount(value: number) {
    this._projectTypesCount.next(value);
  }

  constructor(private odataBackend: OdataBackendService) {}

  deleteProject(projectId: string) {
    return this.odataBackend.deleteEntity('Projects', projectId);
  }

  fetchActivityLog(logId: string): Observable<ActivityLog | null> {
    return this.odataBackend
      .getEntitySet<ActivityLog>('ActivityLogs', {
        expand: ['activityByPerson', 'project', 'project/client'],
        filter: { id: { eq: { type: 'guid', value: logId } } },
      })
      .pipe(
        map(([log]) => log),
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
  }

  fetchActivityLogsCount(filters?: EventFilters): Observable<number> {
    const filter = this.mapEventFiltersToQueryOptions(filters);
    return this.odataBackend.getEntitySetCount('ActivityLogs', { filter }).pipe(
      catchError((error) => {
        console.error(error);
        return of(0);
      })
    );
  }

  fetchActivityLogs(
    page: number = 0,
    filters?: EventFilters
  ): Observable<ActivityLog[]> {
    const filter = this.mapEventFiltersToQueryOptions(filters);
    return this.odataBackend
      .getEntitySet<ActivityLog>('ActivityLogs', {
        expand: {
          activityByPerson: {},
          project: {
            expand: ['client'],
          },
        },
        filter,
        top: PAGE_SIZE,
        skip: page * PAGE_SIZE,
        orderBy: 'activityDate desc',
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      );
  }

  fetchEventTypeOptions(): Observable<DropdownOption[]> {
    return this.odataBackend
      .getEntitySet('ActivityLogs', {
        transform: {
          filter: {
            not: {
              or: [
                {
                  activitySummary: {
                    contains: ' at ',
                  },
                },
                {
                  activitySummary: {
                    contains: ' on ',
                  },
                },
              ],
            },
          },
          groupBy: {
            properties: ['activitySummary'],
          },
        },
      })
      .pipe(
        map((results) =>
          (results as Array<{ activitySummary: string }>).map(
            ({ activitySummary }) => ({
              label: activitySummary,
              value: activitySummary,
            })
          )
        )
      );
  }

  fetchPeopleCount(filters?: PeopleFilters): Observable<number> {
    const filter = this.mapPeopleFiltersToQueryOptions(filters);
    return this.odataBackend.getEntitySetCount('People', { filter }).pipe(
      tap((count) => (this.peopleCount = count)),
      catchError((error) => {
        console.error(error);
        return of(0);
      })
    );
  }

  fetchPeople(page: number = 0, filters?: PeopleFilters): Observable<Person[]> {
    const filter = this.mapPeopleFiltersToQueryOptions(filters);
    return this.odataBackend
      .getEntitySet<Person>('People', {
        select: ['id', 'name', 'primaryEmail'],
        expand: {
          projectPeople: {
            select: ['projectId'],
            expand: [
              { project: { select: ['projectName'] } },
              { role: { select: 'roleName' } },
            ],
          },
        },
        filter,
        top: PAGE_SIZE,
        skip: page * PAGE_SIZE,
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      );
  }

  fetchPerson(personId: string) {
    return this.odataBackend
      .getEntity<Person>('People', personId, {
        select: ['name', 'primaryEmail'],
        expand: {
          projectPeople: {
            select: ['projectId'],
            expand: [
              {
                project: {
                  select: ['projectName'],
                  expand: {
                    client: {
                      select: 'clientName',
                    },
                  },
                },
              },
              {
                role: {
                  select: 'roleName',
                },
              },
            ],
          },
        },
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
  }

  fetchProject(projectId: string) {
    return this.odataBackend
      .getEntity<Project>('Projects', projectId, {
        expand: {
          client: {},
          projectPeople: {
            expand: ['person'],
          },
        },
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
  }

  fetchProjectRespondents(projectId: string): Observable<Respondent[] | null> {
    return this.odataBackend
      .getEntitySet<Respondent>('Respondents', {
        filter: {
          projectId: { eq: { type: 'guid', value: projectId } },
          questionnaireStatus: {
            in: [
              'InProgress-NotYetStarted',
              'InProgress',
              'Complete',
              'CompleteSigned',
            ],
          },
        },
        expand: ['person'],
        orderBy: 'questionnaireStatus desc',
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      );
  }

  fetchProjectsCount(filters?: ProjectFilters): Observable<number> {
    const filter = this.mapProjectFiltersToQueryOptions(filters);
    return this.odataBackend.getEntitySetCount('Projects', { filter }).pipe(
      tap((count) => (this.projectsCount = count)),
      catchError((error) => {
        console.error(error);
        return of(0);
      })
    );
  }

  fetchProjects(
    page: number = 0,
    filters?: ProjectFilters
  ): Observable<Project[]> {
    const filter = this.mapProjectFiltersToQueryOptions(filters);
    return this.odataBackend
      .getEntitySet<Project>('Projects', {
        expand: {
          activityLogs: {
            select: ['activityByPerson'],
            expand: { activityByPerson: { select: 'name' } },
            orderBy: 'activityDate',
            top: 1,
          },
          client: {},
        },
        filter,
        top: PAGE_SIZE,
        skip: page * PAGE_SIZE,
        orderBy: 'created desc',
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      );
  }

  fetchProjectTypesCount(query?: string): Observable<number> {
    const filter = query ? { projectTypeName: { contains: query } } : undefined;
    return this.odataBackend.getEntitySetCount('ProjectTypes', { filter }).pipe(
      tap((count) => (this.projectTypesCount = count)),
      catchError((error) => {
        console.error(error);
        return of(0);
      })
    );
  }

  fetchProjectTypes(
    page: number = 0,
    query?: string
  ): Observable<ProjectType[]> {
    const filter = query ? { projectTypeName: { contains: query } } : undefined;
    return this.odataBackend
      .getEntitySet<ProjectType>('ProjectTypes', {
        filter,
        top: PAGE_SIZE,
        skip: page * PAGE_SIZE,
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      );
  }

  updateProject(
    projectId: string,
    body: Partial<Project>
  ): Observable<Project> {
    return this.odataBackend.patchEntity<Project>('Projects', projectId, body);
  }

  private mapEventFiltersToQueryOptions(filters?: EventFilters) {
    const { client, date, eventType } = filters || {};
    const and = [];
    if (client) {
      and.push({
        project: {
          client: {
            clientNumber: client.clientNumber,
          },
        },
      });
    }
    if (date) {
      if (date === 'thisMonth') {
        and.push({
          activityDate: { ge: startOfMonth(new Date()) },
        });
      } else if (date === 'lastMonth') {
        and.push({
          activityDate: {
            ge: startOfMonth(subMonths(new Date(), 1)),
            le: endOfMonth(subMonths(new Date(), 1)),
          },
        });
      } else if (date === 'ytd') {
        and.push({
          activityDate: { ge: setDayOfYear(new Date(), 1) },
        });
      }
    }
    if (eventType && eventType !== 'All event types') {
      and.push({ activitySummary: eventType });
    }
    return { and };
  }

  private mapPeopleFiltersToQueryOptions(filters?: PeopleFilters) {
    const { search, role, client } = filters || {};
    const and = [];
    if (search) {
      and.push({
        or: [
          { name: { contains: search } },
          { primaryEmail: { contains: search } },
        ],
      });
    }
    if (role) {
      and.push({
        projectPeople: {
          any: {
            role: {
              roleName: role,
            },
          },
        },
      });
    }
    if (client) {
      and.push({
        projectPeople: {
          any: {
            project: {
              client: {
                clientNumber: client.clientNumber,
              },
            },
          },
        },
      });
    }
    return { and };
  }

  private mapProjectFiltersToQueryOptions(filters?: ProjectFilters) {
    const { client, date, employee, isTest } = filters || {};
    const and = [];
    if (client) {
      and.push({
        projectPeople: {
          any: {
            project: {
              client: {
                clientNumber: client.clientNumber,
              },
            },
          },
        },
      });
    }
    if (date) {
      if (date === 'thisMonth') {
        and.push({
          created: { ge: startOfMonth(new Date()) },
        });
      } else if (date === 'lastMonth') {
        and.push({
          created: {
            ge: startOfMonth(subMonths(new Date(), 1)),
            le: endOfMonth(subMonths(new Date(), 1)),
          },
        });
      } else if (date === 'ytd') {
        and.push({
          created: { ge: setDayOfYear(new Date(), 1) },
        });
      }
    }
    if (employee) {
      and.push({
        createdByUser: employee.id,
      });
    }
    if (isTest !== null) {
      and.push({ isTest });
    }

    return { and };
  }
}
