import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QuestionnaireFormModel } from 'src/app/models/aliases';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryPageComponent {
  @Input() fields: FormlyFieldConfig[] = [];
  @Input() model: QuestionnaireFormModel = {};
  @Input() title: string | null = '';
}
