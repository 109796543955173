/** Imports */
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { templateTitleService } from '../_services/template-title.service';
/** Component declaration */
@Component({
  selector: 'app-template-about',
  templateUrl: './template-about.component.html',
  styleUrls: ['./template-about.component.css']
})
/** Class */
export class TemplateAboutComponent implements OnInit {

  /** Displayed text */
  public aboutText = '';
  public commonUIenvConfig: any;
  /** Constructor */
  constructor(
    private templateTitleService: templateTitleService
  ) {
    //
  }

  /** Lifecycle */
  ngOnInit(): void {
    /** Update page title */
    this.templateTitleService.setTitle('About');
    /** Set displayed text */
    this.aboutText = environment.appSpecifics.description;
    this.commonUIenvConfig = environment.commonUIenvConfig;
  }

}
