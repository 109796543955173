import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { take } from 'rxjs';
import { clearAccessToken } from 'src/app/core/auth/auth.helpers';
import { CurrentUserService } from 'src/app/core/services';
import { templateUserProfileServiceService } from '../_services/template-user-profile-service.service';

@Component({
  selector: 'app-template-header-user',
  templateUrl: './template-header-user.component.html',
  styleUrls: ['./template-header-user.component.css'],
})
export class TemplateHeaderUserComponent {
  currentUserName$ = this.userProfileService.currentUserName$;
  isAppAdmin$ = this.currentUserService.isAppAdmin$;

  constructor(
    private authService: MsalService,
    private currentUserService: CurrentUserService,
    private userProfileService: templateUserProfileServiceService
  ) {}

  logout() {
    // If a user is authenticated with a magic link, clear its toekn.
    clearAccessToken();

    // If a user is authenticated with MSAL, call logout.
    this.authService.logout().pipe(take(1)).subscribe();
  }
}
