import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { delayWhen, filter, of, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getActiveAccount } from './core/auth/auth.helpers';
import { EventsService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  envConfig = environment;

  constructor(
    private authService: MsalService,
    private eventsService: EventsService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.getAuthenticatedAccount();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getAuthenticatedAccount(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.destroyed$),
        filter((status) => status === InteractionStatus.None),
        delayWhen(() =>
          this.authService.instance.getAllAccounts().length > 0
            ? this.eventsService.trackLogin()
            : of(true)
        )
      )
      .subscribe(() => getActiveAccount(this.authService.instance));
  }
}
