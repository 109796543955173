import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, switchMap, timer } from 'rxjs';
import { Modal } from 'src/app/models/enums';
import { environment } from 'src/environments/environment';
import { MaintenanceModalComponent } from '../../components/maintenance-modal/maintenance-modal.component';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  apiUrl = environment.appLeveragedApiEndpoints.apiUrl;

  private _active = new BehaviorSubject<boolean>(false);
  get active$(): Observable<boolean> {
    return this._active.asObservable();
  }
  get active(): boolean {
    return this._active.getValue();
  }
  set active(value: boolean) {
    this._active.next(value);
  }

  constructor(
    private httpClient: HttpClient,
    private modalService: BsModalService
  ) {}

  activate(): void {
    if (this.active) {
      return;
    }

    this.active = true;
    this.modalService.show(MaintenanceModalComponent, {
      class: 'modal-dialog-centered',
      id: Modal.Maintenance,
      ignoreBackdropClick: true,
    });
  }

  heartbeat(): Observable<unknown> {
    return timer(10 * 1000).pipe(
      switchMap(() =>
        this.httpClient.get(`${this.apiUrl}/HealthCheck`, {
          observe: 'response',
        })
      )
    );
  }

  deactivate(): void {
    if (this.active) {
      location.reload();
    }
  }
}
