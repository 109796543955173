import { FieldTypeConfig } from '@ngx-formly/core';
import { MultiCheckboxComponent } from 'src/app/features/questionnaire';
import { QuestionnaireVariable } from 'src/app/models/interfaces';
import { evalExpression } from '../contract-express.functions';
import { ALL_OPTION_TEXT } from '../contract-express.service';

export function getMultiCheckboxFieldConfig({
  alloptiontext,
  name,
  otheroption,
  otheroptiontext,
  prescribedoptions,
  unknownoptiontext,
}: QuestionnaireVariable): Partial<FieldTypeConfig> {
  const labels = evalExpression<string[]>(prescribedoptions || '') || [];
  const required = name.toLowerCase().includes('optionverify');
  const options = labels.map((label) => ({ label, value: label }));
  if (alloptiontext) {
    options.unshift({
      label: alloptiontext,
      value: ALL_OPTION_TEXT,
    });
  }
  if (unknownoptiontext) {
    options.unshift({ label: unknownoptiontext, value: null } as any);
  }
  const otherOptionText = otheroption ? otheroptiontext || 'Other' : undefined;
  return {
    type: MultiCheckboxComponent,
    modelOptions: { updateOn: 'change' },
    props: {
      options,
      otherOptionText,
      required,
      type: 'array',
      unknownoptiontext,
    },
  };
}
