import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  delayWhen,
  EMPTY,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';
import {
  QuestionnaireFormModel,
  RespondentListStatus,
} from 'src/app/models/aliases';
import { Roles } from 'src/app/models/enums';
import {
  ActivityLog,
  AnswerSummary,
  Page,
  PageGroup,
  Person,
  Project,
  ProjectPerson,
  QuestionnaireAnswer,
} from 'src/app/models/interfaces';
import { OdataBackendService } from '..';
import {
  REPEAT_CONTEXT_SUFFIX,
  REPEAT_FIELD_GROUP,
} from '../contract-express/contract-express.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveProjectService {
  private _answerSummary = new BehaviorSubject<AnswerSummary>({
    companyInfo: [],
    respondentInfo: [],
    respondentDetails: [],
  });
  get answerSummary$() {
    return this._answerSummary.asObservable();
  }

  get changesDetected$(): Observable<boolean> {
    return this.project$.pipe(
      map((project) => project?.changesDetected || false)
    );
  }

  private _companyInfoPages = new BehaviorSubject<Page[]>([]);
  get companyInfoPages$(): Observable<Page[]> {
    return this._companyInfoPages.asObservable();
  }
  get companyInfoPages(): Page[] {
    return this._companyInfoPages.getValue();
  }
  set companyInfoPages(value: Page[]) {
    this._companyInfoPages.next(value);
  }

  private _project = new BehaviorSubject<Project | null>(null);
  get project$(): Observable<Project | null> {
    return this._project.asObservable();
  }
  get project(): Project | null {
    return this._project.getValue();
  }
  set project(value: Project | null) {
    this._project.next(value);
  }

  private _respondentDetailsPages = new BehaviorSubject<Page[]>([]);
  get respondentDetailsPages$(): Observable<Page[]> {
    return this._respondentDetailsPages.asObservable();
  }
  get respondentDetailsPages(): Page[] {
    return this._respondentDetailsPages.getValue();
  }
  set respondentDetailsPages(value: Page[]) {
    this._respondentDetailsPages.next(value);
  }

  private _respondentInfoPages = new BehaviorSubject<Page[]>([]);
  get respondentInfoPages$(): Observable<Page[]> {
    return this._respondentInfoPages.asObservable();
  }
  get respondentInfoPages(): Page[] {
    return this._respondentInfoPages.getValue();
  }
  set respondentInfoPages(value: Page[]) {
    this._respondentInfoPages.next(value);
  }

  get clientTeamMembers$(): Observable<ProjectPerson[]> {
    return this.project$.pipe(
      map(
        (project) =>
          project?.projectPeople.filter(
            ({ role }) =>
              role?.roleName === Roles.ClientAdmin ||
              role?.roleName === Roles.ClientCollaborator
          ) || []
      )
    );
  }

  get cooleyTeamMembers$(): Observable<ProjectPerson[]> {
    return this.project$.pipe(
      map(
        (project) =>
          project?.projectPeople.filter(
            ({ role }) => role && !role.isClientRole
          ) || []
      )
    );
  }

  get collaborators$(): Observable<ProjectPerson[]> {
    return this.project$.pipe(
      map(
        (project) =>
          project?.projectPeople?.filter(
            ({ role }) =>
              role?.roleName &&
              [Roles.ClientAdmin, Roles.ClientCollaborator].includes(
                role?.roleName
              )
          ) || []
      )
    );
  }

  get companyInfoAnswersModel$(): Observable<QuestionnaireFormModel> {
    return this.answerSummary$.pipe(
      map(({ companyInfo }) => this.mapAnswersToFormModel(companyInfo || []))
    );
  }

  get companyInfoPagesCompleteCount$(): Observable<number> {
    return this.companyInfoPages$.pipe(
      map(
        (pages) =>
          pages.filter(
            ({ globalPageStatus }) => globalPageStatus === 'Complete'
          ).length
      )
    );
  }

  get companyInfoRelevantPages$(): Observable<Page[]> {
    return this.companyInfoPages$.pipe(
      map((pages) =>
        pages.filter(({ cePageStatus }) => cePageStatus !== 'irrelevant')
      )
    );
  }

  get hasKeyContact$(): Observable<boolean> {
    return this.project$.pipe(
      map(
        (project) =>
          project?.projectPeople.some(({ isKeyContact }) => isKeyContact) ||
          false
      )
    );
  }

  get isDistributed$(): Observable<boolean> {
    return this.project$.pipe(
      map(
        (project) =>
          project?.status === 'Distributed' || project?.status === 'Complete'
      )
    );
  }

  get isRespondentListComplete$(): Observable<boolean> {
    return this.project$.pipe(
      map((project) => project?.respondentListStatus === 'Complete' || false)
    );
  }

  get projectStatus$() {
    return this.project$.pipe(
      map((project) =>
        project?.projectPeople.some(
          ({ role }) => role?.roleName === 'Client admin'
        )
          ? 'Collaborating'
          : 'New'
      )
    );
  }

  get respondentDetailsRelevantPages$(): Observable<Page[]> {
    return this.respondentDetailsPages$.pipe(
      map((pages) =>
        pages.filter(
          ({ respondentPages }) =>
            respondentPages[0]?.cePageStatus !== 'irrelevant'
        )
      )
    );
  }

  constructor(private odataBackend: OdataBackendService) {}

  applyChanges(respondentId?: string) {
    const project = this.project;
    return project
      ? this.odataBackend.postEntity<unknown>(
          `Projects/${project.id}/ApplyChanges`,
          { respondentId }
        )
      : EMPTY;
  }

  assignPageCollaborator(
    pageId: string,
    collaboratorProjectPersonId: string | null
  ): Observable<unknown> {
    return this.odataBackend
      .patchEntity<Page>('Pages', pageId, {
        collaboratorProjectPersonId,
      })
      .pipe(delayWhen(() => this.loadRespondentInfoPages()));
  }

  getRespondentInfoAnswers(
    respondentId: string | null
  ): Observable<QuestionnaireFormModel> {
    return this.loadAnswerSummary(respondentId).pipe(
      map(({ respondentInfo }) =>
        this.mapAnswersToFormModel(respondentInfo || [])
      )
    );
  }

  getRespondentDetailsAnswers(
    respondentId: string | null
  ): Observable<QuestionnaireFormModel> {
    return this.loadAnswerSummary(respondentId).pipe(
      map(({ respondentDetails }) =>
        this.mapAnswersToFormModel(respondentDetails)
      )
    );
  }

  loadActiveProject(id: string): Observable<Project | null> {
    return this.odataBackend
      .getEntity<Project>('Projects', id, {
        expand: {
          client: {},
          projectPeople: {
            expand: ['person', 'role', 'respondent'],
            orderBy: { role: 'roleName desc', person: ['name'] },
          },
        },
      })
      .pipe(tap((project) => (this.project = project)));
  }

  loadActivityLogs(
    projectId: string,
    top?: number
  ): Observable<{ logs: ActivityLog[]; count: number }> {
    const params = {
      expand: ['activityByPerson'],
      orderBy: 'activityDate desc',
    };
    const activityLogs = top ? { ...params, count: true, top } : params;
    return this.odataBackend
      .getEntity<Project>('Projects', projectId, {
        expand: { activityLogs },
      })
      .pipe(
        map((response: any) => ({
          logs: response.activityLogs,
          count: response['activityLogs@odata.count'],
        }))
      );
  }

  loadAnswerSummary(respondentId?: string | null): Observable<AnswerSummary> {
    const projectId = this.project?.id;
    return projectId
      ? this.odataBackend
          .postEntity<AnswerSummary>(`Projects/${projectId}/AnswerSummary`, {
            respondentId,
          } as any)
          .pipe(tap((answers) => this._answerSummary.next(answers)))
      : of();
  }

  loadCompanyInfoPages(): Observable<Page[]> {
    const projectId = this.project?.id;
    return projectId
      ? this.odataBackend
          .getEntitySet<PageGroup>('PageGroups', {
            expand: {
              pages: {
                orderBy: ['sortOrder'],
              },
            },
            filter: {
              projectId: { eq: { type: 'guid', value: projectId } },
              tabName: 'Company info',
            },
          })
          .pipe(
            map(([pageGroup]) => pageGroup.pages),
            tap((pages) => {
              this.companyInfoPages = pages;
            })
          )
      : of([]);
  }

  loadRespondentDetailsPages(respondentId: string): Observable<Page[]> {
    const projectId = this.project?.id;
    return projectId
      ? this.odataBackend
          .getEntitySet<PageGroup>('PageGroups', {
            expand: {
              pages: {
                expand: {
                  respondentPages: {
                    filter: {
                      respondentId: {
                        eq: { type: 'guid', value: respondentId },
                      },
                    },
                  },
                },
                orderBy: ['sortOrder'],
              },
            },
            filter: {
              projectId: { eq: { type: 'guid', value: projectId } },
              tabName: 'Respondent details',
            },
          })
          .pipe(
            map(([pageGroup]) => pageGroup.pages),
            tap((pages) => {
              this.respondentDetailsPages = pages.filter(
                ({ cePageStatus }) => cePageStatus !== 'irrelevant'
              );
            })
          )
      : of([]);
  }

  loadRespondentInfoPages(): Observable<Page[]> {
    const projectId = this.project?.id;
    return projectId
      ? this.odataBackend
          .getEntitySet<PageGroup>('PageGroups', {
            expand: {
              pages: {
                expand: ['respondentPages'],
                orderBy: ['sortOrder'],
              },
            },
            filter: {
              projectId: { eq: { type: 'guid', value: projectId } },
              tabName: 'Respondent info',
            },
          })
          .pipe(
            map(([pageGroup]) => pageGroup.pages),
            tap((pages) => {
              this.respondentInfoPages = pages.filter(
                (page) =>
                  page.cePageStatus !== 'irrelevant' &&
                  page.respondentPages.some(
                    ({ cePageStatus }) => cePageStatus !== 'irrelevant'
                  )
              );
            })
          )
      : of([]);
  }

  markRespondentListAsComplete(): Observable<Project> {
    return this.project
      ? this.odataBackend
          .postEntity<Project>(
            `Projects/${this.project.id}/MarkRespondentListAsComplete`,
            {}
          )
          .pipe(delayWhen(() => this.loadActiveProject(this.project!.id)))
      : EMPTY;
  }

  resendPersonInvite(projectPersonId: string) {
    const project = this.project;
    return project
      ? this.odataBackend.postEntity<Person>(`projects/${project.id}/invite`, {
          projectPersonId,
        } as any)
      : EMPTY;
  }

  updateNonQuestionnaireShareholders(
    nonQuestionnaireShareholders: string
  ): Observable<unknown> {
    const project = this.project;
    return project
      ? this.odataBackend
          .patchEntity<Project>('Projects', project.id, {
            nonQuestionnaireShareholders,
          })
          .pipe(delayWhen(() => this.loadActiveProject(project.id)))
      : EMPTY;
  }

  updateRespondentListStatus(
    respondentListStatus: RespondentListStatus
  ): Observable<unknown> {
    const project = this.project;
    if (project) {
      this.project = { ...project, respondentListStatus };
      return this.odataBackend
        .patchEntity<Project>('Projects', project.id, { respondentListStatus })
        .pipe(delayWhen(() => this.loadActiveProject(project.id)));
    }

    return EMPTY;
  }

  reset(): void {
    this.project = null;
    this.companyInfoPages = [];
    this.respondentInfoPages = [];
  }

  private mapAnswersToFormModel(
    answers: QuestionnaireAnswer[]
  ): QuestionnaireFormModel {
    return answers.reduce<QuestionnaireFormModel>(
      (model, { question, answer, known, repeatContext, repeatGroupName }) => {
        if (answer === 'true') {
          answer = true;
        } else if (answer === 'false') {
          answer = false;
        } else if (answer === '' && known === false) {
          answer = null as any;
        }

        if (repeatContext && repeatGroupName) {
          if (repeatGroupName === REPEAT_FIELD_GROUP) {
            let repeatFieldGroup = model.hasOwnProperty(REPEAT_FIELD_GROUP)
              ? (model[repeatGroupName] as Record<string, boolean | string>[])
              : {};
            repeatFieldGroup = {
              ...repeatFieldGroup,
              [`${question}${REPEAT_CONTEXT_SUFFIX}${repeatContext}`]: answer,
            };
            return {
              ...model,
              [REPEAT_FIELD_GROUP]: repeatFieldGroup,
            };
          }

          const repeatGroup = model.hasOwnProperty(repeatGroupName)
            ? (model[repeatGroupName] as Record<string, boolean | string>[])
            : [];
          if (repeatGroup) {
            const repeatGroupValue = repeatGroup[repeatContext - 1];
            repeatGroup[repeatContext - 1] = {
              ...repeatGroupValue,
              [question]: answer,
            };
          }

          const repeatArray = model.hasOwnProperty(question)
            ? (model[question] as Array<boolean | string>)
            : [];
          repeatArray[repeatContext - 1] = answer;
          return {
            ...model,
            [question]: repeatArray,
            [repeatGroupName]: repeatGroup,
          };
        } else if (repeatContext) {
          const repeatArray = model.hasOwnProperty(question)
            ? (model[question] as Array<boolean | string>)
            : [];
          if (Array.isArray(repeatArray)) {
            repeatArray[repeatContext - 1] = answer;
          }
          return {
            ...model,
            [question]: repeatArray,
          };
        }

        return { ...model, [question]: answer };
      },
      {}
    );
  }
}
