import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { BehaviorSubject, Subject, startWith, takeUntil } from 'rxjs';
import { ContractExpressService } from 'src/app/core/services';

@Component({
  selector: 'app-questionnaire-field-wrapper',
  templateUrl: './questionnaire-field-wrapper.component.html',
})
export class QuestionnaireFieldWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  destroyed$ = new Subject<boolean>();
  label$ = new BehaviorSubject<string>('');

  get hideLabel(): boolean {
    const parent = this.field?.parent;
    if (
      parent &&
      Number(parent.key) &&
      parent.parent?.props &&
      parent.parent.props['repeatTitles'] &&
      (parent.parent.fieldArray as any)?.fieldGroup?.length === 1
    ) {
      return true;
    }
    return false;
  }

  constructor(private contractExpressService: ContractExpressService) {
    super();
  }

  ngOnInit(): void {
    if (this.props['promptexpression']) {
      this.initDynamicPrompt();
    } else {
      this.label$.next(
        this.field.type === 'checkbox'
          ? this.props['prompt']
          : this.props.label,
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initDynamicPrompt(): void {
    this.formControl.root.valueChanges
      .pipe(takeUntil(this.destroyed$), startWith(''))
      .subscribe(() =>
        this.label$.next(
          this.contractExpressService
            .eval<string[]>(this.props['promptexpression'], this.model)
            ?.join('') || '',
        ),
      );
  }
}
