import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { isEqual, isNil } from 'lodash';
import {
  ChangeReportFieldComponent,
  ChangeReportGroupWrapperComponent,
  ChangeReportRepeatGroupComponent,
  SummaryFieldWrapperComponent,
} from 'src/app/features/questionnaire';
import {
  QuestionnaireFormAnswer,
  QuestionnaireFormModel,
} from 'src/app/models/aliases';
import {
  Questionnaire,
  QuestionnaireGroup,
  QuestionnaireVariable,
} from 'src/app/models/interfaces';
import { evalExpression } from './contract-express.functions';
import {
  REPEAT_CONTEXT_SUFFIX,
  REPEAT_FIELD_GROUP,
  REPEAT_GROUP_SUFFIX,
} from './contract-express.service';

const AlwaysVisibleFields = [
  'Respondent_DiversityInfoAggregate_Verify',
  'Respondent_DiversityInfoIndividual',
];

const ExceptionFields = [
  'Respondent_CompanyDirectorComp_Verify',
  'Respondent_CompanyExecOfficerComp_Verify',
  'Respondent_CompanyOtherComp_Verify',
  'Respondent_CompanyPerquisites_Verify',
  'Respondent_CompanySecuritiesConfirm',
  'Respondent_CompanySecuritiesRightToAcquireConfirm',
  'Respondent_IncentivePlanAwards',
  'Respondent_LegalProceedings',
  'Respondent_Meetings',
  'Respondent_NQDefComp',
  'Respondent_PensionPlan',
  'Respondent_Section16Form5',
  'Respondent_StockAwardHoldings',
  'Respondent_StockOptionHoldings',
];

const VerifyFields = [
  'Respondent_IncentivePlanAwards',
  'Respondent_LegalProceedings',
  'Respondent_Meetings',
  'Respondent_NQDefComp',
  'Respondent_PensionPlan',
  'Respondent_Section16Form5',
  'Respondent_StockAwardHoldings',
  'Respondent_StockOptionHoldings',
];

export const getPageConfig = (
  previous: QuestionnaireFormModel,
  current: QuestionnaireFormModel
): FormlyFieldConfig[] => {
  const [pageData] = (window as any).cePage || [];

  if (pageData) {
    const { activatedpage, questionnairepages } = pageData as Questionnaire;
    const questionnairePage = questionnairepages[activatedpage];
    const { questionnairegroups, usage } = questionnairePage || {};
    return Object.values(questionnairegroups || {}).map((group) => {
      const [usageKey] = Object.keys(group.usage || {}).map(Number);
      if (usage[usageKey]) {
        const [repeatcontext] = usage[usageKey].repeatcontext;
        const context = repeatcontext
          ? (repeatcontext(current) as number[])
          : undefined;
        if (context) {
          group.repeatcontext = Math.max(...context);
        }
      }
      return mapToFieldGroupConfig(group, previous, current);
    });
  }

  return [];
};

interface AnswerChangedParams {
  fieldName: string;
  defaultValue?: QuestionnaireFormAnswer;
  currentValue: QuestionnaireFormAnswer | null;
  previousValue: QuestionnaireFormAnswer | null;
}
const hasAnswerChanged = ({
  fieldName,
  currentValue,
  defaultValue,
  previousValue,
}: AnswerChangedParams): boolean => {
  const isAlwaysVisibleField = AlwaysVisibleFields.some(
    (field) => fieldName === field.toLocaleLowerCase()
  );
  if (isAlwaysVisibleField) {
    return true;
  }

  const isExceptionField = ExceptionFields.some(
    (field) => fieldName === field.toLocaleLowerCase()
  );
  const isMiscField = fieldName.includes('respondent_miscquestion');
  const isVerifyField =
    fieldName.endsWith('confirm') ||
    fieldName.includes('_verify') ||
    VerifyFields.some((field) => fieldName === field.toLocaleLowerCase());

  const hideNilAnswers = isNil(currentValue);
  const hideFalsyAnswers =
    (currentValue === false || currentValue === '') &&
    !(isExceptionField || isMiscField);
  const hideConfirmedVerifyAnswers = currentValue === true && isVerifyField;
  const hideEqualDefaulAndCurrentAnswers =
    defaultValue !== undefined && isEqual(currentValue, defaultValue);
  const hideEqualCurrentAndPreviousAnswers =
    isEqual(currentValue, previousValue) && isNil(defaultValue);
  return (
    !hideNilAnswers &&
    !hideFalsyAnswers &&
    !hideConfirmedVerifyAnswers &&
    !hideEqualDefaulAndCurrentAnswers &&
    !hideEqualCurrentAndPreviousAnswers
  );
};

const mapToFieldGroupConfig = (
  group: QuestionnaireGroup,
  previous: QuestionnaireFormModel,
  current: QuestionnaireFormModel
): FormlyFieldConfig => {
  const {
    questionnairevariableorder,
    questionnairevariables,
    title,
    titleexpression,
  } = group;
  const guidance = group.guidanceexpression
    ? evalExpression<string[]>(group.guidanceexpression, current)?.join('')
    : group.guidance;
  const label = titleexpression
    ? evalExpression<string[]>(titleexpression)?.join('')
    : title;
  const variables = questionnairevariableorder || [];
  const fieldGroup = variables.reduce<FormlyFieldConfig[]>(
    (groups, key, i, fields) => {
      const variable = questionnairevariables[key];
      if (variable.inputmethod === 'GroupRepeat') {
        const children = fields
          .splice(i + 1)
          .reduce<Record<string, QuestionnaireVariable>>(
            (all, field) => ({
              ...all,
              [field]: questionnairevariables[field],
            }),
            {}
          );
        groups.push(
          mapToRepeaterFieldArray(variable, children, previous, current)
        );
      } else if (group.repeatcontext && current) {
        // if there is a repeatcontext, prepare a new type of a repeater group.
        fields.splice(i + 1);
        groups.push(mapToRepeaterFieldGroup(group, current));
      } else {
        groups.push(mapToFieldConfig(key, variable, previous, current));
      }
      return groups;
    },
    []
  );

  return {
    fieldGroup,
    props: { guidance, label: label?.replace('_ListVerify', '') },
    wrappers: [ChangeReportGroupWrapperComponent],
  };
};

const mapToFieldConfig = (
  key: string,
  variable: QuestionnaireVariable,
  previous: QuestionnaireFormModel,
  current: QuestionnaireFormModel
): FieldTypeConfig => {
  const fieldName = key?.toString().toLocaleLowerCase();
  let label = variable.promptexpression
    ? evalExpression<string[]>(variable.promptexpression, current)?.join('')
    : variable.prompt;
  const [option] =
    evalExpression<string[]>(variable.prescribedoptions || '') || [];
  let defaultValue: boolean | undefined;
  let prompt = '';

  if (variable.inputmethod === 'Checkbox') {
    prompt = label || '';
    label = option;
    defaultValue = variable.prefillvalueexpression
      ? !!evalExpression(variable.prefillvalueexpression, previous)
      : false;
  }
  const useIconDisplay = variable.inputmethod === 'Checkbox';
  const multicheckbox =
    variable.inputmethod === 'ButtonList' && variable.datatype === 'StringList';
  const previousValue = previous?.hasOwnProperty(key) ? previous[key] : null;
  const currentValue = current?.hasOwnProperty(key) ? current[key] : null;
  const hasChanged = hasAnswerChanged({
    fieldName,
    currentValue,
    defaultValue,
    previousValue,
  });
  const fieldConfig = {
    key,
    type: ChangeReportFieldComponent,
    props: {
      label,
      currentValue,
      previousValue,
      hasChanged,
      prompt,
      useIconDisplay,
      multicheckbox,
    },
    expressions: {
      hide: ({ model }: FormlyFieldConfig) =>
        Object.values(variable.usage).some(
          (usage) =>
            !evalExpression<boolean | null>(usage, { ...current, ...model })
        ),
    },
    wrappers: [SummaryFieldWrapperComponent],
  } as any as FieldTypeConfig;

  return fieldConfig;
};

const mapToRepeaterFieldArray = (
  parent: QuestionnaireVariable,
  children: Record<string, QuestionnaireVariable>,
  previous: QuestionnaireFormModel,
  current: QuestionnaireFormModel
): FormlyFieldConfig => {
  const fieldArrayKey = parent.name.toLowerCase().concat(REPEAT_GROUP_SUFFIX);
  const currentModel = current?.hasOwnProperty(fieldArrayKey)
    ? (current[fieldArrayKey] as QuestionnaireFormModel[])
    : [];
  const previousModel = previous?.hasOwnProperty(fieldArrayKey)
    ? (previous[fieldArrayKey] as QuestionnaireFormModel[])
    : [];
  const fieldGroup = Object.entries(children).map(([key, variable], index) =>
    mapToFieldConfig(
      key,
      variable,
      previousModel ? previousModel[index] : {},
      currentModel ? currentModel[index] : {}
    )
  );
  const childrenVariables = Object.values(children);
  const [firstVariable] = childrenVariables;
  const label = firstVariable?.prompt;
  const titles = childrenVariables.map(({ prompt, promptexpression }) =>
    promptexpression
      ? evalExpression<string[]>(promptexpression, previous)?.join('')
      : prompt
  );
  return {
    key: fieldArrayKey,
    type: ChangeReportRepeatGroupComponent,
    fieldArray: {
      fieldGroup,
      fieldGroupClassName: 'row',
    },
    expressions: {
      hide: () =>
        Object.values(parent.usage).some(
          (usage) => !evalExpression<boolean | null>(usage, current)
        ),
    },
    props: {
      currentModel,
      label,
      layout: 'summary',
      length: Math.max(currentModel?.length || 0, previousModel?.length || 0),
      previousModel,
      titles,
    },
  };
};

const mapToRepeaterFieldGroup = (
  { questionnairevariables, repeatcontext }: QuestionnaireGroup,
  formModel: QuestionnaireFormModel
): FormlyFieldConfig => {
  if (!repeatcontext) {
    return {};
  }

  const fieldGroup: FormlyFieldConfig[] = [];
  for (let i = 0; i < repeatcontext; i++) {
    const model = Object.entries({
      ...formModel,
      ...(formModel[REPEAT_FIELD_GROUP] as QuestionnaireFormModel),
    }).reduce(
      (flatModel, [key, value]) => ({
        ...flatModel,
        [key]: Array.isArray(value) ? value[i] : value,
      }),
      {}
    );
    Object.entries(questionnairevariables).forEach(([name, variable]) =>
      fieldGroup.push(
        mapToFieldConfig(
          `${name}${REPEAT_CONTEXT_SUFFIX}${i + 1}`,
          variable,
          {},
          model
        )
      )
    );
  }

  return { key: REPEAT_FIELD_GROUP, fieldGroup, props: { hasChanged: true } };
};
