<div class="bg-white container-xl mx-auto p-5">
  <h4 *ngIf="title" class="mb-5">
    {{ title }}
    <span *ngIf="subtitle" class="fw-normal ms-3">{{ subtitle }}</span>
  </h4>

  <div
    *ngIf="guidance"
    appGlossaryTerm
    class="my-3"
    [innerHTML]="guidance | safeHtml"
    style="max-width: 80ch; white-space: pre-wrap"
  ></div>

  <form autocomplete="off" [formGroup]="form">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="options"
    ></formly-form>
  </form>

  <ng-content></ng-content>
</div>
