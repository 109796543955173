import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delayWhen,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { RespondentQuestionnaireStatus } from 'src/app/models/aliases';
import {
  ContactInfo,
  DelegateItem,
  Person,
  Respondent,
} from 'src/app/models/interfaces';
import {
  ActiveProjectService,
  ActiveProjectUserRoleService,
  OdataBackendService,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class RespondentsService {
  private _activeRespondent = new BehaviorSubject<Respondent | null>(null);
  private _respondents$ = new BehaviorSubject<Respondent[]>([]);

  get activeRespondent$(): Observable<Respondent | null> {
    return this._activeRespondent.asObservable();
  }
  get activeRespondent(): Respondent | null {
    return this._activeRespondent.getValue();
  }
  set activeRespondent(value: Respondent | null) {
    this._activeRespondent.next(value);
  }

  get groupedRespondents$() {
    return this.respondents$.pipe(
      map((respondents) => groupBy(respondents, 'respondentCategory'))
    );
  }

  get groupedDistributedRespondents$() {
    return this.respondents$.pipe(
      map((respondents) =>
        groupBy(
          respondents.filter(
            ({ questionnaireStatus }) =>
              questionnaireStatus !== 'New' &&
              questionnaireStatus !== 'ReadyToDistribute'
          ),
          'respondentCategory'
        )
      )
    );
  }

  get groupedRespondentsForCollaborator$() {
    return combineLatest([
      this.respondents$,
      this.activeProjectUserRoleService.projectPersonId$,
    ]).pipe(
      map(([respondents, projectPersonId]) =>
        groupBy(
          respondents.filter((respondent) =>
            respondent.respondentPages?.some(
              ({ cePageStatus, page }) =>
                cePageStatus !== 'irrelevant' &&
                page.collaboratorProjectPersonId === projectPersonId
            )
          ),
          'respondentCategory'
        )
      )
    );
  }

  get respondents$() {
    return this._respondents$.asObservable();
  }
  get respondents() {
    return this._respondents$.getValue();
  }
  set respondents(value: Respondent[]) {
    this._respondents$.next(value);
  }

  get respondentsComplete$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus === 'Complete' ||
            questionnaireStatus === 'CompleteSigned'
        )
      )
    );
  }

  get respondentsPendingResponse$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus === 'InProgress-NotYetStarted' ||
            questionnaireStatus === 'InProgress'
        )
      )
    );
  }

  get respondentsQueue$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) => questionnaireStatus === 'New'
        )
      )
    );
  }

  get respondentsReadyToDistribute$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus === 'ReadyToDistribute'
        )
      )
    );
  }

  constructor(
    private activeProjectService: ActiveProjectService,
    private activeProjectUserRoleService: ActiveProjectUserRoleService,
    private odataBackendService: OdataBackendService
  ) {}

  addDelegate(
    respondentId: string,
    person: Pick<Person, 'name' | 'primaryEmail' | 'userType'>
  ): Observable<unknown> {
    return this.odataBackendService
      .postEntity<Person>('People', person)
      .pipe(
        switchMap((person) =>
          this.odataBackendService.postEntity<unknown>(
            `respondents/${respondentId}/AddDelegate`,
            { delegate: { personId: person.id } }
          )
        )
      );
  }

  addRespondent(respondent: Partial<Respondent>, person?: Partial<Person>) {
    // If a person entity was provided, create that person first,
    // and then use that personId while creating a respondent.
    // If a person was not provided, then the responded is an organization
    // and should be created without the underlying person entity.
    const createPerson$: Observable<Partial<Person>> = person
      ? this.odataBackendService.postEntity<Person>('People', person)
      : of({});
    return createPerson$.pipe(
      switchMap(({ id }) =>
        this.odataBackendService.postEntity<Respondent>(
          'Respondents',
          id ? { ...respondent, personId: id } : respondent
        )
      ),
      delayWhen((newRespondent) =>
        this.fetchProjectRespondents(newRespondent.projectId)
      ),
      catchError((error) => throwError(error))
    );
  }

  reassignQuestionnaire(
    respondent: Partial<Respondent>,
    person: Partial<Person>
  ): Observable<Partial<Respondent>> {
    if (!person || !respondent?.id) {
      return of(respondent);
    }

    const deleteDelegatesIfNeeded$ = respondent.delegates?.length
      ? forkJoin(
          respondent.delegates.map((delegate) => this.deleteDelegate(delegate))
        )
      : of([]);

    return deleteDelegatesIfNeeded$.pipe(
      switchMap(() =>
        this.odataBackendService.postEntity<Person>('People', person)
      ),
      switchMap((person) => {
        return this.updateRespondent(respondent.id!, { personId: person.id });
      }),
      switchMap(() =>
        this.activeProjectService.loadActiveProject(respondent.projectId!)
      ),
      switchMap((project) => {
        const projectPerson = project?.projectPeople.find(
          ({ respondent: projectPersonRespondent }) =>
            projectPersonRespondent?.id === respondent?.id
        );
        if (projectPerson) {
          return this.activeProjectService.resendPersonInvite(projectPerson.id);
        } else {
          return of({});
        }
      }),
      switchMap(() =>
        this.odataBackendService.postEntity<any>(
          `projects/${respondent.projectId}/RemoveTeamMember`,
          {
            teamMember: respondent.person?.id,
          }
        )
      )
    );
  }

  deleteDelegate(delegate: DelegateItem): Observable<unknown> {
    return this.odataBackendService.postEntity<unknown>(
      `respondents/${delegate.respondentId}/DeleteDelegate`,
      { delegate: { delegateId: delegate.id } }
    );
  }

  deleteRespondent(respondentId: string): Observable<void> {
    return this.odataBackendService
      .deleteEntity('Respondents', respondentId)
      .pipe(catchError((error) => throwError(error)));
  }

  distribute(respondentId: string): Observable<Respondent> {
    return this.odataBackendService
      .postEntity<Respondent>(
        `Respondents/${respondentId}/Distribute`,
        {},
        { expand: ['person'] }
      )
      .pipe(catchError((error) => throwError(error)));
  }

  fetchActiveProjectRespondents(): Observable<Respondent[]> {
    // TODO: Remove this function in favor of "fetchProjectRespondents" below.
    const project = this.activeProjectService.project;
    return project
      ? this.odataBackendService
          .getEntitySet<Respondent>('Respondents', {
            filter: { projectId: { eq: { type: 'guid', value: project.id } } },
            expand: [
              'delegates',
              'delegates/p/person',
              'person',
              'respondentPages/page',
            ],
          })
          .pipe(
            tap((respondents) => (this.respondents = respondents)),
            catchError((error) => throwError(error))
          )
      : of([]);
  }

  fetchProjectRespondents(projectId: string): Observable<Respondent[]> {
    return this.odataBackendService
      .getEntitySet<Respondent>('Respondents', {
        filter: { projectId: { eq: { type: 'guid', value: projectId } } },
        expand: [
          'delegates',
          'delegates/p/person',
          'person',
          'respondentPages/page',
        ],
        orderBy: 'person/name',
      })
      .pipe(
        tap((respondents) => (this.respondents = respondents)),
        catchError((error) => throwError(error))
      );
  }

  getRespondentName(respondentId: string): string | null {
    const respondent = this.respondents.find(({ id }) => id === respondentId);
    return respondent
      ? respondent.respondentEntityName || respondent.person?.name || null
      : null;
  }

  loadPerson(personId: string): Observable<Person> {
    return this.odataBackendService.getEntity<Person>('People', personId);
  }

  loadRespondent(respondentId: string): Observable<Respondent> {
    return this.odataBackendService
      .getEntity<any>('Respondents', respondentId, {
        expand: {
          person: {},
          delegates: {
            expand: {
              p: {
                expand: ['person'],
              },
            },
          },
        },
      })
      .pipe(tap((respondent) => (this.activeRespondent = respondent)));
  }

  rollBackToStatus(
    respondentId: string,
    questionnaireStatus: RespondentQuestionnaireStatus
  ): Observable<Respondent> {
    return this.odataBackendService.postEntity<Respondent>(
      `Respondents/${respondentId}/RollBackToStatus`,
      { questionnaireStatus }
    );
  }

  updatePerson(personId: string, body: Partial<Person>): Observable<Person> {
    return this.odataBackendService.patchEntity<Person>(
      'People',
      personId,
      body
    );
  }

  updateRespondent(
    respondentId: string,
    body: Partial<Respondent>
  ): Observable<Respondent> {
    return this.odataBackendService.patchEntity<Respondent>(
      'Respondents',
      respondentId,
      body,
      { expand: ['person', 'delegates', 'delegates/p/person'] }
    );
  }

  updateRespondentContactInfo(respondentId: string, contactInfo: ContactInfo) {
    return this.odataBackendService.postEntity(
      `Respondents/${respondentId}/EditRespondentContactInfo`,
      { contactInfo }
    );
  }
}
