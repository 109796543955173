/** Imports */
import { Component, OnInit } from '@angular/core';
import { templateFooterMenuService } from '../_services/template-footer-menu.service';
/** Declaration of component */
@Component({
  selector: 'app-template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.css']
})
/** Footer class */
export class TemplateFooterComponent implements OnInit {

  /** App footer links */
  public footerLinksApp: any;
  /** Error condition display. */
  public errorConditionMenu: any;

  /** Constructor */
  constructor(
    private templateFooterMenuService: templateFooterMenuService
  ) {
    //
  }

  /** Lifecycle */
  ngOnInit(): void {
    /** Error condition display */
    this.errorConditionMenu = {
      "data": [
        {
          "label": "Error condition menu",
          "showLabel": false,
          "data": [
            {
              "text": "An error occured fetching the footer app links.",
              "route": "home"
            }
          ]
        },
      ]
    };
    /** Get menu */
    this.getFooterMenuData();

  }

  /** getFooterMenuData */
  private getFooterMenuData() {
    try {
      /** Set the top menu */
      this.footerLinksApp = this.templateFooterMenuService.footerMenuData;
    } catch (error) {
      console.log(error);
      /** Display the error in the header */
      this.footerLinksApp = this.errorConditionMenu;
    }
  }

}
