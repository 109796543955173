<div *ngIf="showPreviousValue" class="mb-3" style="white-space: break-spaces">
  <del class="text-muted">
    <div [innerHTML]="previousValue"></div>
  </del>
</div>

<div *ngIf="showCurrentValue" style="white-space: break-spaces">
  <ng-container [ngSwitch]="true">
    <svg
      *ngSwitchCase="currentValue === true && useIcon"
      ibmIconCheckboxChecked
      class="text-muted"
      fill="currentColor"
      size="24"
    ></svg>
    <svg
      *ngSwitchCase="currentValue === false && useIcon"
      ibmIconCheckbox
      class="text-muted"
      fill="currentColor"
      size="24"
    ></svg>
    <div *ngSwitchDefault [innerHTML]="currentValue"></div>
  </ng-container>
</div>
