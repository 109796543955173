/** Imports */
import { Injectable } from '@angular/core';
/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root'
})
/** Class */
export class templateFooterMenuService {

  /** Footer menu object */
  private _footerMenuData: any;
  /** Constructor */
  constructor() {
    //
   }

  /** Get the top menu object */
  get footerMenuData() {
    return this._footerMenuData;
  }
  /** Set the top menu object */
  set footerMenuData(newfooterMenuData) {
    // console.log(newfooterMenuData);
    this._footerMenuData = newfooterMenuData;
  }

}
