import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delayWhen,
  map,
  Observable,
  tap,
  throwError,
} from 'rxjs';
import { Roles, UserType } from 'src/app/models/enums';
import {
  AddTeamMember,
  EditTeamMember,
  Person,
  Project,
  ProjectListItem,
  ProjectPerson,
} from 'src/app/models/interfaces';

import {
  ActiveProjectService,
  CurrentUserService,
  OdataBackendService,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(
    private activeProjectService: ActiveProjectService,
    private currentUserService: CurrentUserService,
    private odataBackendService: OdataBackendService
  ) {}

  private _myProjects = new BehaviorSubject<Project[]>([]);
  get myProjects$(): Observable<Project[]> {
    return this._myProjects.asObservable();
  }
  set myProjects(value: Project[]) {
    this._myProjects.next(value);
  }

  get projectHeader$() {
    return combineLatest([
      this.myProjects$,
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([myProjects, selectedProject, currentUser]) => {
        if (selectedProject) {
          const { client, projectName } = selectedProject;
          return {
            clientName: client?.clientName || '',
            clientNumber: client?.clientNumber,
            clientUrl: client?.url || '',
            projectName,
          };
        } else if (
          myProjects.length &&
          currentUser?.userType === UserType.external
        ) {
          const [latestProject] = myProjects;
          const { client, projectName } = latestProject;
          return {
            clientName: client?.clientName || '',
            clientNumber: client?.clientNumber,
            clientUrl: client?.url || '',
            projectName,
          };
        } else {
          return null;
        }
      })
    );
  }

  get sameClientProjects$(): Observable<boolean> {
    return this.myProjects$.pipe(
      map((projects) =>
        projects.every(
          (project) =>
            project.client?.clientName === projects[0].client?.clientName
        )
      )
    );
  }

  get projectList$(): Observable<ProjectListItem[]> {
    return combineLatest([
      this.myProjects$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([projects, currentUser]) =>
        this.mapProjectsToProjectList(projects, currentUser)
      )
    );
  }

  addProject(project: Partial<Project>): Observable<Project> {
    return this.odataBackendService.postEntity<Project>('Projects', project);
  }

  fetchMyProjects(userId: string): Observable<Project[]> {
    return this.odataBackendService
      .getEntitySet<Project>('Projects', {
        expand: {
          activityLogs: {},
          client: {},
          projectPeople: { expand: ['person', 'respondent', 'role'] },
          projectType: {},
        },
        filter: {
          or: [
            {
              projectPeople: {
                any: {
                  or: [
                    {
                      personId: { eq: { type: 'guid', value: userId } },
                      role: {
                        roleName: Roles.Respondent,
                      },
                      respondent: {
                        questionnaireStatus: {
                          in: [
                            'InProgress-NotYetStarted',
                            'InProgress',
                            'Complete',
                            'CompleteSigned',
                          ],
                        },
                      },
                    },
                    {
                      personId: { eq: { type: 'guid', value: userId } },
                      role: {
                        roleName: {
                          in: [
                            Roles.CooleyUser,
                            Roles.ClientAdmin,
                            Roles.ClientCollaborator,
                          ],
                        },
                      },
                    },
                  ],
                },
              },
            },
            {
              delegates: {
                any: {
                  personId: { eq: { type: 'guid', value: userId } },
                  respondent: {
                    questionnaireStatus: {
                      in: [
                        'InProgress-NotYetStarted',
                        'InProgress',
                        'Complete',
                        'CompleteSigned',
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        orderBy: 'created DESC',
      })
      .pipe(
        tap((projects) => (this.myProjects = projects)),
        catchError((error) => throwError(error))
      );
  }

  getClientsCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Projects', {
        transform: {
          filter: {
            projectPeople: {
              any: {
                personId: { eq: { type: 'guid', value: userId } },
              },
            },
            created: { ge: startDate },
          },
          groupBy: {
            properties: ['client/clientNumber'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((clients) => clients.length));
  }

  getDocumentsSignedCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService.getEntitySetCount('Respondents', {
      filter: {
        questionnaireStatus: 'CompleteSigned',
        project: {
          projectPeople: {
            any: {
              personId: { eq: { type: 'guid', value: userId } },
            },
          },
        },
        modified: { ge: startDate },
      },
    });
  }

  getPagesCompletedCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Pages', {
        transform: {
          filter: {
            collaborator: {
              personId: { eq: { type: 'guid', value: userId } },
            },
            respondentPages: {
              all: {
                or: [
                  { cePageStatus: 'irrelevant' },
                  { respondentPageStatus: 'Complete' },
                ],
              },
            },
            modified: { ge: startDate },
          },
          groupBy: {
            properties: ['id'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((pages) => pages.length));
  }

  getPagesTotalCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Pages', {
        transform: {
          filter: {
            collaborator: {
              personId: { eq: { type: 'guid', value: userId } },
            },
            modified: { ge: startDate },
          },
          groupBy: {
            properties: ['id'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((pages) => pages.length));
  }

  getProjectsCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService.getEntitySetCount('Projects', {
      filter: {
        projectPeople: {
          any: {
            personId: { eq: { type: 'guid', value: userId } },
          },
        },
        created: { ge: startDate },
      },
    });
  }

  saveExistingTeamMember(
    projectId: string,
    clientTeamMember: EditTeamMember
  ): Observable<Project> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/EditTeamMember`, {
        teamMember: {
          personId: clientTeamMember.personId,
          roleId: clientTeamMember.roleId,
        },
      })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error))
      );
  }

  deleteTeamMember(projectId: string, member: Person): Observable<null> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/RemoveTeamMember`, {
        teamMember: member.id,
      })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error))
      );
  }

  addTeamMember(
    projectId: string,
    teamMember: AddTeamMember
  ): Observable<Project> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/AddTeamMember`, { teamMember })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error))
      );
  }

  editTeamMember(
    projectId: string,
    teamMember: Partial<ProjectPerson>
  ): Observable<Project> {
    return this.odataBackendService.postEntity<any>(
      `projects/${projectId}/EditTeamMember`,
      { teamMember }
    );
  }

  private getRespondentId(user: Person, projectId: string): string {
    const currentProjectRole = user.projectPeople.find(
      (projectRole) => projectRole.projectId === projectId
    );

    if (!currentProjectRole) {
      return '';
    }

    if (currentProjectRole.respondent?.id) {
      return currentProjectRole.respondent.id;
    }

    if (
      currentProjectRole.role?.roleName === Roles.RespondentDelegate &&
      currentProjectRole.delegates?.length
    ) {
      const delegate = currentProjectRole.delegates.find(
        (delegate) => delegate.personId === currentProjectRole.personId
      );

      if (delegate) {
        return delegate.respondentId;
      }
    }

    return '';
  }

  private mapProjectsToProjectList(projects: Project[], user: Person | null) {
    return projects.reduce<ProjectListItem[]>(
      (
        listItems,
        { client, created, id, projectName, projectPeople, status }
      ) => {
        const projectRole = projectPeople.find(
          ({ personId }) => personId === user?.id
        );

        const lastActivity =
          user?.userType === UserType.internal
            ? `Created ${format(new Date(created || ''), 'MMM d, yyyy')}`
            : '';

        const listItem = {
          clientUrl: client?.url?.trim() || '',
          clientName: client?.clientName || '',
          lastActivity,
          projectId: id,
          projectName,
          respondentId: '',
          showClient: true,
          status: status || null,
        };

        if (
          (projectRole?.role?.roleName === Roles.Respondent ||
            projectRole?.role?.roleName) === Roles.RespondentDelegate
        ) {
          if (
            projectRole?.respondent?.questionnaireStatus === 'Complete' ||
            projectRole?.respondent?.questionnaireStatus === 'CompleteSigned'
          ) {
            status = 'Complete';
          } else if (
            projectRole?.respondent?.questionnaireStatus === 'InProgress' ||
            projectRole?.respondent?.questionnaireStatus ===
              'InProgress-NotYetStarted'
          ) {
            status = 'Distributed';
          }
        }

        if (projectRole?.role?.roleName === Roles.RespondentDelegate) {
          const currentProjectRole = user?.projectPeople.find(
            ({ projectId }) => projectId === id
          );
          const delegates = currentProjectRole?.delegates.filter(
            (delegate) => delegate.personId === currentProjectRole.personId
          );
          delegates?.forEach(({ respondentId, respondent }) => {
            if (
              respondent?.questionnaireStatus === 'InProgress-NotYetStarted' ||
              respondent?.questionnaireStatus === 'InProgress' ||
              respondent?.questionnaireStatus === 'Complete' ||
              respondent?.questionnaireStatus === 'CompleteSigned'
            )
              listItems.push({
                ...listItem,
                respondentId,
                respondentName: respondent?.person?.name,
                status: null,
              });
          });
        } else if (projectRole?.role?.roleName === Roles.Respondent) {
          const respondentId = projectRole.respondent?.id || '';
          const respondentName = projectRole.respondent?.person?.name;
          listItems.push({ ...listItem, respondentId, respondentName });
        } else {
          listItems.push(listItem);
        }
        return listItems;
      },
      []
    );
  }

  private mapProjectToProjectListItem(
    { client, created, id, projectName, projectPeople, status }: Project,
    user: Person | null
  ): ProjectListItem {
    const projectRole = projectPeople.find(
      ({ personId }) => personId === user?.id
    );
    const respondentId = user ? this.getRespondentId(user, id) : '';
    if (respondentId) {
      if (
        projectRole?.respondent?.questionnaireStatus === 'Complete' ||
        projectRole?.respondent?.questionnaireStatus === 'CompleteSigned'
      ) {
        status = 'Complete';
      } else if (
        projectRole?.respondent?.questionnaireStatus === 'InProgress' ||
        projectRole?.respondent?.questionnaireStatus ===
          'InProgress-NotYetStarted'
      ) {
        status = 'Distributed';
      }
    }

    const lastActivity =
      user?.userType === UserType.internal
        ? `Created ${format(new Date(created || ''), 'MMM d, yyyy')}`
        : '';

    return {
      clientUrl: client?.url?.trim() || '',
      clientName: client?.clientName || '',
      lastActivity,
      projectId: id,
      projectName,
      respondentId,
      status: status || 'New',
      showClient: true,
    };
  }
}
