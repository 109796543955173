<ng-container [formGroup]="formGroup">
  <select
    class="form-select"
    formControlName="select"
    [compareWith]="props.compareWith"
    [formlyAttributes]="field"
  >
    <option *ngIf="props.placeholder" hidden [ngValue]="''">
      {{ props.placeholder }}
    </option>
    <ng-container
      *ngIf="props.options | formlySelectOptions: field | async as options"
    >
      <ng-container *ngFor="let option of options">
        <option
          [ngValue]="option.value"
          [disabled]="option.disabled"
          [title]="option.label"
        >
          {{ option.label }}
        </option>
      </ng-container>
      <option *ngIf="otherOptionLabel" [ngValue]="otherOptionLabel">
        {{ otherOptionLabel }}
      </option>
    </ng-container>
  </select>

  <div *ngIf="showOtherOptionText" class="mt-3">
    <textarea
      autocomplete="off"
      class="form-control"
      formControlName="text"
      rows="1"
    ></textarea>
  </div>
</ng-container>
