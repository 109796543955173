<footer class="bg-white border-top d-flex align-items-center p-3">
  <img src="/assets/images/cooley-logo.png" alt="Cooley logo" height="32" />
  <ng-content></ng-content>
  <div class="ms-auto">
    <div class="row row-cols-2 row-cols-lg-auto justify-content-end gy-3">
      <a class="col-auto text-dark" href="/faq">FAQs</a>
      <a class="col-auto text-dark" href="/terms-of-use">Terms of Use</a>
      <a class="col-auto text-dark" href="/cookie-policy">Cookie Policy</a>
      <a class="col-auto text-dark" href="/privacy-policy">Privacy Policy</a>
    </div>
  </div>
</footer>
