import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-summary-field-wrapper',
  templateUrl: './summary-field-wrapper.component.html',
})
export class SummaryFieldWrapperComponent extends FieldWrapper {
  get hasChanged(): boolean {
    return this.props['hasChanged'] || false;
  }
}
