<div *ngIf="layout === 'collapsed'" class="mb-3">
  <div
    *ngFor="let record of formControl.value; let i = index"
    class="card align-items-center flex-row ps-3 pe-2"
    role="button"
    style="margin-top: 2px; height: 3rem"
    (click)="openRepeatForm(i)"
  >
    <div class="flex-fill overflow-hidden">
      <ng-container
        *ngFor="let item of record | keyvalue: keepOrder; let j = index"
      >
        <div *ngIf="j < 2" [ngClass]="{ 'small text-muted': j }">
          {{ item.value }}
        </div>
      </ng-container>
    </div>
    <button
      class="btn cx-btn cx-btn--ghost cx-btn--icon ms-2"
      type="button"
      (click)="removeGroup(i)"
    >
      <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
    </button>
  </div>

  <button
    class="btn btn-outline-primary cx-btn my-3 py-2"
    type="button"
    (click)="openRepeatForm()"
  >
    Add <svg cdsIcon="add" fill="currentColor" size="16"></svg>
  </button>
</div>

<div *ngIf="layout === 'expanded'" class="hide-labels mb-3">
  <div class="d-none d-lg-block">
    <div *ngIf="field.fieldGroup?.length" class="row">
      <div *ngFor="let title of titles" class="col">
        <div class="mb-2 text-muted" [innerHTML]="title | safeHtml"></div>
      </div>
      <div class="col-auto" style="width: 80px"></div>
    </div>

    <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
      <formly-field class="col" [field]="field"></formly-field>
      <div class="col-auto">
        <button
          class="btn cx-btn cx-btn--ghost cx-btn--icon"
          type="button"
          (click)="removeGroup(i)"
        >
          <svg
            cdsIcon="trash-can"
            class="text-danger"
            fill="currentColor"
            size="16"
          ></svg>
        </button>
      </div>
    </div>

    <button
      class="btn btn-outline-primary cx-btn py-2"
      type="button"
      (click)="addGroup()"
    >
      Add <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  <div class="d-lg-none">
    <div
      *ngFor="let record of formControl.value; let i = index"
      class="card align-items-center flex-row ps-3 pe-2"
      role="button"
      style="margin-top: 2px; height: 3rem"
      (click)="openRepeatForm(i)"
    >
      <div class="flex-fill overflow-hidden">
        <ng-container
          *ngFor="let item of record | keyvalue: keepOrder; let j = index"
        >
          <div *ngIf="j < 2" [ngClass]="{ 'small text-muted': j }">
            {{ item.value }}
          </div>
        </ng-container>
      </div>
      <button
        class="btn cx-btn cx-btn--ghost cx-btn--icon ms-2"
        type="button"
        (click)="removeGroup(i)"
      >
        <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
      </button>
    </div>

    <button
      class="btn btn-outline-primary cx-btn my-3 py-2"
      type="button"
      (click)="openRepeatForm()"
    >
      Add <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>
</div>

<div
  *ngIf="layout === 'griddown'"
  class="hide-labels mb-3"
  [ngStyle]="{ 'max-width': compactWidth ? '80ch' : '100%' }"
>
  <div [ngClass]="repeatTitles.length > 0 ? 'd-block' : 'd-none d-lg-block'">
    <div
      *ngIf="field.fieldGroup?.length && repeatTitles.length === 0"
      class="row"
    >
      <div *ngFor="let title of titles" class="col">
        <div class="mb-2 text-muted" [innerHTML]="title | safeHtml"></div>
      </div>
      <div *ngIf="showButtons" class="col-auto" style="width: 80px"></div>
    </div>

    <p *ngIf="repeatTitles.length > 0 && !hideGroup">
      {{ props.label }}
    </p>

    <table
      *ngIf="!hideGroup"
      class="table table-borderless"
      [class.w-auto]="repeatTitles.length > 0"
    >
      <tbody>
        <tr *ngFor="let subField of field.fieldGroup; let i = index">
          <td class="p-0">
            <formly-field [field]="subField"></formly-field>
          </td>
          <td *ngIf="showButtons" class="align-top" style="width: 80px">
            <button
              class="btn cx-btn cx-btn--ghost cx-btn--icon"
              type="button"
              (click)="removeGroup(i)"
            >
              <svg
                cdsIcon="trash-can"
                class="text-danger"
                fill="currentColor"
                size="16"
              ></svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <button
      *ngIf="showButtons"
      class="btn btn-outline-primary cx-btn py-2"
      type="button"
      (click)="addGroup()"
    >
      Add <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  <div [ngClass]="repeatTitles.length > 0 ? 'd-none' : 'd-lg-none'">
    <div
      *ngFor="let record of formControl.value; let i = index"
      class="card align-items-center flex-row ps-3 pe-2"
      role="button"
      style="margin-top: 2px; height: 3rem"
      (click)="openRepeatForm(i)"
    >
      <div class="flex-fill overflow-hidden">
        <ng-container
          *ngFor="let item of record | keyvalue: keepOrder; let j = index"
        >
          <div *ngIf="j < 2" [ngClass]="{ 'small text-muted': j }">
            {{ item.value }}
          </div>
        </ng-container>
      </div>
      <button
        *ngIf="showButtons"
        class="btn cx-btn cx-btn--ghost cx-btn--icon ms-2"
        type="button"
        (click)="removeGroup(i)"
      >
        <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
      </button>
    </div>

    <button
      *ngIf="showButtons"
      class="btn btn-outline-primary cx-btn my-3 py-2"
      type="button"
      (click)="openRepeatForm()"
    >
      Add <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>
</div>

<div *ngIf="layout === 'readonly' && !hideGroup" class="mb-3">
  <p *ngIf="repeatTitles.length > 0">
    {{ props.label }}
  </p>
  <div
    *ngFor="let row of field.fieldGroup; let i = index; let count = count"
    class="border-bottom"
  >
    <h6 class="my-3 text-muted">{{ i + 1 }} of {{ count }}</h6>
    <div
      *ngFor="let item of row.fieldGroup | keyvalue: keepOrder; let j = index"
      class="row mb-2"
      style="white-space: break-spaces"
    >
      <div *ngIf="repeatTitles[i] as label" class="col-8">
        <div>{{ label }}</div>
      </div>
      <div *ngIf="!repeatTitles[i] && titles[j] as label" class="col-8">
        <div>{{ label }}</div>
      </div>
      <div class="col-1"></div>
      <div class="col-3">{{ model[i][$any(item.value).key] | answer }}</div>
    </div>
  </div>
</div>

<div *ngIf="layout === 'summary'">
  <div
    *ngFor="let s of summaryCount; let i = index; let count = count"
    class="border-bottom"
    [class.d-none]="repeatValuesAreEqual(i)"
  >
    <h6 class="my-3 text-muted">{{ i + 1 }} of {{ count }}</h6>
    <div
      *ngFor="
        let item of fieldArray.fieldGroup | keyvalue: keepOrder;
        let j = index
      "
      class="row mb-2"
    >
      <div class="col-8">{{ titles[j] }}</div>
      <div class="col-1"></div>
      <div class="col-3">
        <ng-container
          *ngTemplateOutlet="
            changeValues;
            context: {
              current:
                (summaryCurrentModel && summaryCurrentModel[i]
                  ? summaryCurrentModel[i][$any(item.value).key]
                  : null
                ) | answer,
              previous:
                (summaryPreviousModel && summaryPreviousModel[i]
                  ? summaryPreviousModel[i][$any(item.value).key]
                  : null
                ) | answer,
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #changeValues let-current="current" let-previous="previous">
  <div *ngIf="previous">
    <del class="text-muted" [class.d-none]="previous === current">
      {{ previous }}
    </del>
  </div>
  <div *ngIf="current">
    {{ current }}
    <span *ngIf="current === previous" class="text-muted">(no changes)</span>
  </div>
</ng-template>
