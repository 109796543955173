/**  */
import { Component, OnInit } from '@angular/core';

/** Component */
@Component({
  selector: 'app-base-home',
  templateUrl: './base-home.component.html',
  styleUrls: ['./base-home.component.css']
})
/** Class */
export class BaseHomeComponent {

  // constructor(
  // ) { }

  // ngOnInit(): void {
  // }

}
