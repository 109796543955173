import { FieldTypeConfig } from '@ngx-formly/core';
import { SelectComponent } from 'src/app/features/questionnaire';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import { QuestionnaireVariable } from 'src/app/models/interfaces';
import { evalExpression } from '../contract-express.functions';

export function getSelectFieldConfig(
  {
    layout,
    otheroption,
    otheroptiontext,
    prescribedoptions,
    unknownoptiontext: placeholder,
  }: QuestionnaireVariable,
  model?: QuestionnaireFormModel,
): Partial<FieldTypeConfig> {
  const formCheck = layout === 'Horizontal' ? 'inline' : 'default';
  const options = getOptions(prescribedoptions || '', model);
  const otherOptionText = otheroption ? otheroptiontext : undefined;
  return {
    type: SelectComponent,
    modelOptions: { updateOn: 'change' },
    props: { formCheck, otherOptionText, options, placeholder },
    hooks: {
      onInit: ({ form, parent, props }) => {
        const isInRepeatGroup = !!parent?.parent?.fieldArray;
        if (!isInRepeatGroup && form && props) {
          form.valueChanges.subscribe((formValues) => {
            props.options = getOptions(prescribedoptions || '', {
              ...model,
              formValues,
            });
          });
        }
      },
    },
  };
}

function getOptions(expression: string, model?: QuestionnaireFormModel): any[] {
  const options = evalExpression<string[]>(expression, model) || [];
  return options.map((label) => ({ label, value: label }));
}
