import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-theme-demo-html',
  templateUrl: './template-theme-demo-html.component.html',
  styleUrls: ['./template-theme-demo-html.component.css']
})
export class TemplateThemeDemoHtmlComponent implements OnInit {

  constructor() {
    //
   }

  ngOnInit(): void {
    //
  }

}
