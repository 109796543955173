/** Imports */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root'
})
/** Class */
export class templateLeftMenuService {

  /** left menu observable */
  private _leftMenuSource$ = new BehaviorSubject<any>({});
  public _leftMenuData$ = this._leftMenuSource$.asObservable();
  /** Constructor */
  constructor() {
    //
   }

  /** Set the left menu obj value */
  setLeftMenu(leftMenu: any) {
    this._leftMenuSource$.next(leftMenu);
  }

}
