import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { REPEAT_GROUP_SUFFIX } from 'src/app/core/services/contract-express/contract-express.service';
import { Modal } from 'src/app/models/enums';
import {
  RepeatTableColumnConfig,
  RepeatTableConfig,
} from 'src/app/models/interfaces';
import { RepeatFormComponent } from '../..';

@Component({
  selector: 'app-repeat-table',
  templateUrl: './repeat-table.component.html',
})
export class RepeatTableComponent extends FieldArrayType {
  get columns(): RepeatTableColumnConfig[] {
    return this.props['columns'];
  }

  get enableSummary(): boolean {
    return this.props['enableSummary'];
  }

  get fieldArray() {
    return this.field.fieldArray as FormlyFieldConfig;
  }

  get fieldKey() {
    return (this.field.key as string).replace(REPEAT_GROUP_SUFFIX, '');
  }

  get labels() {
    return this.props['labels'];
  }

  get preventColumnGrowth(): boolean {
    return this.columns.every((column) => column.width);
  }

  get summary(): RepeatTableConfig['summary'] {
    return this.props['summary'];
  }

  constructor(public modalService: BsModalService) {
    super();
  }

  addGroup(): void {
    // Certain CE fields require numeric counter of repeater groups
    // to be updated in real time for usage property to work correctly.
    // Adding a "band-aid" here for conditional fields to work.
    if (
      this.field.parent?.model &&
      !this.field.parent.model.hasOwnProperty(this.fieldKey)
    ) {
      this.field.parent.model[this.fieldKey] = 1;
    }

    this.add();
  }

  getField(name: string, index: number) {
    if (Array.isArray(this.field.fieldGroup)) {
      const row = this.field.fieldGroup[index];
      const fieldConfig = row.fieldGroup?.find(
        ({ key }) => key === name.toLowerCase(),
      );
      return fieldConfig;
    }

    return null;
  }

  keepOrder(): number {
    return 0;
  }

  openRepeatForm(index?: number): void {
    const fields =
      index !== undefined && this.field.fieldGroup
        ? this.field.fieldGroup[index].fieldGroup
        : this.fieldArray.fieldGroup;

    const model =
      index !== undefined
        ? { ...this.formControl.at(index).getRawValue() }
        : {};

    const modalRef = this.modalService.show(RepeatFormComponent, {
      class: 'modal-md modal-dialog-centered',
      id: Modal.RepeaterForm,
      initialState: {
        fields,
        form: new FormGroup({}),
        model,
        operation: index === undefined ? 'add' : 'update',
        repeatName: this.props['repeatName'],
      },
    });

    modalRef.content?.onSave$
      .pipe(takeUntil(modalRef.content.destroyed$))
      .subscribe((value) => {
        this.formControl.markAsDirty();
        if (index !== undefined) {
          this.formControl.at(index).patchValue(value);
          // We need to notify formly about model changes
          // hence resetModel call below.
          if (this.options.resetModel) {
            this.options.resetModel(this.form.getRawValue());
          }
        } else {
          this.add(undefined, value);
        }
      });
  }

  removeGroup(index: number): void {
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }
}
