/** Imports */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root'
})
/** Class */
export class templateHeaderMenuService {

  /** top menu object */
  // private _topMenuData: any;

  private _topMenuSource$ = new BehaviorSubject<any>({});
  public _topMenuData$ = this._topMenuSource$.asObservable();

  /** Constructor */
  constructor() {
    //
   }

  // /** Get the top menu object */
  // get topMenuData() {
  //   // console.log(this._topMenuData);
  //   return this._topMenuData;
  // }
  // /** Set the top menu object */
  // set topMenuData(newTopMenuData) {
  //   // console.log(newTopMenuData);
  //   this._topMenuData = newTopMenuData;
  // }

  setTopMenu(topMenu: any) {
    this._topMenuSource$.next(topMenu);
  }

}
