/** CommonUI: Template Required */
import { CommonUITemplateModule } from './_framing/_framing.module';
/** Angular specific: modify as required */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { ErrorInterceptor } from 'src/app/core/interceptors';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './core/auth.module';
import { CoreModule } from './core/core.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonUITemplateModule,
    CoreModule,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class AppModule {}
