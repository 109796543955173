/** Imports */
import { Component, OnInit } from '@angular/core';
import { TemplateHeaderSearchService } from '../_services/template-header-search.service';
import { environment } from '../../../environments/environment';
/** Component declaration */
@Component({
  selector: 'app-template-body',
  templateUrl: './template-body.component.html',
  styleUrls: ['./template-body.component.css']
})
/** Class */
export class TemplateBodyComponent implements OnInit {

  /** Search trigger */
  public displaySearch = false;
  public useBodySearch = environment.appSpecifics.searchAction;
  constructor(
    private templateHeaderSearchService: TemplateHeaderSearchService
  ) {
    // console.log(this.useBodySearch);
  }

  ngOnInit(): void {
    /** Subscribe to searching if searchAction = 'displayComponent' */
    if (this.useBodySearch === 'displayComponent') {
      this.templateHeaderSearchService._searching$.subscribe((value) => {
        let str = '';
        str = value.term;
        // console.log(value);
        if (str && value.display) {
          this.displaySearch = true;
        } else {
          this.displaySearch = false
        }
      });
    }
  }

}
