import { Component, Input, OnInit } from '@angular/core';
import { TemplateHeaderSearchService } from '../_services/template-header-search.service';

@Component({
  selector: 'app-template-header-search-displaylinks',
  templateUrl: './template-header-search-displaylinks.component.html',
  styleUrls: ['./template-header-search-displaylinks.component.css']
})
export class TemplateHeaderSearchDisplaylinksComponent implements OnInit {

  @Input() public results: any;
  constructor(
    private templateHeaderSearchService: TemplateHeaderSearchService
  ) {
    //
  }

  ngOnInit(): void {

    try {
      this.templateHeaderSearchService._searchResultsLinks$.subscribe((res) => {
        if (res) {
          console.log(res);
          this.processSearchResultsLinks(res);
        }
      });
    } catch (error) {
      console.log(error);
    }

  }

  /** processSearchResultsLinks */
  private processSearchResultsLinks(res: any) {
    console.log(res);
    this.results = res;
  }

}
