import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import buildQuery, { QueryOptions } from 'odata-query';
import { map, Observable, take } from 'rxjs';
import { OdataEntitySetResponse } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OdataBackendService {
  apiUrl = `${environment.appLeveragedApiEndpoints.apiUrl}/v1`;

  constructor(private http: HttpClient) {}

  deleteEntity(entityType: string, id: string): Observable<void> {
    return this.http
      .delete<void>(`${this.apiUrl}/${entityType}/${id}`)
      .pipe(take(1));
  }

  getEntity<T>(
    entityType: string,
    id: string,
    queryOptions?: Partial<QueryOptions<T>>
  ): Observable<T> {
    const query = buildQuery(queryOptions);
    return this.http
      .get<T>(`${this.apiUrl}/${entityType}/${id}${query}`)
      .pipe(take(1));
  }

  getEntitySet<T>(
    entityType: string,
    queryOptions?: Partial<QueryOptions<T>>
  ): Observable<T[]> {
    const query = buildQuery(queryOptions);
    return this.http
      .get<OdataEntitySetResponse<T>>(`${this.apiUrl}/${entityType}${query}`)
      .pipe(
        take(1),
        map(({ value }) => value || [])
      );
  }

  getEntitySetCount(
    entityType: string,
    queryOptions?: Partial<QueryOptions<unknown>>
  ): Observable<number> {
    const query = buildQuery({ ...queryOptions, count: true, top: 0 });
    return this.http
      .get<OdataEntitySetResponse<unknown> & { '@odata.count': number }>(
        `${this.apiUrl}/${entityType}${query}`
      )
      .pipe(
        take(1),
        map((response) => response['@odata.count'] || 0)
      );
  }

  postEntity<T>(
    entityType: string,
    body: Partial<T>,
    queryOptions?: Partial<QueryOptions<T>>
  ): Observable<T> {
    const query = buildQuery(queryOptions);
    return this.http
      .post<T>(`${this.apiUrl}/${entityType}${query}`, body)
      .pipe(take(1));
  }

  patchEntity<T>(
    entityType: string,
    id: string,
    body: Partial<T>,
    queryOptions?: Partial<QueryOptions<T>>
  ): Observable<T> {
    const query = buildQuery(queryOptions);
    return this.http
      .patch<T>(`${this.apiUrl}/${entityType}/${id}${query}`, body)
      .pipe(take(1));
  }

  search<T>(
    q: string,
    queryOptions?: Partial<QueryOptions<T>>
  ): Observable<T[]> {
    const query = buildQuery(queryOptions);
    const queryParams = query ? `(q='${q}')${query}` : `(q='${q}')`;
    return this.http
      .get<OdataEntitySetResponse<T>>(
        `${this.apiUrl}/Search/RawResults${queryParams}`
      )
      .pipe(
        take(1),
        map(({ value }) => value || [])
      );
  }
}
