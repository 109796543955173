import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormControlStatus, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { auditTime, startWith, Subject, takeUntil } from 'rxjs';
import { ActivePageService } from 'src/app/core/services';
import { QuestionnaireFormModel } from 'src/app/models/aliases';

@Component({
  selector: 'app-questionnaire-page',
  templateUrl: './questionnaire-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnairePageComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() fields: FormlyFieldConfig[] = [];
  @Input() guidance? = '';
  @Input() model: QuestionnaireFormModel = {};
  @Input() title: string | null = '';
  @Input() subtitle: string | null = '';
  @Output() statusChanges = new EventEmitter<FormControlStatus>();
  @Output() valueChanges = new EventEmitter<QuestionnaireFormModel>();

  destroyed$ = new Subject<boolean>();
  form = new FormGroup({});
  options: FormlyFormOptions = {
    showError: () => false,
  };

  constructor(private activePageService: ActivePageService) {}

  ngOnInit(): void {
    this.listenToStatusChanges();
    this.listenToValueChanges();
    this.listenToValidationErrors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields']) {
      // Ensure the form gets cleared from obsolete controls.
      Object.keys(this.form.controls).forEach((field: string) =>
        this.form.removeControl(field, { emitEvent: false })
      );
      // If fields params change, mark the form as pristine
      // in order to avoid extra value changes emmissions.
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private listenToStatusChanges(): void {
    this.form.statusChanges
      .pipe(
        takeUntil(this.destroyed$),
        startWith(this.form.status),
        auditTime(1000)
      )
      .subscribe((status) => this.statusChanges.emit(status));
  }

  private listenToValueChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      // Detect changed (dirty) controls and emit their values.
      const entries = Object.entries<FormControl>(this.form.controls);
      const values = entries.reduce<QuestionnaireFormModel>(
        (changes, [key, control]) => {
          if (control.dirty) {
            return { ...changes, [key]: control.getRawValue() };
          }
          return changes;
        },
        {}
      );
      setTimeout(() => this.form.markAsPristine(), 0);
      this.valueChanges.emit(values);
    });
  }

  private listenToValidationErrors(): void {
    this.activePageService.validationError$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((field) => this.form.get(field)?.setValue(''));

    this.activePageService.validationFix$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([field, value]) => {
        this.form.get(field)?.markAsDirty();
        this.form.get(field)?.setValue(value);
      });
  }
}
