import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { isNil } from 'lodash';

@Component({
  selector: 'app-change-report-field',
  templateUrl: './change-report-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeReportFieldComponent extends FieldType<FormlyFieldConfig> {
  get currentValue(): string | boolean {
    const value = this.props['currentValue'];

    if (value === true && !this.useIcon) {
      return 'Yes';
    } else if (value === false && !this.useIcon) {
      return 'No';
    } else if (Array.isArray(value)) {
      return this.mapValuesToList(value);
    } else if (value === '' && !this.previousValue && this.isMulticheckbox) {
      return 'None selected';
    }

    return value;
  }

  get previousValue(): string {
    const value = this.props['previousValue'];

    if (value === true && !this.useIcon) {
      return 'Yes';
    } else if (value === false && !this.useIcon) {
      return 'No';
    } else if (Array.isArray(value)) {
      return this.mapValuesToList(value);
    }

    return value;
  }

  get isMulticheckbox(): boolean {
    return this.props['multicheckbox'];
  }

  get showCurrentValue(): boolean {
    return !isNil(this.currentValue) && this.currentValue !== '';
  }

  get showPreviousValue(): boolean {
    return (
      this.props['hasChanged'] &&
      !isNil(this.previousValue) &&
      this.previousValue !== ''
    );
  }

  get useIcon(): boolean {
    return this.props['useIconDisplay'];
  }

  private mapValuesToList(values: string[]): string {
    return `<ul><li>${values.join('</li><li>')}</li></ul>`;
  }
}
