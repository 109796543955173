<cx1ui-header
  *ngIf="showHeader$ | async"
  [envConfig]="env"
  [topMenu]="topNavMenu"
>
  <app-base-topnav-menu></app-base-topnav-menu>
  <!-- Temporary disable these until we can control them with a service.
    <app-template-header-search topSearch></app-template-header-search>
    <app-template-header-help topHelp></app-template-header-help>
    -->
  <app-base-topnav-extra topExtra></app-base-topnav-extra>
  <app-template-header-user topUser></app-template-header-user>
</cx1ui-header>
