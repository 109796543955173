/**
[COMMONUI STARTER TEMPLATE: TEMPLATE SERVICE / TITLE SERVICE: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
This required service comes bundled with the CommonUI template. Edits are not permitted.
Passing a string will result in the tab/bookmark/other title being updated to display your env title and the passed string with a ": " splitter.

Dynamic changes to the displayed title in the header are neither covered by this service nor allowed.

[COMPLIANCE]
Expect this file to be routinely scanned for compliance.

Do not edit or remove this content.

[EXCEPTIONS]
Exceptions to this guidance are not permitted. Edits to this file are not permitted.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
*/
/**
 * Imports
 */
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from  '../../../environments/environment';
/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root'
})
/** Class */
export class templateTitleService {

  /** Displayed title */
  private appTitle: string;
  /** Constructor */
  constructor(
    private titleandmetadataService: Title
  ) {
    /** Define the app title */
    this.appTitle = environment.appSpecifics.title;
   }

  /**
   * setTitle service
   * @param newTitle string Sets the title to a new value.
   */
  public setTitle(newTitle: string) {
    /**
     * Try/catch on set item
     */
    try {

      /**
       * Prepend the title seperated with a ": " as per the CommonUI template standard
       */
      this.titleandmetadataService.setTitle(this.appTitle + ': ' + newTitle);

    } catch (error) {

      /**
       * On error condition console out.
       */
      console.log(error);

    }
  }

}
