import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { InvalidAnswerModalComponent } from 'src/app/features/modals';
import { GlobalPageStatus } from 'src/app/models/aliases';
import { Modal } from 'src/app/models/enums';
import {
  Page,
  QuestionnaireAnswer,
  RespondentPage,
  ValidationErrorResponse,
} from 'src/app/models/interfaces';
import { OdataBackendService } from '..';

@Injectable({
  providedIn: 'root',
})
export class ActivePageService {
  private _page = new BehaviorSubject<Page | null>(null);
  get page$(): Observable<Page | null> {
    return this._page.asObservable();
  }
  get page(): Page | null {
    return this._page.getValue();
  }
  set page(value: Page | null) {
    this._page.next(value);
  }

  get respondentPages$(): Observable<RespondentPage[]> {
    return this.page$.pipe(map((page) => page?.respondentPages || []));
  }

  private _savingAnswers = new BehaviorSubject<boolean>(false);
  get savingAnswers$() {
    return this._savingAnswers.asObservable();
  }
  get savingAnswers() {
    return this._savingAnswers.getValue();
  }
  set savingAnswers(value: boolean) {
    this._savingAnswers.next(value);
  }

  validationError$ = new Subject<string>();
  validationFix$ = new Subject<[string, string]>();

  constructor(
    private modalService: BsModalService,
    private odataBackend: OdataBackendService,
  ) {}

  loadPage(pageId: string): Observable<Page> {
    return this.odataBackend
      .getEntity<Page>('Pages', pageId, {
        expand: {
          respondentPages: {
            expand: {
              respondent: {
                expand: ['person'],
              },
            },
            orderBy: 'respondent/person/name',
          },
        },
      })
      .pipe(tap((page) => (this.page = page)));
  }

  saveAnswers(
    pageId: string,
    answers: QuestionnaireAnswer[],
    respondentId: string | null = null,
  ): Observable<boolean> {
    return this.odataBackend
      .postEntity(`Pages/${pageId}/SaveAnswers`, { answers, respondentId })
      .pipe(
        map(() => true),
        catchError(({ status, error }: HttpErrorResponse) => {
          console.error(error);
          if (status === 400 && error.hasOwnProperty('validationErrors')) {
            this.showValidationErrors(error.validationErrors);
          }
          throw error;
        }),
      );
  }

  updatePageStatus(
    pageId: string,
    globalPageStatus: GlobalPageStatus,
  ): Observable<unknown> {
    return this.odataBackend
      .patchEntity<Page>('Pages', pageId, { globalPageStatus })
      .pipe(tap((page) => (this.page = page)));
  }

  updateRespondentPageStatus(
    pageId: string,
    respondentPageStatus: GlobalPageStatus,
    isManualCompletion?: boolean,
  ): Observable<RespondentPage> {
    return this.odataBackend.patchEntity<RespondentPage>(
      'RespondentPages',
      pageId,
      { isManualCompletion, respondentPageStatus },
    );
  }

  private showValidationErrors(errors: ValidationErrorResponse[]): void {
    const [error] = errors;
    const { question, sanitizedAnswer } = error;
    this.validationError$.next(question);
    this.modalService.show(InvalidAnswerModalComponent, {
      id: Modal.InvalidAnswer,
      initialState: {
        question,
        sanitizedAnswer,
      },
    });
  }
}
