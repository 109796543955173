<div
  *ngIf="field"
  class="row"
  [ngClass]="{
    'alert alert-info mb-0 px-0': hasChanged,
    'my-4 unchanged-field': !hasChanged
  }"
>
  <div class="col-8 summary-details">
    <div
      *ngIf="props['prompt']"
      appGlossaryTerm
      class="field-label mb-3"
      [innerHTML]="props['prompt'] | safeHtml"
      style="max-width: 80ch; white-space: pre-wrap"
    ></div>

    <div
      *ngIf="props.label"
      appGlossaryTerm
      [innerHTML]="props.label | safeHtml"
      style="max-width: 80ch; white-space: pre-wrap"
    ></div>
  </div>

  <div class="col-3 offset-1">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
