<div class="hide-labels mb-3">
  <div class="d-none d-lg-block">
    <table class="table table-sm w-100">
      <tbody>
        <tr class="border-bottom">
          @for (column of columns; track column) {
            @if (hasField(column.key)) {
              <td
                class="align-bottom py-2"
                scope="col"
                [style.width]="column.width"
              >
                {{ column.label }}
              </td>
            }
          }
        </tr>
        @for (field of field.fieldGroup; track field; let i = $index) {
          <tr class="border-bottom">
            @if (
              getField("Signatory_CompanySecuritiesStockClass", i);
              as subfield
            ) {
              <td class="align-top pt-3">
                <formly-field [field]="subfield"></formly-field>
              </td>
            }
            @if (
              getField("Signatory_CompanySecuritiesNoShares", i);
              as subfield
            ) {
              <td class="align-top pt-3">
                <formly-field [field]="subfield"></formly-field>
              </td>
            }
            <td class="align-top pt-3">
              <formly-field
                *ngIf="
                  getField(
                    'Signatory_CompanySecuritiesRegisteredName',
                    i
                  ) as subfield
                "
                [field]="subfield"
              ></formly-field>
              <div class="ms-3 mt-n2">
                <div class="text-muted">Relationship to beneficial owner</div>
                <formly-field
                  *ngIf="
                    getField(
                      'Signatory_CompanySecuritiesRelationship',
                      i
                    ) as subfield
                  "
                  [field]="subfield"
                ></formly-field>
              </div>
            </td>
            @if (
              getField("Signatory_CompanySecuritiesSharedVoting", i);
              as subfield
            ) {
              <td class="align-top pt-3">
                <formly-field [field]="subfield"></formly-field>
              </td>
            }
            @if (
              getField("Signatory_CompanySecuritiesSharedInvestment", i);
              as subfield
            ) {
              <td class="align-top pt-3">
                <formly-field [field]="subfield"></formly-field>
              </td>
            }
            @if (
              getField("Signatory_CompanySecuritiesSecuritiesPledged", i);
              as subfield
            ) {
              <td class="align-top pt-3">
                <formly-field [field]="subfield"></formly-field>
              </td>
            }
            <td class="align-top pt-3">
              <button
                class="btn cx-btn cx-btn--ghost cx-btn--icon"
                type="button"
                (click)="removeGroup(i)"
              >
                <svg
                  cdsIcon="trash-can"
                  class="text-danger"
                  fill="currentColor"
                  size="16"
                ></svg>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>

    <button
      class="btn btn-outline-primary cx-btn"
      [class.w-100]="!field.fieldGroup?.length"
      [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
      type="button"
      (click)="addGroup()"
    >
      {{
        field.fieldGroup?.length
          ? "Add additional securities"
          : "Add one or more securities"
      }}
      <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  <div class="d-lg-none">
    <div
      *ngFor="let record of formControl.value; let i = index"
      class="card align-items-center flex-row ps-3 pe-2"
      role="button"
      style="margin-top: 2px; height: 3rem"
      (click)="openRepeatForm(i)"
    >
      <div class="flex-fill overflow-hidden">
        <ng-container
          *ngFor="let item of record | keyvalue: keepOrder; let j = index"
        >
          <div *ngIf="j < 2" [ngClass]="{ 'small text-muted': j }">
            {{ item.value }}
          </div>
        </ng-container>
      </div>
      <button
        class="btn cx-btn cx-btn--ghost cx-btn--icon ms-2"
        type="button"
        (click)="removeGroup(i)"
      >
        <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
      </button>
    </div>

    <button
      class="btn btn-outline-primary cx-btn my-3 w-100"
      [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
      type="button"
      (click)="openRepeatForm()"
    >
      {{
        field.fieldGroup?.length
          ? "Add additional securities"
          : "Add one or more securities"
      }}
      <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>
</div>
