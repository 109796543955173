<div aria-labelledby="global-search-label" class="cx-search cx-search--global cx-search--toolbar"
  [ngClass]="{ 'cx-search--has-focus': searching }" id="global-search" tabindex="-1">
  <label id="global-search-label" for="global-search-input" class="visually-hidden">
    Global search label
  </label>
  <input aria-autocomplete="list" aria-expanded="false" aria-hidden="false" autocomplete="off" class="cx-search__input"
    [ngClass]="{ 'pe-3': searching }" [formControl]="searchField" (focus)="onFocus()" id="global-search-input"
    placeholder="{{ searchFieldStringDisplayed }}" role="searchbox" tabindex="0" type="search" value="" />
  <!-- Display if searchAction = 'displaylinks' -->
  <app-base-search-links></app-base-search-links>
  <div *ngIf="searching && searchAction === 'displayLinks'">
    <app-template-header-search-displaylinks></app-template-header-search-displaylinks>
  </div>
</div>
