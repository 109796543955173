import { Component, OnChanges } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { TemplatesecondaryHeaderDisplayedService } from '../../_framing/_services/template-secondary-header.service';
@Component({
  selector: 'app-base-header-secondary',
  templateUrl: './base-header-secondary.component.html',
  styleUrls: ['./base-header-secondary.component.css']
})
export class BaseHeaderSecondaryComponent implements OnChanges {

  private currentRoute = '';
  constructor(
    private router: Router,
    private templatesecondaryHeaderDisplayedService: TemplatesecondaryHeaderDisplayedService
  ) {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.currentRoute = event.url.replace('/','').toLowerCase();
          /** Set left menu */
          this.toggleSecondaryHeader();
        }
      });
  }

  ngOnChanges(): void {
    this.toggleSecondaryHeader();
  }

  private toggleSecondaryHeader(){
    if (this.currentRoute == 'design') {
      this.templatesecondaryHeaderDisplayedService.toggleSecondaryHeaderDisplay(true);
    } else {
      this.templatesecondaryHeaderDisplayedService.toggleSecondaryHeaderDisplay(false);
    }
  }

}
