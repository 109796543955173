import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, map } from 'rxjs';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import {
  Answer,
  Questionnaire,
  QuestionnaireAnswer,
} from 'src/app/models/interfaces';
import { OdataBackendService } from '..';
import { getPageConfig as getAnswerSummaryPageConfig } from './answer-summary.field-configs';
import { getPageConfig as getChangeSummaryPageConfig } from './change-summary.field-configs';
import { evalExpression } from './contract-express.functions';
import { getPageConfig as getQuestionnairePageConfig } from './questionnaire.field-configs';

export const REPEAT_GROUP_SUFFIX = '_repeat';
export const ALL_OPTION_TEXT = 'dballoption';

@Injectable({
  providedIn: 'root',
})
export class ContractExpressService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private odataBackend: OdataBackendService,
  ) {}

  appendScript(renderer: Renderer2, scripts: string): void {
    const scriptsElement = this.document.getElementById('ceq-scripts');
    if (scriptsElement !== null) {
      scriptsElement.parentNode?.removeChild(scriptsElement);
    }
    const script = renderer.createElement('script');
    script.id = 'ceq-scripts';
    script.type = 'text/javascript';
    script.text = `var cePage = ${scripts};`;
    renderer.appendChild(this.document.body, script);
  }

  eval<T>(expression: string, model?: Record<string, unknown>): T | null {
    return evalExpression<T>(expression, model);
  }

  getAnswers(
    pageId: string,
    respondentId: string | null = null,
  ): Observable<Answer[]> {
    return this.odataBackend
      .postEntity<any>(
        `Pages/${pageId}/GetAnswers`,
        { respondentId },
        { expand: ['question'] },
      )
      .pipe(map(({ value }) => value));
  }

  getAnswerSummaryFieldConfigs(
    model?: QuestionnaireFormModel,
  ): FormlyFieldConfig[] {
    return getAnswerSummaryPageConfig(model);
  }

  getFormFieldConfigs(
    model?: QuestionnaireFormModel,
    options?: Record<string, unknown>,
  ): FormlyFieldConfig[] {
    return getQuestionnairePageConfig(model, options);
  }

  getPageGuidance(model?: QuestionnaireFormModel): string {
    const [pageData] = (window as any).cePage || [];
    if (pageData) {
      const { activatedpage, questionnairepages } = pageData as Questionnaire;
      const { guidance, guidanceexpression } =
        questionnairepages[activatedpage];
      return guidanceexpression
        ? evalExpression<string[]>(guidanceexpression, model)?.join('') || ''
        : guidance || '';
    }

    return '';
  }

  getChangeReportFieldConfigs(
    previous: QuestionnaireFormModel,
    current: QuestionnaireFormModel,
  ): FormlyFieldConfig[] {
    return getChangeSummaryPageConfig(previous, current);
  }

  mapFormValuesToAnswers(
    values: QuestionnaireFormModel,
  ): QuestionnaireAnswer[] {
    return Object.entries(values).reduce<QuestionnaireAnswer[]>(
      (answers, [question, answer]) => {
        // if answer is an array, then it's coming from a repeater field array
        if (Array.isArray(answer)) {
          answer.forEach((group, index) => {
            if (group === null) {
              answers.push({
                question,
                answer: '',
                known: false,
                repeatContext: index + 1,
              });
            } else if (typeof group === 'string') {
              answers.push({
                question,
                answer: group,
                repeatContext: index + 1,
              });
            } else {
              Object.entries(group || {}).forEach(([subQuestion, subAnswer]) =>
                answers.push({
                  question: subQuestion,
                  answer: subAnswer?.toString(),
                  repeatContext: index + 1,
                  repeatGroupName: question,
                }),
              );
            }
          });
          if (question.includes(REPEAT_GROUP_SUFFIX)) {
            // Update corresponding repeater group counter that CE requires.
            answers.push({
              question: question.replace(REPEAT_GROUP_SUFFIX, ''),
              answer: answer.length.toString(),
            });
          } else if (answer.length === 0) {
            // If it's not a repeat group and an empty array,
            // set it's value to empty string.
            answers.push({ question, answer: '', known: true });
          }
          // if answer is an object, then it's coming from a repeater field group
          // } else if (typeof answer === 'object' && answer !== null) {
          //   Object.entries(answer).forEach(([subQuestion, subAnswer]) => {
          //     const questionContext = subQuestion.split(REPEAT_CONTEXT_SUFFIX);
          //     answers.push({
          //       question: questionContext[0],
          //       answer: (subAnswer as any)?.toString(),
          //       repeatContext: Number(questionContext[1]),
          //       repeatGroupName: question,
          //     });
          //   });
          // } else if (question.includes(REPEAT_CONTEXT_SUFFIX)) {
          //   const questionContext = question.split(REPEAT_CONTEXT_SUFFIX);
          //   question = questionContext[0];
          //   answers.push({
          //     question: questionContext[0],
          //     answer: answer?.toString(),
          //     repeatContext: Number(questionContext[1]),
          //   });
        } else {
          const known = answer === null ? false : undefined;
          answers.push({ question, answer: answer?.toString(), known });
        }

        return answers;
      },
      [],
    );
  }
}
