import { Component, OnChanges, OnInit } from '@angular/core';
import { TemplatesecondaryHeaderDisplayedService } from '../../_framing/_services/template-secondary-header.service';

@Component({
  selector: 'app-template-header-secondary',
  templateUrl: './template-header-secondary.component.html',
  styleUrls: ['./template-header-secondary.component.css']
})
export class TemplateHeaderSecondaryComponent implements OnInit, OnChanges {

  public display:any;
  public displaySecondaryHeader = false;
  constructor(
    private templatesecondaryHeaderDisplayedService: TemplatesecondaryHeaderDisplayedService
  ) {
    //
   }

  ngOnInit(): void {
    try {
      this.templatesecondaryHeaderDisplayedService._secondaryHeaderDisplayedSource$.subscribe((display) => {
        if (display) {
          this.display = display;
          this.processSecondaryHeader();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  ngOnChanges(): void {
    this.processSecondaryHeader();
  }

  private processSecondaryHeader() {
    console.log(this.display);
    if (typeof this.display == 'boolean') {
      this.displaySecondaryHeader = this.display;
    } else {
      this.displaySecondaryHeader = false;
    }
  }

}
