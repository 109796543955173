<input
  *ngIf="isDateInput; else yearInput"
  type="date"
  class="form-control"
  [class.is-invalid]="showError"
  [formControl]="formControl"
  [formlyAttributes]="field"
  max="9999-12-31"
  style="padding-right: 0.75rem; width: 160px"
/>

<ng-template #yearInput>
  <input
    type="text"
    [formControl]="formControl"
    class="form-control"
    [formlyAttributes]="field"
    [class.is-invalid]="showError"
    mask="0000"
    style="width: 160px"
  />
</ng-template>
