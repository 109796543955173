import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root',
})
export class templateUserProfileServiceService {
  private _currentUserName = new BehaviorSubject<string>('');
  private endPoint = `https://${environment.commonUIenvConfig.userServiceURL}/handlers/currentuser.ashx`;

  get currentUserName$(): Observable<string> {
    return this._currentUserName.asObservable();
  }
  get currentUserName(): string {
    return this._currentUserName.getValue();
  }
  set currentUserName(value: string) {
    this._currentUserName.next(value);
  }

  constructor(private http: HttpClient) {}

  getProfile() {
    return this.http
      .get<any>(this.endPoint)
      .pipe(tap(({ DisplayName }) => (this.currentUserName = DisplayName)));
  }
}
