import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Cx1uiModule } from '@cooley/cx1ui';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IconsModule } from './icons.module';

@NgModule({
  imports: [
    CommonModule,
    Cx1uiModule,
    HttpClientModule,
    IconsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ],
  exports: [CommonModule, Cx1uiModule, IconsModule, TooltipModule],
  providers: [],
})
export class CoreModule {}
