import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { isFuture } from 'date-fns';
import { AccessTokenResponse } from 'src/app/models/interfaces';

export const clearAccessToken = () => {
  sessionStorage.removeItem('magiclink.token');
};

export const getAccessToken = (): string | null => {
  const token = sessionStorage.getItem('magiclink.token');
  if (token) {
    try {
      const { accessToken, expiration } = JSON.parse(
        token
      ) as AccessTokenResponse;
      if (accessToken && isFuture(new Date(expiration))) {
        return accessToken;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};

export const getActiveAccount = (
  instance: IPublicClientApplication
): AccountInfo | null => {
  const activeAccount = instance.getActiveAccount();
  if (activeAccount) {
    return activeAccount;
  }

  const allAccounts = instance.getAllAccounts();
  if (allAccounts.length) {
    const [firstAccount] = allAccounts;
    instance.setActiveAccount(firstAccount);
    return firstAccount;
  }

  return null;
};
