import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { Settings } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiUrl = environment.appLeveragedApiEndpoints.apiUrl;

  private _settings = new BehaviorSubject<Settings | null>(null);
  get settings$(): Observable<Settings | null> {
    return this._settings.asObservable();
  }
  get settings(): Settings | null {
    return this._settings.getValue();
  }
  set settings(value: Settings | null) {
    this._settings.next(value);
  }

  get apryseKey$(): Observable<string> {
    return this.settings$.pipe(
      map((settings) => (settings ? settings.apryseKey : ''))
    );
  }

  constructor(private httpClient: HttpClient) {}

  getAppSettings(): Observable<Settings> {
    return this.httpClient.get<Settings>(`${this.apiUrl}/v1/UISettings`).pipe(
      take(1),
      tap((settings) => (this.settings = settings))
    );
  }
}
