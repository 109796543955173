import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { REPEAT_GROUP_SUFFIX } from 'src/app/core/services/contract-express/contract-express.service';
import { Modal } from 'src/app/models/enums';
import { RepeatFormComponent } from '../..';

@Component({
  selector: 'app-company-securities',
  templateUrl: './company-securities.component.html',
})
export class CompanySecuritiesComponent extends FieldArrayType {
  columns = [
    {
      label: 'Class and/or series',
      key: 'signatory_companysecuritiesstockclass',
      width: '11rem',
    },
    {
      label: 'Number of shares',
      key: 'signatory_companysecuritiesnoshares',
      width: '11rem',
    },
    {
      label: 'Registered to',
      key: 'signatory_companysecuritiesregisteredname',
    },
    {
      label: 'Shared voting control',
      key: 'signatory_companysecuritiessharedvoting',
      width: '10rem',
    },
    {
      label: 'Shared investment control?',
      key: 'signatory_companysecuritiessharedinvestment',
      width: '10rem',
    },
    {
      label: 'Shares pledged as security?',
      key: 'signatory_companysecuritiessecuritiespledged',
      width: '10rem',
    },
    { label: '', width: '4rem' },
  ];

  get fieldArray() {
    return this.field.fieldArray as FormlyFieldConfig;
  }

  get fieldKey() {
    return (this.field.key as string).replace(REPEAT_GROUP_SUFFIX, '');
  }

  constructor(private modalService: BsModalService) {
    super();
  }

  addGroup(): void {
    // Certain CE fields require numeric counter of repeater groups
    // to be updated in real time for usage property to work correctly.
    // Adding a "band-aid" here for conditional fields to work.
    if (
      this.field.parent?.model &&
      !this.field.parent.model.hasOwnProperty(this.fieldKey)
    ) {
      this.field.parent.model[this.fieldKey] = 1;
    }

    this.add();
  }

  getField(name: string, index: number) {
    if (Array.isArray(this.field.fieldGroup)) {
      const row = this.field.fieldGroup[index];
      const fieldConfig = row.fieldGroup?.find(
        ({ key }) => key === name.toLowerCase(),
      );
      return fieldConfig;
    }

    return null;
  }

  hasField(name?: string) {
    return (
      !name ||
      this.fieldArray?.fieldGroup?.some(({ key }) => key === name.toLowerCase())
    );
  }

  keepOrder(): number {
    return 0;
  }

  openRepeatForm(index?: number): void {
    const fields =
      index !== undefined && this.field.fieldGroup
        ? this.field.fieldGroup[index].fieldGroup
        : this.fieldArray.fieldGroup;

    const model =
      index !== undefined
        ? { ...this.formControl.at(index).getRawValue() }
        : {};

    const modalRef = this.modalService.show(RepeatFormComponent, {
      class: 'modal-md modal-dialog-centered',
      id: Modal.RepeaterForm,
      initialState: {
        fields,
        form: new FormGroup({}),
        model,
        operation: index === undefined ? 'add' : 'update',
      },
    });

    modalRef.content?.onSave$
      .pipe(takeUntil(modalRef.content.destroyed$))
      .subscribe((value) => {
        this.formControl.markAsDirty();
        if (index !== undefined) {
          this.formControl.at(index).patchValue(value);
          // We need to notify formly about model changes
          // hence resetModel call below.
          if (this.options.resetModel) {
            this.options.resetModel(this.form.getRawValue());
          }
        } else {
          this.add(undefined, value);
        }
      });
  }

  removeGroup(index: number): void {
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }
}
