import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  take,
  tap,
  throwError,
} from 'rxjs';
import { Client } from 'src/app/models/interfaces';
import { ClientWithNameAndNumber } from 'src/app/models/interfaces/client';
import { EntityMapperService, OdataBackendService } from '..';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private entityMapperService: EntityMapperService,
    private odataBackendService: OdataBackendService
  ) {}

  private _clients$ = new BehaviorSubject<ClientWithNameAndNumber[]>([]);

  get clients$(): Observable<ClientWithNameAndNumber[]> {
    return this._clients$.asObservable().pipe();
  }

  set clients(value: ClientWithNameAndNumber[]) {
    this._clients$.next(value);
  }

  searchClients(searchTerm?: string): Observable<ClientWithNameAndNumber[]> {
    const filter = {
      or: [
        `contains(clientNumber, '${searchTerm}')`,
        `contains(clientName, '${searchTerm}')`,
      ],
    };

    return this.odataBackendService
      .getEntitySet<Client>('Clients', searchTerm ? { filter, top: 20 } : {})
      .pipe(
        take(1),
        map((clients) =>
          clients.map((client) => this.entityMapperService.mapClient(client))
        ),
        tap((clients: ClientWithNameAndNumber[]) => (this.clients = clients)),
        catchError((error) => throwError(error))
      );
  }
}
