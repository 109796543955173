<div class="modal-body">
  <div class="d-flex flex-column gap-3 text-center">
    <div class="cx-loading" style="height: auto">
      <svg
        class="cx-loading__spinner"
        width="64px"
        height="64px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="path"
          fill="none"
          stroke-width="8"
          stroke-linecap="round"
          cx="32"
          cy="32"
          r="28"
        ></circle>
      </svg>
    </div>
    <h6>The system is undergoing maintenance.</h6>
  </div>
</div>
