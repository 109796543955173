<button
  *ngIf="!fieldValue"
  class="btn btn-outline-primary cx-btn"
  type="button"
  (click)="openModal(template)"
>
  Upload a file
  <svg ibmIconDocumentAdd fill="currentColor" size="16"></svg>
</button>

<div
  *ngIf="fieldValue"
  class="card align-items-center flex-row pe-2"
  style="margin-top: 2px; height: 3rem"
>
  <button class="btn btn-link flex-fill" type="button" (click)="openFile()">
    {{ fieldValue }}
  </button>
  <button
    class="btn cx-btn cx-btn--ghost cx-btn--icon ms-2"
    type="button"
    (click)="remove()"
  >
    <svg ibmIconTrashCan fill="currentColor" size="16"></svg>
  </button>
</div>

<ng-template #template>
  <div class="modal-header border-0 pb-0">
    <h5>Upload an Exhibit</h5>
    <button class="btn-close" type="button" (click)="closeModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="uploadForm">
    <div class="form-group mb-4">
      <label for="resource" class="form-label">Select a file</label>
      <div class="input-group">
        <select class="form-select" formControlName="resource" id="resource">
          <option [value]="null" hidden selected>Select a file...</option>
          <option
            *ngFor="let resource of resources$ | async"
            [ngValue]="resource"
          >
            {{ resource.fileName }}
          </option>
          <option [value]="{}">Upload a new file</option>
        </select>
        <ng-container *ngIf="uploadForm.controls.resource.value as resource">
          <button
            *ngIf="resource.id"
            class="btn btn-outline-secondary cx-btn"
            type="button"
            (click)="openFile(resource.id)"
          >
            Preview
            <svg ibmIconLaunch fill="currentColor" size="16"></svg>
          </button>
        </ng-container>
      </div>
    </div>

    <div *ngIf="showFileUpload" class="form-group mb-4">
      <input
        type="file"
        accept=".doc, .docx, .xls, .xlsx, .pdf"
        (change)="onFileChange($event)"
      />
      <div class="text-muted small mt-2">
        Only file types of DOC(X), XLS(X), or PDF are permitted.
      </div>
    </div>

    <div class="form-group">
      <label class="form-label">Apply to</label>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          formControlName="applyTo"
          id="applyToSignle"
          [value]="'single'"
        />
        <label class="form-check-label" for="applyToSignle">
          This respondent only
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          formControlName="applyTo"
          id="applyToMultiple"
          [value]="'multiple'"
        />
        <label class="form-check-label" for="applyToMultiple">
          Multiple respondents
        </label>
      </div>
    </div>

    <div *ngIf="showRespondentsList" class="form-group mb-4 ms-4">
      <label class="form-label">Select respondents</label>
      <div
        *ngFor="let option of respondents$ | async as respondents"
        class="form-check mb-0"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [id]="option.id"
          [value]="option.id"
          (change)="toggleRespondent($event, option.id)"
        />
        <label class="form-check-label" [for]="option.id">
          {{ option.name }}
        </label>
      </div>
    </div>

    <div class="d-flex">
      <button
        class="btn cx-btn cx-btn--ghost ms-auto"
        type="button"
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        *ngIf="(submitting$ | async) !== true"
        class="btn btn-primary cx-btn ms-3"
        [disabled]="formDisabled"
        type="button"
        (click)="submitForm()"
      >
        Add this file
        <svg ibmIconCheckmark fill="currentColor" size="16"></svg>
      </button>
      <button
        *ngIf="submitting$ | async"
        class="btn btn-primary cx-btn ms-3 pe-3"
        disabled
        type="button"
      >
        Please wait...
        <div class="spinner-border spinner-border-sm ms-3" role="status"></div>
      </button>
    </div>
  </div>
</ng-template>
