<div style="margin-bottom: 3rem">
  <ng-container *ngIf="groupVisible">
    <h6 *ngIf="props.label" class="mb-3" style="max-width: 80ch">
      {{ props.label }}
    </h6>

    <div
      *ngIf="guidance"
      appGlossaryTerm
      class="text-muted my-2 summary-details"
      [innerHTML]="guidance | safeHtml"
      style="max-width: 80ch; white-space: pre-wrap"
    ></div>
  </ng-container>

  <ng-container #fieldComponent></ng-container>
</div>
