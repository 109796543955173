<ng-container [ngSwitch]="true">
  <svg
    *ngSwitchCase="formControl.value === true && useIcon"
    ibmIconCheckboxChecked
    class="text-muted"
    fill="currentColor"
    size="24"
  ></svg>
  <svg
    *ngSwitchCase="formControl.value === false && useIcon"
    ibmIconCheckbox
    class="text-muted"
    fill="currentColor"
    size="24"
  ></svg>
  <div *ngSwitchDefault>
    <ng-container
      *ngIf="
        formControl.value | answer : multivalue : format as value;
        else unknown
      "
    >
      <div [innerHTML]="value" style="white-space: break-spaces"></div>
    </ng-container>
  </div>
</ng-container>

<ng-template #unknown>
  <div class="text-muted">Not answered</div>
</ng-template>
