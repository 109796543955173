<cx1ui-headertopuser
  *ngIf="currentUserName$ | async as userName"
  [hasUserActions]="true"
  [userName]="userName"
>
  <div *ngIf="isAppAdmin$ | async" role="menuitem">
    <a class="dropdown-item" [routerLink]="['/admin']">Admin</a>
  </div>
  <div role="menuitem">
    <button class="dropdown-item" type="button" (click)="logout()">
      Logout
    </button>
  </div>
</cx1ui-headertopuser>
