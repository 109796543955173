import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { isNil, merge } from 'lodash';
import { debounceTime, delay, filter, map, Observable, tap } from 'rxjs';
import {
  DateFieldInputComponent,
  FileInputComponent,
  MultiCheckboxComponent,
  NumericFieldInputComponent,
  PlainTextInputComponent,
  QuestionnaireFieldWrapperComponent,
  QuestionnaireGroupWrapper,
  RadioComponent,
  RepeatGroupComponent,
  SelectComponent,
  SummaryFieldWrapperComponent,
} from 'src/app/features/questionnaire';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import { AnswerFormat } from 'src/app/models/enums';
import {
  Answer,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireGroup,
  QuestionnaireVariable,
} from 'src/app/models/interfaces';
import { OdataBackendService } from '..';
import { getPageConfig } from './change-summary.field-configs';
import { evalExpression } from './contract-express.functions';

export const REPEAT_CONTEXT_SUFFIX = '_context_';
export const REPEAT_FIELD_GROUP = 'repeat_field_group';
export const REPEAT_GROUP_SUFFIX = '_repeat';
export const ALL_OPTION_TEXT = 'dballoption';

@Injectable({
  providedIn: 'root',
})
export class ContractExpressService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private odataBackend: OdataBackendService
  ) {}

  appendScript(renderer: Renderer2, scripts: string): void {
    const scriptsElement = this.document.getElementById('ceq-scripts');
    if (scriptsElement !== null) {
      scriptsElement.parentNode?.removeChild(scriptsElement);
    }
    const script = renderer.createElement('script');
    script.id = 'ceq-scripts';
    script.type = 'text/javascript';
    script.text = `var cePage = ${scripts};`;
    renderer.appendChild(this.document.body, script);
  }

  eval<T>(expression: string, model?: Record<string, unknown>): T | null {
    return evalExpression<T>(expression, model);
  }

  getAnswers(
    pageId: string,
    respondentId: string | null = null
  ): Observable<Answer[]> {
    return this.odataBackend
      .postEntity<any>(
        `Pages/${pageId}/GetAnswers`,
        { respondentId },
        { expand: ['question'] }
      )
      .pipe(map(({ value }) => value));
  }

  getFormFieldConfigs(model?: QuestionnaireFormModel): FormlyFieldConfig[] {
    const [pageData] = ((window as any).cePage || []) as Questionnaire[];
    if (pageData) {
      const { activatedpage, questionnairepages } = pageData;
      const questionnairePage = questionnairepages[activatedpage];
      const { questionnairegroups, usage } = questionnairePage || {};
      return Object.values(questionnairegroups || {})
        .filter((group) => group.layout !== 'offpage')
        .map((group) => {
          const [usageKey] = Object.keys(group.usage).map(Number);
          if (usage[usageKey]) {
            const [repeatcontext] = usage[usageKey].repeatcontext;
            const context = repeatcontext
              ? (repeatcontext(model) as number[])
              : undefined;
            if (context) {
              group.repeatcontext = Math.max(...context);
            }
          }
          return this.mapQuestionnaireGroupToFieldConfig(group, model);
        });
    }

    return [];
  }

  getPageGuidance(model?: QuestionnaireFormModel): string {
    const [pageData] = (window as any).cePage || [];
    if (pageData) {
      const { activatedpage, questionnairepages } = pageData as Questionnaire;
      const { guidance, guidanceexpression } =
        questionnairepages[activatedpage];
      return guidanceexpression
        ? evalExpression<string[]>(guidanceexpression, model)?.join('') || ''
        : guidance || '';
    }

    return '';
  }

  getChangeReportFieldConfigs(
    previous: QuestionnaireFormModel,
    current: QuestionnaireFormModel
  ): FormlyFieldConfig[] {
    return getPageConfig(previous, current);
  }

  getSummaryFieldConfigs(model?: QuestionnaireFormModel): FormlyFieldConfig[] {
    const [pageData] = (window as any).cePage || [];

    if (pageData) {
      const { activatedpage, questionnairepages } = pageData as Questionnaire;
      const questionnairePage = questionnairepages[activatedpage];
      const { questionnairegroups, usage } = questionnairePage || {};
      return Object.values(questionnairegroups || {}).map((group) => {
        const [usageKey] = Object.keys(group.usage || {}).map(Number);
        if (usage[usageKey]) {
          const [repeatcontext] = usage[usageKey].repeatcontext;
          const context = repeatcontext
            ? (repeatcontext(model) as number[])
            : undefined;
          if (context) {
            group.repeatcontext = Math.max(...context);
          }
        }
        return this.mapQuestionnaireGroupToReadonlyFieldConfig(group, model);
      });
    }

    return [];
  }

  mapFormValuesToAnswers(
    values: QuestionnaireFormModel
  ): QuestionnaireAnswer[] {
    return Object.entries(values).reduce<QuestionnaireAnswer[]>(
      (answers, [question, answer]) => {
        // if answer is an array, then it's coming from a repeater field array
        if (Array.isArray(answer)) {
          answer.forEach((group, index) => {
            if (group === null) {
              answers.push({
                question,
                answer: '',
                known: false,
                repeatContext: index + 1,
              });
            } else if (typeof group === 'string') {
              answers.push({
                question,
                answer: group,
                repeatContext: index + 1,
              });
            } else {
              Object.entries(group || {}).forEach(([subQuestion, subAnswer]) =>
                answers.push({
                  question: subQuestion,
                  answer: subAnswer?.toString(),
                  repeatContext: index + 1,
                  repeatGroupName: question,
                })
              );
            }
          });
          if (question.includes(REPEAT_GROUP_SUFFIX)) {
            // Update corresponding repeater group counter that CE requires.
            answers.push({
              question: question.replace(REPEAT_GROUP_SUFFIX, ''),
              answer: answer.length.toString(),
            });
          } else if (answer.length === 0) {
            // If it's not a repeat group and an empty array,
            // set it's value to empty string.
            answers.push({ question, answer: '', known: true });
          }
          // if answer is an object, then it's coming from a repeater field group
        } else if (typeof answer === 'object' && answer !== null) {
          Object.entries(answer).forEach(([subQuestion, subAnswer]) => {
            const questionContext = subQuestion.split(REPEAT_CONTEXT_SUFFIX);
            answers.push({
              question: questionContext[0],
              answer: (subAnswer as any)?.toString(),
              repeatContext: Number(questionContext[1]),
              repeatGroupName: question,
            });
          });
        } else if (question.includes(REPEAT_CONTEXT_SUFFIX)) {
          const questionContext = question.split(REPEAT_CONTEXT_SUFFIX);
          question = questionContext[0];
          answers.push({
            question: questionContext[0],
            answer: answer?.toString(),
            repeatContext: Number(questionContext[1]),
          });
        } else {
          const known = answer === null ? false : undefined;
          answers.push({ question, answer: answer?.toString(), known });
        }

        return answers;
      },
      []
    );
  }

  private getAnswerFormat({
    datatype,
    defaultformat,
    inputmethod,
  }: QuestionnaireVariable): AnswerFormat {
    if (defaultformat === 'currencydigits') {
      return AnswerFormat.CURRENCY;
    } else if (datatype === 'Date' && inputmethod !== 'Calendar') {
      return AnswerFormat.YEAR_ONLY;
    }

    return AnswerFormat.DEFAULT;
  }

  private getCheckboxFieldConfig(
    {
      layout,
      name,
      prefillvalueexpression,
      prescribedoptions,
      prompt,
      promptexpression,
    }: QuestionnaireVariable,
    formModel?: QuestionnaireFormModel
  ): Partial<FieldTypeConfig> {
    const defaultValue = prefillvalueexpression
      ? !!evalExpression(prefillvalueexpression, formModel)
      : false;
    const formCheck = layout === 'Horizontal' ? 'inline' : 'default';
    const [label] = evalExpression<string[]>(prescribedoptions || '') || [];
    if (promptexpression) {
      prompt = evalExpression<string[]>(promptexpression)?.join('') || '';
    }
    const unknown = formModel && !formModel?.hasOwnProperty(name.toLowerCase());
    return {
      type: 'checkbox',
      defaultValue,
      modelOptions: { updateOn: 'change' },
      props: {
        formCheck,
        label,
        prompt,
        required: false,
        unknown,
      },
      validators: {
        verify: {
          expression: (control: AbstractControl) =>
            name.toLowerCase().includes('_verify') ? control.value : true,
        },
      },
    };
  }

  private getDateFieldConfig({
    inputmethod,
  }: QuestionnaireVariable): Partial<FieldTypeConfig> {
    return {
      type: DateFieldInputComponent,
      props: { inputmethod },
    };
  }

  private getDefaultFieldConfig(
    key: number | string,
    variable: QuestionnaireVariable,
    required = true,
    repeaterField = false,
    formModel?: QuestionnaireFormModel
  ): FieldTypeConfig {
    const guidance = variable.guidanceexpression
      ? evalExpression<string[]>(variable.guidanceexpression, formModel)?.join(
          ''
        )
      : variable.guidance;
    const label = variable.promptexpression
      ? evalExpression<string[]>(variable.promptexpression, formModel)?.join('')
      : variable.prompt;
    const preamble = variable.preambleexpression
      ? evalExpression<string[]>(variable.preambleexpression, formModel)?.join(
          ''
        )
      : variable.preamble;
    // Pass promptexpression to FieldTypeConfig
    // so that it can be evaluated in real-time,
    // but only if it's not a _context_ variable.
    const promptexpression = (key as string).includes(REPEAT_CONTEXT_SUFFIX)
      ? null
      : variable.promptexpression;
    return {
      key,
      type: 'input',
      className: 'col',
      modelOptions: {
        updateOn: 'blur',
      },
      props: {
        ceConfig: variable,
        guidance,
        label,
        layout: variable.layout,
        preamble,
        promptexpression,
        required,
      },
      expressions: {
        hide: ({ model }: FormlyFieldConfig) =>
          repeaterField
            ? false
            : Object.values(variable.usage).some(
                (usage) =>
                  !evalExpression<boolean | null>(usage, {
                    ...formModel,
                    ...model,
                  })
              ),
        'props.disabled': ({ form, model }: FormlyFieldConfig) =>
          repeaterField
            ? Object.values(variable.usage).some(
                (usage) =>
                  !evalExpression<boolean | null>(usage, {
                    ...formModel,
                    ...form?.value,
                    ...model,
                  })
              )
            : false,
      },
      hooks: {
        onInit: (fieldConfig: FormlyFieldConfig) => {
          // if variable is empty and has a prefill value expression, we need to apply it
          // either as a default value, or triggered by another value change.
          this.initPrefillLogic(fieldConfig, variable.prefillvalueexpression);
          return this.listenToFieldPropertyChanges(fieldConfig);
        },
      },
      wrappers: [QuestionnaireFieldWrapperComponent],
    } as any;
  }

  private getFileUploadFieldConfig(): Partial<FieldTypeConfig> {
    return { type: FileInputComponent };
  }

  private getMultiCheckboxFieldConfig(
    {
      alloptiontext,
      name,
      otheroption,
      otheroptiontext,
      prescribedoptions,
      unknownoptiontext,
    }: QuestionnaireVariable,
    formModel?: QuestionnaireFormModel
  ): Partial<FieldTypeConfig> {
    const labels = evalExpression<string[]>(prescribedoptions || '') || [];
    const required = name.toLowerCase().includes('optionverify');
    const options = labels.map((label) => ({ label, value: label }));
    if (alloptiontext) {
      options.unshift({
        label: alloptiontext,
        value: ALL_OPTION_TEXT,
      });
    }
    if (unknownoptiontext) {
      options.unshift({ label: unknownoptiontext, value: null } as any);
    }
    const otherOptionText = otheroption
      ? otheroptiontext || 'Other'
      : undefined;
    return {
      type: MultiCheckboxComponent,
      modelOptions: { updateOn: 'change' },
      props: {
        options,
        otherOptionText,
        required,
        type: 'array',
        unknownoptiontext,
      },
    };
  }

  private getNumericFieldConfig({
    defaultformat,
  }: QuestionnaireVariable): Partial<FieldTypeConfig> {
    return { type: NumericFieldInputComponent, props: { defaultformat } };
  }

  private getRadioFieldConfig({
    datatype,
    layout,
    otheroption,
    otheroptiontext,
    prescribedoptions,
    unknownoptiontext,
  }: QuestionnaireVariable): Partial<FieldTypeConfig> {
    const formCheck = layout === 'Horizontal' ? 'inline' : 'default';
    const labels = evalExpression<string[]>(prescribedoptions || '') || [];
    const options = labels.map((label, index) => {
      const value = datatype === 'Boolean' ? !index : label;
      return { label, value };
    });
    if (unknownoptiontext) {
      options.unshift({ label: unknownoptiontext, value: null } as any);
    }
    const otherOptionText = otheroption
      ? otheroptiontext || 'Other'
      : undefined;
    return {
      type: RadioComponent,
      modelOptions: { updateOn: 'change' },
      props: { formCheck, options, otherOptionText },
    };
  }

  private getSelectFieldConfig(
    {
      layout,
      otheroption,
      otheroptiontext,
      prescribedoptions,
      unknownoptiontext: placeholder,
    }: QuestionnaireVariable,
    model?: QuestionnaireFormModel
  ): Partial<FieldTypeConfig> {
    const formCheck = layout === 'Horizontal' ? 'inline' : 'default';
    const labels =
      evalExpression<string[]>(prescribedoptions || '', model) || [];
    const options = labels.map((label) => ({ label, value: label }));
    const otherOptionText = otheroption ? otheroptiontext : undefined;
    return {
      type: SelectComponent,
      modelOptions: { updateOn: 'change' },
      props: { formCheck, options, otherOptionText, placeholder },
    };
  }

  private getTextAreaFieldConfig({
    depth,
  }: QuestionnaireVariable): Partial<FieldTypeConfig> {
    return { type: 'textarea', props: { rows: depth } };
  }

  private initPrefillLogic(config: FormlyFieldConfig, expression?: string) {
    const { formControl, model } = config;
    const hasValue =
      formControl && (formControl.value || formControl.value === false);
    if (formControl && !hasValue && expression) {
      let defaultValue = this.transformPrefillToValue(
        evalExpression(expression, model)
      );
      if (!isNil(defaultValue) && defaultValue !== '') {
        formControl.markAsDirty();
        formControl.setValue(defaultValue);
      }

      formControl.root.valueChanges
        .pipe(
          debounceTime(250),
          delay(100),
          filter(() => formControl.untouched)
        )
        .subscribe(() => {
          const value = this.transformPrefillToValue(
            evalExpression(expression, model)
          );
          if (
            formControl.untouched &&
            !isNil(value) &&
            value !== defaultValue
          ) {
            defaultValue = value;
            formControl.markAsDirty();
            formControl.setValue(value);
          }
        });
    }
  }

  // Clear field value when it becomes disabled/hidden.
  private listenToFieldPropertyChanges(field: FormlyFieldConfig) {
    return field.options?.fieldChanges?.pipe(
      filter((event: FormlyValueChangeEvent) => {
        return (
          event.type === 'expressionChanges' &&
          event.field === field &&
          event['property'] === 'hide' &&
          event.value === true
        );
      }),
      tap(() => {
        const { formControl } = field;
        formControl?.markAsDirty();
        formControl?.setValue('');
      })
    );
  }

  private listenToFieldArrayPropertyChanges(field: FormlyFieldConfig) {
    return field.options?.fieldChanges?.pipe(
      filter((event: FormlyValueChangeEvent) => {
        return (
          event.type === 'expressionChanges' &&
          event.field === field &&
          event['property'] === 'hide' &&
          event.value === true
        );
      }),
      tap(() => {
        const formControl = field.formControl as FormArray;
        while (formControl.length > 1) {
          formControl.removeAt(formControl.length - 1, { emitEvent: false });
        }
        if (formControl.length) {
          Object.keys(formControl.at(0).getRawValue()).forEach((key) => {
            formControl.at(0).get(key)?.setValue('', { emitEvent: false });
          });
        }
        formControl.markAsDirty();
        formControl.updateValueAndValidity();
      })
    );
  }

  private mapVariableToFieldTypeConfig(
    key: number | string,
    variable: QuestionnaireVariable,
    required: boolean,
    repeater = false,
    model?: QuestionnaireFormModel
  ): FieldTypeConfig {
    const { datatype, depth, inputmethod } = variable;

    const fieldConfig = this.getDefaultFieldConfig(
      key,
      variable,
      required,
      repeater,
      model
    );
    if (inputmethod === 'ButtonList' && datatype === 'StringList') {
      return merge(
        fieldConfig,
        this.getMultiCheckboxFieldConfig(variable, model)
      );
    } else if (inputmethod === 'ButtonList') {
      return merge(fieldConfig, this.getRadioFieldConfig(variable));
    } else if (datatype === 'Date') {
      return merge(fieldConfig, this.getDateFieldConfig(variable));
    } else if (inputmethod === 'Checkbox') {
      return merge(fieldConfig, this.getCheckboxFieldConfig(variable, model));
    } else if (datatype === 'Float' || datatype === 'Integer') {
      return merge(fieldConfig, this.getNumericFieldConfig(variable));
    } else if (inputmethod === 'EditBox' && depth) {
      return merge(fieldConfig, this.getTextAreaFieldConfig(variable));
    } else if (inputmethod === 'FileUpload') {
      return merge(fieldConfig, this.getFileUploadFieldConfig());
    } else if (inputmethod === 'SelectList') {
      return merge(fieldConfig, this.getSelectFieldConfig(variable, model));
    }

    return fieldConfig;
  }

  private mapVariableToPlainTextConfig(
    key: string,
    variable: QuestionnaireVariable,
    formModel?: QuestionnaireFormModel
  ): FieldTypeConfig {
    const [option] =
      evalExpression<string[]>(variable.prescribedoptions || '') || [];
    let label = variable.promptexpression
      ? evalExpression<string[]>(variable.promptexpression, formModel)?.join('')
      : variable.prompt;
    const multivalue = variable.datatype === 'StringList';
    let prompt = '';

    if (variable.inputmethod === 'Checkbox') {
      prompt = label || '';
      label = option;
    }
    const useIconDisplay = variable.inputmethod === 'Checkbox';
    const format = this.getAnswerFormat(variable);
    const fieldConfig = {
      key,
      type: PlainTextInputComponent,
      props: { label, format, multivalue, prompt, useIconDisplay },
      expressions: {
        hide: ({ model }: FormlyFieldConfig) =>
          Object.values(variable.usage).some(
            (usage) =>
              !evalExpression<boolean | null>(usage, {
                ...formModel,
                ...model,
              })
          ),
      },
      wrappers: [SummaryFieldWrapperComponent],
    } as any as FieldTypeConfig;

    return fieldConfig;
  }

  private mapQuestionnaireGroupToFieldConfig(
    group: QuestionnaireGroup,
    formModel?: QuestionnaireFormModel
  ): FormlyFieldConfig {
    const {
      guidanceexpression,
      questionnairevariableorder,
      questionnairevariables,
      title,
      titleexpression,
    } = group;
    const guidance = guidanceexpression
      ? evalExpression<string[]>(guidanceexpression, formModel)?.join('')
      : group.guidance;
    const label = titleexpression
      ? evalExpression<string[]>(titleexpression, formModel)?.join('')
      : title;
    const isListVerifyGroup = label?.includes('_ListVerify');
    const variables = questionnairevariableorder || [];
    const fieldGroup = variables.reduce<FormlyFieldConfig[]>(
      (groups, key, i, fields) => {
        const variable = questionnairevariables[key];
        if (variable.inputmethod === 'GroupRepeat') {
          const children = fields
            .splice(i + 1)
            .reduce<Record<string, QuestionnaireVariable>>(
              (all, field) => ({
                ...all,
                [field]: questionnairevariables[field],
              }),
              {}
            );
          groups.push(
            this.mapToRepeaterFieldArray(group, variable, children, formModel)
          );
        } else if (group.repeatcontext && formModel) {
          // if there is a repeatcontext, prepare a new type of a repeater group.
          fields.splice(i + 1);
          groups.push(this.mapToRepeaterFieldGroup(group, formModel));
        } else {
          const required =
            isListVerifyGroup &&
            !key.includes('_verify') &&
            !key.includes('_desc')
              ? false
              : true;
          groups.push(
            this.mapVariableToFieldTypeConfig(
              key,
              variable,
              required,
              false,
              formModel
            )
          );
        }
        return groups;
      },
      []
    );

    return {
      fieldGroup,
      props: { guidance, label: label?.replace('_ListVerify', '') },
      validators: {
        listVerify: {
          expression: (formGroup: FormGroup) => {
            const listVariables = questionnairevariableorder.filter(
              (name) => !name.includes('_verify') && !name.includes('_desc')
            );
            const listVerify = listVariables.length
              ? listVariables.some(
                  (name) =>
                    formGroup.get(name)?.value ||
                    formGroup.get(name)?.value === 0 ||
                    formGroup.get(name)?.value === false
                )
              : true;
            return isListVerifyGroup ? listVerify : true;
          },
        },
      },
      wrappers: [QuestionnaireGroupWrapper],
    };
  }

  private mapQuestionnaireGroupToReadonlyFieldConfig(
    group: QuestionnaireGroup,
    model?: QuestionnaireFormModel
  ): FormlyFieldConfig {
    const {
      questionnairevariableorder,
      questionnairevariables,
      title,
      titleexpression,
    } = group;
    const guidance = group.guidanceexpression
      ? evalExpression<string[]>(group.guidanceexpression, model)?.join('')
      : group.guidance;
    const label = titleexpression
      ? evalExpression<string[]>(titleexpression)?.join('')
      : title;
    const variables = questionnairevariableorder || [];
    const fieldGroup = variables.reduce<FormlyFieldConfig[]>(
      (groups, key, i, fields) => {
        const variable = questionnairevariables[key];
        if (variable.inputmethod === 'GroupRepeat') {
          const children = fields
            .splice(i + 1)
            .reduce<Record<string, QuestionnaireVariable>>(
              (all, field) => ({
                ...all,
                [field]: questionnairevariables[field],
              }),
              {}
            );
          groups.push(
            this.mapToRepeaterFieldArray(
              { ...group, layout: 'readonly' },
              variable,
              children,
              model
            )
          );
        } else if (group.repeatcontext && model) {
          // if there is a repeatcontext, prepare a new type of a repeater group.
          fields.splice(i + 1);
          groups.push(this.mapToReadonlyRepeaterFieldGroup(group, model));
        } else {
          groups.push(this.mapVariableToPlainTextConfig(key, variable, model));
        }
        return groups;
      },
      []
    );

    return {
      fieldGroup,
      props: { guidance, label: label?.replace('_ListVerify', '') },
      wrappers: [QuestionnaireGroupWrapper],
    };
  }

  private mapToRepeaterFieldArray(
    { includebuttons, layout, repeattitleexpression }: QuestionnaireGroup,
    parent: QuestionnaireVariable,
    children: Record<string, QuestionnaireVariable>,
    formModel?: QuestionnaireFormModel
  ): FormlyFieldConfig {
    const parentKey = parent.name.toLowerCase();
    const fieldArrayKey = parentKey.concat(REPEAT_GROUP_SUFFIX);
    const repeater = layout !== 'griddown' ? true : false;
    const fieldGroup = Object.entries(children).map(([key, variable]) =>
      layout !== 'readonly'
        ? this.mapVariableToFieldTypeConfig(
            key,
            variable,
            true,
            repeater,
            formModel
          )
        : this.mapVariableToPlainTextConfig(key, variable)
    );
    const childrenVariables = Object.values(children);
    const [firstVariable] = childrenVariables;
    const label = firstVariable?.promptexpression
      ? evalExpression<string[]>(
          firstVariable.promptexpression,
          formModel
        )?.join('')
      : firstVariable?.prompt;
    const [repeatTitles] = repeattitleexpression
      ? evalExpression<string[][]>(repeattitleexpression, formModel) || []
      : [];
    const titles = childrenVariables.map(({ prompt, promptexpression }) =>
      promptexpression
        ? evalExpression<string[]>(promptexpression, formModel)?.join('')
        : prompt
    );

    // If there are more answers for griddown form than there are repeat titles,
    // reduce that list to match the number of titles.
    if (
      layout === 'griddown' &&
      !includebuttons &&
      repeatTitles.length &&
      formModel?.hasOwnProperty(fieldArrayKey)
    ) {
      const knownAnswers = formModel[fieldArrayKey] as Record<string, string>[];
      if (knownAnswers?.length > repeatTitles.length) {
        formModel[fieldArrayKey] = knownAnswers.slice(0, repeatTitles.length);
      }
    }

    return {
      key: fieldArrayKey,
      type: RepeatGroupComponent,
      fieldArray: {
        fieldGroup,
        fieldGroupClassName: 'row',
      },
      modelOptions: {
        updateOn: 'change',
      },
      expressions: {
        // For field arrays the model is accessible through a parent property.
        // See https://github.com/ngx-formly/ngx-formly/issues/704#issuecomment-364661614
        hide: (fieldConfig: FormlyFieldConfig) =>
          Object.values(parent.usage).some(
            (usage) =>
              !evalExpression<boolean | null>(usage, fieldConfig.parent?.model)
          ),
      },
      hooks: {
        onInit: (fieldConfig: FormlyFieldConfig) => {
          // If there are no known answers to this repeat group,
          // save its initial value (empty array).
          if (!formModel?.hasOwnProperty(parentKey)) {
            fieldConfig.formControl?.markAsDirty();
            fieldConfig.formControl?.updateValueAndValidity();
          }
          return this.listenToFieldArrayPropertyChanges(fieldConfig);
        },
      },
      props: {
        includebuttons,
        label,
        layout,
        repeatTitles,
        titles,
      },
      validators: {
        repeatVerify: {
          expression: (formArray: FormArray) => formArray?.value?.length,
        },
      },
    };
  }

  private mapToRepeaterFieldGroup(
    { questionnairevariables, repeatcontext }: QuestionnaireGroup,
    formModel: QuestionnaireFormModel
  ): FormlyFieldConfig {
    if (!repeatcontext) {
      return {};
    }

    const fieldGroup: FormlyFieldConfig[] = [];
    for (let i = 0; i < repeatcontext; i++) {
      const model = Object.entries(formModel).reduce(
        (flatModel, [key, value]) => ({
          ...flatModel,
          [key]: Array.isArray(value) ? value[i] : value,
        }),
        {}
      );
      Object.entries(questionnairevariables).forEach(([name, variable]) =>
        fieldGroup.push(
          this.mapVariableToFieldTypeConfig(
            `${name}${REPEAT_CONTEXT_SUFFIX}${i + 1}`,
            variable,
            true,
            false,
            model
          )
        )
      );
    }
    return { key: REPEAT_FIELD_GROUP, fieldGroup };
  }

  private mapToReadonlyRepeaterFieldGroup(
    { questionnairevariables, repeatcontext }: QuestionnaireGroup,
    formModel: QuestionnaireFormModel
  ): FormlyFieldConfig {
    if (!repeatcontext) {
      return {};
    }

    const fieldGroup: FormlyFieldConfig[] = [];
    for (let i = 0; i < repeatcontext; i++) {
      const model = Object.entries(formModel).reduce(
        (flatModel, [key, value]) => ({
          ...flatModel,
          [key]: Array.isArray(value) ? value[i] : value,
        }),
        {}
      );
      Object.entries(questionnairevariables).forEach(([name, variable]) =>
        fieldGroup.push(
          this.mapVariableToPlainTextConfig(
            `${name}${REPEAT_CONTEXT_SUFFIX}${i + 1}`,
            variable,
            model
          )
        )
      );
    }

    return { key: REPEAT_FIELD_GROUP, fieldGroup };
  }

  private transformPrefillToValue(prefill?: any): string {
    if (
      prefill?.hasOwnProperty('year') &&
      prefill?.hasOwnProperty('month') &&
      prefill?.hasOwnProperty('day')
    ) {
      const { year, month, day } = prefill;
      const date = [
        year,
        (month as number).toString().padStart(2, '0'),
        (day as number).toString().padStart(2, '0'),
      ];
      return date.join('-');
    } else if (Array.isArray(prefill)) {
      return prefill.join('');
    }
    return prefill;
  }
}
