<div class="d-flex flex-column vh-100 vw-100">
  <main class="container-fluid flex-fill">
    <div class="row align-items-center h-100">
      <div class="col">
        <div
          class="d-flex flex-column gap-5 m-0 m-lg-5"
          style="max-width: 480px"
        >
          <h2>Page not found</h2>

          <div class="bg-danger" style="height: 5px; width: 80px"></div>

          <div>
            We were unable to locate the requested page. Use the browser's back
            button to return to the previous page, or go to your landing page.
          </div>

          <div>
            <a class="btn btn-primary cx-btn" [routerLink]="['/']">
              Go to home
              <svg ibmIconArrowRight size="16" fill="currentColor"></svg>
            </a>
          </div>
        </div>
      </div>
      <div class="col d-none d-md-flex bg-image h-100"></div>
    </div>
  </main>
  <div class="bg-light p-3">
    <div class="m-lg-3 p-lg-3">
      If you need additional assistance, please contact our support team at
      <a href="mailto:connectsupport@cooley.com">connectsupport@cooley.com</a>
    </div>
  </div>
  <app-footer></app-footer>
</div>
