import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OffcanvasService {
  private _respondentNavOpen = new BehaviorSubject<boolean>(false);
  get respondentNavOpen$() {
    return this._respondentNavOpen.asObservable();
  }
  get respondentNavOpen() {
    return this._respondentNavOpen.getValue();
  }
  set respondentNavOpen(value: boolean) {
    this._respondentNavOpen.next(value);
  }
}
