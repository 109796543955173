import { Injectable } from '@angular/core';
import { Roles } from '../../../models/enums';
import { Person, Project } from '../../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor() {}

  isPersonAnAdministrator(person?: Person, projects?: Project[]): boolean {
    if (!projects || !projects.length) {
      return false;
    }

    return projects
      .map((project) =>
        project.projectPeople.find(
          (projectPerson) => projectPerson.personId === person?.id
        )
      )
      .some(
        (projectPerson) => projectPerson?.role?.roleName === Roles.ClientAdmin
      );
  }

  isPersonACollaborator(person?: Person, projects?: Project[]): boolean {
    if (!projects || !projects.length) {
      return false;
    }

    return projects
      .map((project) =>
        project.projectPeople.find(
          (projectPerson) => projectPerson.personId === person?.id
        )
      )
      .some(
        (projectPerson) =>
          projectPerson?.role?.roleName === Roles.ClientCollaborator
      );
  }
}
