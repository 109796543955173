/**
[COMMONUI STARTER TEMPLATE: TEMPLATE SERVICE / DEVICE DETECTION: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
This required service comes bundled with the CommonUI template. Edits are not permitted.
Returns boolean for device queries and detailed object for device info.
Default error condition on boolean operations is to return boolean:false and console the error.

[COMPLIANCE]
Expect this file to be routinely scanned for compliance.

Do not edit or remove this content.

[EXCEPTIONS]
Exceptions to this guidance are not permitted.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
*/
/**
 * Imports
 */
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * Specifies that the service should be provided in the root injector.
 */
@Injectable({
  providedIn: 'root'
})
/** Class */
export class templateDeviceDetectionService {

  /** Constructor */
  constructor(
    /**
     * Required
     */
    private deviceService: DeviceDetectorService,
  ) {
    //
  }

  /**
   * isMobile
   * @returns boolean.
   */
  public isMobile() {
    /**
     * Wrap in a trycatch
     */
    try {
      /**
       * Get from API
       */
      const isMobile = this.deviceService.isMobile();
      /**
       * Return the response.
       */
      return isMobile;
    } catch (error) {
      /**
       * On error condition console out and return.
       */
      console.error(error);
      return false;
    }
  }

  /**
   * isTablet
   * @returns boolean.
   */
  public isTablet() {
    /**
     * Wrap in a trycatch
     */
    try {
      /**
       * Get from API
       */
      const isTablet = this.deviceService.isTablet();
      /**
       * Return the response.
       */
      return isTablet;
    } catch (error) {
      /**
       * On error condition console out and return.
       */
      console.error(error);
      return false;
    }
  }

  /**
   * isDesktopDevice
   * @returns boolean.
   */
  public isDesktopDevice() {
    /**
     * Wrap in a trycatch
     */
    try {
      /**
       * Get from API
       */
      const isDesktopDevice = this.deviceService.isDesktop();
      /**
       * Return the response.
       */
      return isDesktopDevice;
    } catch (error) {
      /**
       * On error condition console out and return.
       */
      console.error(error);
      return false;
    }
  }

  /**
   * deviceInfo
   * @returns object with device info.
   */
  public getdeviceInfo() {
    /**
     * Wrap in a trycatch
     */
    try {
      /**
       * Get from API
       */
      const deviceInfo = this.deviceService.getDeviceInfo();
      /**
       * Return the response.
       */
      return deviceInfo;
    } catch (error) {
      /**
       * On error condition console out and return.
       */
      console.error(error);
      return error;
    }
  }

}
