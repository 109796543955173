import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalInterceptor } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { getAccessToken } from 'src/app/core/auth/auth.helpers';

@Injectable()
export class AuthInterceptor extends MsalInterceptor {
  override intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const accessToken = getAccessToken();

    if (accessToken) {
      const headers = request.headers
        .set('Authorization', `Bearer ${accessToken}`)
        .set('Flow', 'JWT');
      const requestClone = request.clone({ headers });
      return next.handle(requestClone);
    }

    return super.intercept(request, next);
  }
}
