<!--
[COMMONUI STARTER TEMPLATE: TEMPLATE LEFT NAV: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
The left menu, if utilized in your solution, has relatively simple requirements - it requires an object for it to render.
No other content shall be displayed within this component. If you require additional elements within the left navigation zone
the only available path leverages content projection and is documented

[COMPLIANCE]
Expect this file to be routinely scanned for compliance.

Do not edit or remove this content.

[EXCEPTIONS]
Exceptions to this guidance are not permitted. Simplified - you cannot edit this file beyond what is expressly stated.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
-->
<div *ngIf="display">
  <!-- BEGIN: Desktop -->
  <div *ngIf="!isMobile" class="cxtemplateleftnavcontainer">
    <cx1ui-left-menu [navData]="leftNav" (menuClickEvent)="processMenuClickEvent($event)"></cx1ui-left-menu>
  </div>
  <!-- BEGIN: Mobile -->
  <div *ngIf="isMobile" class="">
    <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeftNavMobile"
      aria-controls="offcanvasLeftNavMobile">
      <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" width="32px" height="32px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
        <defs>
          <style>
            .cls-1 {
              fill: none;
            }
          </style>
        </defs>
        <title>menu</title>
        <rect x="4" y="6" width="24" height="2" />
        <rect x="4" y="24" width="24" height="2" />
        <rect x="4" y="12" width="24" height="2" />
        <rect x="4" y="18" width="24" height="2" />
        <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32" />
      </svg>
    </button>
  </div>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasLeftNavMobile" aria-labelledby="Left Navigation for Mobile">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasLeftNavMobileLabel">{{ offCanvasTitle }}</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <cx1ui-left-menu [navData]="leftNav" (menuClickEvent)="processMenuClickEvent($event)"></cx1ui-left-menu>
    </div>
  </div>
</div>
