<div
  *ngFor="
    let option of props.options | formlySelectOptions : field | async;
    let i = index
  "
  class="form-check"
  [ngClass]="{
    'form-check-inline':
      props.formCheck === 'inline' || props.formCheck === 'inline-switch',
    'form-switch':
      props.formCheck === 'switch' || props.formCheck === 'inline-switch'
  }"
>
  <input
    type="checkbox"
    [id]="id + '_' + i"
    class="form-check-input"
    [value]="option.value"
    [checked]="isChecked(option)"
    [formlyAttributes]="field"
    [disabled]="isDisabled(option)"
    (change)="onChange(option.value, $any($event.target).checked)"
  />
  <label class="form-check-label" [for]="id + '_' + i">
    {{ option.label }}
  </label>
</div>

<ng-container *ngIf="otherOptionLabel" [formGroup]="otherOptionForm">
  <div
    class="form-check"
    [ngClass]="{
      'form-check-inline':
        props.formCheck === 'inline' || props.formCheck === 'inline-switch',
      'form-switch':
        props.formCheck === 'switch' || props.formCheck === 'inline-switch'
    }"
  >
    <input
      type="checkbox"
      [id]="id + '_other'"
      class="form-check-input"
      formControlName="toggle"
    />
    <label class="form-check-label" [for]="id + '_other'">
      {{ otherOptionLabel }}
    </label>
  </div>

  <div *ngIf="otherOptionToggleControl.value">
    <input
      autocomplete="off"
      class="form-control"
      formControlName="text"
      type="text"
    />
  </div>
</ng-container>
