<!--
[COMMONUI STARTER TEMPLATE: TEMPLATE BODY / ROUTER POINT: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
There is only one requirement for this file. It must be the location of the router outlet.
You may add components or anything else but the router must stay resident here.

[COMPLIANCE]
Expect this file to be routinely scanned for compliance.

Do not edit or remove this content.

[EXCEPTIONS]
Exceptions to this guidance are not permitted.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
-->
<ng-container *ngIf="displaySearch">
  <app-template-body-search></app-template-body-search>
</ng-container>
<ng-container *ngIf="!displaySearch">
  <router-outlet></router-outlet>
</ng-container>
