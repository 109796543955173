/** Imports */
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { templateLeftMenuService } from '../../_framing/_services/template-left-menu.service';
/** Components */
@Component({
  selector: 'app-base-leftnav-menu',
  templateUrl: './base-leftnav-menu.component.html',
  styleUrls: ['./base-leftnav-menu.component.css']
})
/** Class */
export class BaseLeftnavMenuComponent implements OnInit {

  /** Route */
  private currentRoute = '';
  /** Menus */
  /** Discover */
  private leftMenuDataDiscover: any;
  private leftMenuDataDesign: any;
  private leftMenuDataDevelop: any;
  private leftMenuDataSupport: any;

  /** Constructor */
  constructor(
    private templateLeftMenuService: templateLeftMenuService,
    private router: Router
    ) {
      /** Subscribe to route changes */
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.currentRoute = event.url.replace('/','').toLowerCase();
          /** Set left menu */
          this.setLeftMenu();
        }
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
        }
        if (event instanceof NavigationError) {
          console.log(event.error);
        }
      });
    }

  /** Lifecycle */
  ngOnInit(): void {
    /** Example left menu
     * Replace with static or service/other retrieved data. The only constraints are that the JSON object is appropriately structured.
     */
    /** Left menu: Discover */
    this.leftMenuDataDiscover = {
      "data": [
        {
          "label": "Discover",
          "showLabel": false,
          "data": [
            {
              "text": "Overview",
              "route": "style",
            },
            {
              "text": "Resources",
              "route": "icons",
            },
            {
              "text": "What's New",
              "route": "framing",
            }
          ]
        }
      ]

    }
    /** Left menu: Design */
    this.leftMenuDataDesign = {
      "data": [
        {
          "label": "CommonUI Home",
          "showLabel": false,
          "data": [
            {
              "text": "Style",
              "route": "style",
            },
            {
              "text": "Icons",
              "route": "icons",
            },
            {
              "text": "Framing",
              "route": "framing",
            },
            {
              "text": "Components",
              "route": "democomponents",
            },
            {
              "text": "Services",
              "route": "services",
            },
          ]
        }
      ]

    }
    /** Left menu: Develop */
    this.leftMenuDataDevelop = {
      "data": [
        {
          "label": "About",
          "showLabel": true,
          "data": [
            {
              "text": " - CommonUI",
              "href": "http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#",
            },
            {
              "text": " - Angular",
              "href": "https://angular.io/docs",
            },
            {
              "text": " - Bootstrap",
              "route": "https://getbootstrap.com/docs/5.1/getting-started/introduction/",
            }
          ]
        }
      ]

    }
    /** Left menu: Support */
    this.leftMenuDataSupport = {
      "data": [
        {
          "label": "About",
          "showLabel": true,
          "data": [
            {
              "text": " - CommonUI",
              "href": "http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#",
            },
            {
              "text": " - Angular",
              "href": "https://angular.io/docs",
            },
            {
              "text": " - Bootstrap",
              "route": "https://getbootstrap.com/docs/5.1/getting-started/introduction/",
            }
          ]
        }
      ]

    }
  }

  /** Set left menu */
  private setLeftMenu() {
    try {
      if (this.currentRoute === 'discover') {
        this.templateLeftMenuService.setLeftMenu(this.leftMenuDataDiscover);
      } else if (this.currentRoute === 'design') {
        this.templateLeftMenuService.setLeftMenu(this.leftMenuDataDesign);
      } else if (this.currentRoute === 'develop') {
        this.templateLeftMenuService.setLeftMenu(this.leftMenuDataDevelop);
      } else if (this.currentRoute === 'support') {
        this.templateLeftMenuService.setLeftMenu(this.leftMenuDataSupport);
      } else {
        this.templateLeftMenuService.setLeftMenu({});
      }
    } catch (error) {
      console.log(error);
    }
  }
}
