import { NgModule } from '@angular/core';
import {
  AddModule,
  ArrowLeftModule,
  ArrowRightModule,
  CatalogModule,
  CheckboxCheckedModule,
  CheckboxModule,
  CheckmarkFilledModule,
  CheckmarkFilledWarningModule,
  CheckmarkModule,
  CheckmarkOutlineModule,
  CheckmarkOutlineWarningModule,
  ChevronDownModule,
  ChevronLeftModule,
  ChevronRightModule,
  CircleDashModule,
  CircleFilledModule,
  CloseModule,
  CopyModule,
  DocumentAddModule,
  DocumentTasksModule,
  DocumentViewModule,
  DownloadModule,
  EditModule,
  FolderAddModule,
  IncompleteModule,
  IncompleteWarningModule,
  InformationFilledModule,
  InProgressModule,
  InsertModule,
  LaunchModule,
  ListModule,
  LockedModule,
  MisuseModule,
  NotebookModule,
  OverflowMenuVerticalModule,
  PendingModule,
  RecentlyViewedModule,
  ResetModule,
  SaveModule,
  SendAltModule,
  StopFilledAltModule,
  TaskViewModule,
  TrashCanModule,
  UserFollowModule,
  UserMultipleModule,
  UserProfileModule,
  WarningAltFilledModule,
  WarningFilledModule,
  WarningSquareFilledModule,
} from '@carbon/icons-angular';

@NgModule({
  exports: [
    AddModule,
    ArrowLeftModule,
    ArrowRightModule,
    CatalogModule,
    CheckboxModule,
    CheckboxCheckedModule,
    CheckmarkFilledModule,
    CheckmarkFilledWarningModule,
    CheckmarkModule,
    CheckmarkOutlineModule,
    CheckmarkOutlineWarningModule,
    ChevronDownModule,
    ChevronLeftModule,
    ChevronRightModule,
    CircleDashModule,
    CircleFilledModule,
    CloseModule,
    CopyModule,
    DocumentAddModule,
    DocumentTasksModule,
    DocumentViewModule,
    DownloadModule,
    EditModule,
    FolderAddModule,
    IncompleteModule,
    IncompleteWarningModule,
    InformationFilledModule,
    InProgressModule,
    InsertModule,
    LaunchModule,
    ListModule,
    LockedModule,
    MisuseModule,
    NotebookModule,
    OverflowMenuVerticalModule,
    PendingModule,
    RecentlyViewedModule,
    ResetModule,
    SaveModule,
    SendAltModule,
    StopFilledAltModule,
    TaskViewModule,
    TrashCanModule,
    UserFollowModule,
    UserMultipleModule,
    UserProfileModule,
    WarningAltFilledModule,
    WarningFilledModule,
    WarningSquareFilledModule,
  ],
})
export class IconsModule {}
