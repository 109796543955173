import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, Observable } from 'rxjs';
import { AppRoutes } from 'src/app/models/enums';
import { getAccessToken } from './auth.helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean {
    return getAccessToken()
      ? true
      : this.msalBroadcastService.inProgress$.pipe(
          filter((status) => status === InteractionStatus.None),
          map(
            () =>
              // Check if user is authenticated (has at least one account).
              this.authService.instance.getAllAccounts().length > 0
                ? true
                : this.router.parseUrl(AppRoutes.LOGIN) // Otherwise, redirect user to login page.
          )
        );
  }
}
