<div class="modal-header">
  <h5>{{ modalTitle }}</h5>
  <button class="btn-close" type="button" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body pb-0">
  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options"
  ></formly-form>
</div>
<div class="modal-footer d-flex">
  <button
    class="btn cx-btn cx-btn--ghost ms-auto"
    type="button"
    (click)="closeModal()"
  >
    Cancel
  </button>
  <button class="btn btn-primary cx-btn ms-3" type="button" (click)="save()">
    {{ submitLabel }}
    <svg ibmIconAdd fill="currentColor" size="16"></svg>
  </button>
</div>
