import { format, isValid, parse } from 'date-fns';
import { toNumber } from 'lodash';
import { Questionnaire } from 'src/app/models/interfaces';

export const evalExpression = <T>(
  expression: string,
  rpt?: Record<string, unknown>
): T | null => {
  let m_CachedEvaluations: any = {};
  let m_QuantifierExecuted = false;
  const ceq = {
    GetVariableRepetitionsByDomain: function (oxo245: unknown) {
      return oxo276(oxo245);
    },
    GetVariableValue: (
      name: string,
      values: Record<string, unknown>,
      type: string
    ) => {
      //console.log('GET VAR:', name, rpt);
      var value = rpt?.hasOwnProperty(name);
      //var value = values?.hasOwnProperty(name);
      if (type === 'isrelevant' && value !== true) {
        return null;
      } else if (type === 'isnotirrelevant' && value === false) {
        return null;
      }
      return rpt ? rpt[name] : undefined;
      // return values ? values[name] : undefined;
    },
    IsWithinRepetition: function (
      oxo155: string,
      oxo237: string,
      oxo285: boolean
    ) {
      return oxo286(oxo155, oxo237, oxo285);
    },
    PopInnermostRepetition: function (oxo155: unknown, oxo337: unknown) {
      return oxo155;
      //return oxo338(oxo155, oxo337);
    },
    PushInnermostRepetition: function (oxo237: unknown, oxo367: unknown) {
      return oxo368(oxo237, oxo367);
    },
  };
  function cxFormatNumber(value: unknown, minimumFractionDigits = 0) {
    return Intl.NumberFormat('en-US', { minimumFractionDigits }).format(
      Number(value)
    );
  }
  function dbfAnd(oxo2889: unknown, oxo2890: unknown) {
    if (oxo2889 === false || oxo2890 === false) {
      return false;
    }
    if (oxo2889 === true) {
      return oxo2890;
    }
    if (oxo2890 === true) {
      return oxo2889;
    }
    return null;
  }
  function dbfConcatenate(oxo2699: unknown, oxo2700: unknown) {
    if (dbjTypeString(oxo2699) && dbjTypeString(oxo2700)) {
      return oxo2699 + oxo2700;
    }
    if (dbjTypeList(oxo2699) && dbjNullOrUndefined(oxo2700)) {
      var oxo1159 = '';
      for (var i = 0; i < oxo2699.length; i++) {
        var value = oxo2699[i];
        var result = dbfToString(value);
        if (dbjNullOrUndefined(result)) {
          return null;
        }
        oxo1159 += result;
      }
      return oxo1159;
    }
    return null;
  }
  function dbfCount(oxo2415: unknown[]) {
    if (dbjTypeList(oxo2415)) {
      return oxo2415.length;
    }
    return null;
  }
  function dbfDate(oxo1888: number, oxo1889: number, oxo1890: number) {
    if (
      dbjTypeNumber(oxo1888) &&
      dbjTypeNumber(oxo1889) &&
      dbjTypeNumber(oxo1890)
    ) {
      return dbjNewDate(oxo1888, oxo1889, oxo1890);
    }
    return null;
  }
  function dbfDaysAfter(oxo2342: unknown, oxo2769: unknown) {
    if (dbjTypeDate(oxo2342) && dbjTypeNumber(oxo2769)) {
      return dbjFromJulienDate(dbjToJulienDate(oxo2342) + oxo2769);
    }
    return null;
  }
  function dbfDifference(oxo2868: unknown, oxo2869: unknown) {
    if (dbjTypeList(oxo2868) && dbjTypeList(oxo2869)) {
      return dbjApplyDifference(oxo2868, oxo2869);
    }
    return null;
  }
  function dbfDistinct(oxo2415: unknown) {
    if (dbjTypeList(oxo2415)) {
      var oxo1159 = [];
      for (var oxo534 = 0; oxo534 < oxo2415.length; oxo534++) {
        var oxo2417 = oxo2415[oxo534];
        if (!dbfMember(oxo2417, oxo1159)) {
          oxo1159[oxo1159.length] = oxo2417;
        }
      }
      return oxo1159;
    }
    return null;
  }
  function dbfEqual(oxo2699: unknown, oxo2700: unknown): boolean | null {
    var oxo2895 = dbjCompare(oxo2699, oxo2700);
    return dbjNullOrUndefined(oxo2895) ? null : oxo2895 === 0;
  }
  function dbfEncodeHTML(oxo283: string, oxo2984: unknown) {
    if (dbjTypeList(oxo283)) {
      return oxo283.join(dbjNewlineCharacter());
    } else if (dbjTypeString(oxo283)) {
      var oxo2985 = Array.from(oxo283);
      var oxo2986 = dbjNullOrUndefined(oxo2984) ? false : oxo2984;
      var oxo2987 = dbjNewlineCharacter();
      var oxo1159 = '';
      var oxo534;
      var oxo2226;
      var oxo2865;
      for (oxo534 = 0; oxo534 < oxo2985.length; oxo534++) {
        oxo2226 = oxo2985[oxo534];
        oxo2865 = oxo2226.charCodeAt(0);

        if (oxo2226 === '&') {
          oxo1159 += '&amp;';
        } else if (oxo2226 === '"') {
          oxo1159 += '&quot;';
        } else if (oxo2226 === "'") {
          oxo1159 += '&apos;';
        } else if (oxo2226 === '<') {
          oxo1159 += '&lt;';
        } else if (oxo2226 === '>') {
          oxo1159 += '&gt;';
        } else if (oxo2226 === oxo2987 && oxo2986 === true) {
          oxo1159 += '<BR/>';
        } else if (oxo2865 >= 127 && oxo2226.length === 1) {
          oxo1159 += '&#' + oxo2865 + ';';
        } else {
          oxo1159 += oxo2226;
        }
      }
      return oxo1159;
    }
    return null;
  }
  function dbfExistsRepetition(
    oxo2976: any,
    oxo2820: unknown,
    oxo237: string,
    oxo2821: any
  ) {
    var oxo2822 = 'EXISTS  (' + oxo2821 + ')';

    var oxo2823 = oxo2821 + oxo237;
    var oxo2824 = m_CachedEvaluations[oxo2823];
    if (oxo2824 !== undefined) {
      return oxo2824;
    }

    m_CachedEvaluations[oxo2823] = null;

    var oxo2825 = dbjGetCollectableRepetitions(
      oxo2820,
      oxo237,
      true,
      oxo2823,
      oxo2822
    );

    if (oxo2825 === null) {
      return null;
    }

    var oxo1159: boolean | null = false;

    for (var oxo155 in oxo2825) {
      var oxo283 = oxo2976(oxo155);

      if (oxo283 === true) {
        oxo1159 = true;
        break;
      } else if (oxo283 !== false) {
        oxo1159 = null;
      }
    }

    m_CachedEvaluations[oxo2823] = oxo1159;

    return oxo1159;
  }
  function dbfFloor(oxo283: number, oxo2701: number) {
    if (dbjTypeNumber(oxo283) && dbjTypeNumber(oxo2701)) {
      var oxo2720 = oxo283 / oxo2701;
      if (dbjTypeNumber(oxo2720)) {
        return Math.floor(oxo2720) * oxo2701;
      }
    }
    return null;
  }
  function dbfForEach(list: unknown, oxo2872: Function) {
    if (dbjTypeList(list)) {
      var result = [];
      var success = true;
      for (var i = 0; i < list.length; i++) {
        const value = oxo2872(list[i]);
        if (dbjNullOrUndefined(value)) {
          success = false;
          break;
        }
        result.push(value);
      }
      if (success) {
        return result;
      }
    }
    return null;
  }
  function dbfHash(oxo2976: Function, oxo534: unknown, oxo237: unknown) {
    if (oxo534 === null) {
      return null;
    }

    if (dbjTypeNumber(oxo534)) {
      // Artem Kolotilkin, 2023-08-26:
      // This is a simplified override to support specific use case
      // of using dbfHash inside of dbfForEach with dbfList.
      const result = oxo2976(oxo237) as unknown[];
      return result ? result[oxo534 - 1] : null;
      // End of override.
    } else if (dbjTypeList(oxo534)) {
      var oxo1159 = [];
      for (var i = 0; i < oxo534.length; i++) {
        var oxo2982 = dbfHashSingleton(oxo2976, oxo534[i], oxo237);
        if (dbjNullOrUndefined(oxo2982)) {
          return null;
        }
        oxo1159.push(oxo2982);
      }
      return oxo1159;
    } else {
      return dbfHashSingleton(oxo2976, oxo534, oxo237);
    }
  }
  function dbfHashSingleton(
    oxo2976: Function,
    oxo534: unknown,
    oxo237: unknown
  ) {
    var oxo163 =
      dbjTypeString(oxo534) && oxo534.charAt(0) === '['
        ? oxo534
        : ceq.PushInnermostRepetition(oxo237, oxo534);
    return oxo2976(oxo163);
  }
  function dbfIf(oxo2891: unknown, oxo2892: unknown, oxo2893: unknown) {
    var oxo2894 = dbjEvalExpr(oxo2891);
    if (oxo2894 === true) {
      return dbjEvalExpr(oxo2892);
    }
    if (oxo2894 === false) {
      return dbjEvalExpr(oxo2893);
    }
    return null;
  }
  function dbfIfKnownElse(oxo283: unknown, oxo2819: unknown) {
    var oxo1930 = dbfKnown(oxo283);
    if (oxo1930 === true) {
      return oxo283;
    }
    if (oxo1930 === false) {
      return oxo2819;
    }
    return null;
  }
  function dbfInverse(oxo283: unknown) {
    if (dbjTypeString(oxo283)) {
      var oxo2729 = '';
      for (var oxo534 = 0; oxo534 < oxo283.length; oxo534++) {
        var oxo2226 = oxo283.charCodeAt(oxo534);
        var oxo2730 = String.fromCharCode(65536 - oxo2226);
        oxo2729 += oxo2730;
      }
      return oxo2729;
    }
    if (dbjTypeBoolean(oxo283)) {
      return !oxo283;
    }
    if (dbjTypeNumber(oxo283)) {
      return 0 - oxo283;
    }
    // if (dbjTypeDate(oxo283)) {
    //   var oxo2731 = dbjNewDate(3999, 12, 31);
    //   var oxo2732 = dbjToJulienDate(oxo2731);
    //   var oxo2733 = dbjToJulienDate(oxo283);
    //   var oxo2734 = oxo2732 - oxo2733;
    //   var oxo2735 = dbjFromJulienDate(oxo2734);
    //   return oxo2735;
    // }
    // if (dbjTypeTime(oxo283)) {
    //   var oxo2504 = dbjToFractionOfDay(oxo283);
    //   var oxo2736 = 1 - oxo2504;
    //   var oxo2737 = dbjFromFractionOfDay(oxo2736);
    //   return oxo2737;
    // }
    // if (dbjTypeList(oxo283)) {
    //   var oxo2738 = [];
    //   for (var oxo534 = 0; oxo534 < oxo283.length; oxo534++) {
    //     var oxo2739 = dbfInverse(oxo283[oxo534]);
    //     if (oxo2739 === null) {
    //       return null;
    //     }
    //     oxo2738[oxo534] = oxo2739;
    //   }
    //   return oxo2738;
    // }
    return null;
  }
  function dbfKnown(oxo283: any): boolean | null {
    if (
      dbjNullOrUndefined(oxo283) ||
      dbjTypeNaN(oxo283) ||
      dbjTypeInfinite(oxo283)
    ) {
      return false;
    }
    if (dbjTypeString(oxo283)) {
      return true;
    }
    if (dbjTypeBoolean(oxo283)) {
      return true;
    }
    if (dbjTypeNumber(oxo283)) {
      return true;
    }
    if (dbjTypeDate(oxo283)) {
      return true;
    }
    if (dbjTypeTime(oxo283)) {
      return true;
    }
    if (dbjTypeList(oxo283)) {
      for (var oxo534 = 0; oxo534 < oxo283.length; oxo534++) {
        var oxo1930 = dbfKnown(oxo283[oxo534]);
        if (oxo1930 !== true) {
          return oxo1930;
        }
      }
      return true;
    }
    return null;
  }
  function dbfKnownTrue(value: unknown) {
    return value === true;
  }
  function dbfList(options: string[]): string[] {
    return options;
  }
  function dbfLookup(oxo283: string, oxo2898: string) {
    if (dbjNullOrUndefined(oxo283)) {
      return null;
    }
    const [pageData] = ((window as any).cePage || []) as Questionnaire[];
    const { lookuptables } = pageData;
    const rows = ([] as Array<[string, string]>).concat(
      ...lookuptables
        .filter(({ columns }) => columns.includes(oxo2898))
        .map(({ rows }) => rows)
    );
    const record = rows.find(([index]) => index === oxo283);
    return record ? record[1] : '';
  }
  function dbfMember(oxo2417: unknown, oxo2415: any): boolean | null {
    if (!oxo2415) {
      oxo2415 = [];
    }
    if (dbjTypeList(oxo2415)) {
      let oxo2870: boolean | null = false;
      var oxo534 = 0;
      while (!oxo2870 && oxo534 < oxo2415.length) {
        oxo2870 = dbfEqual(oxo2415[oxo534], oxo2417);
        oxo534 = oxo534 + 1;
      }
      return oxo2870;
    }
    return null;
  }
  function dbfMinus(oxo2699: number, oxo2700: number) {
    if (dbjTypeNumber(oxo2699) && dbjTypeNumber(oxo2700)) {
      var oxo1159 = oxo2699 - oxo2700;
      if (dbjTypeNumber(oxo1159)) {
        return oxo1159;
      }
    }
    return null;
  }
  function dbfMoreThan(oxo2699: unknown, oxo2700: unknown) {
    var oxo2895 = dbjCompare(oxo2699, oxo2700);
    return dbjNullOrUndefined(oxo2895) ? null : oxo2895 === 1;
  }
  function dbfNot(value: unknown) {
    if (value === true) {
      return false;
    }
    if (value === false) {
      return true;
    }
    return null;
  }
  function dbfOr(oxo2889: unknown, oxo2890: unknown) {
    var oxo2699 = dbjEvalExpr(oxo2889);
    if (oxo2699 === true) {
      return true;
    }

    var oxo2700 = dbjEvalExpr(oxo2890);
    if (oxo2700 === true) {
      return true;
    }

    if (oxo2699 === false && oxo2700 === false) {
      return false;
    }

    return null;
  }
  function dbfPlus(oxo2699: unknown, oxo2700: unknown) {
    if (dbjTypeNumber(oxo2699) && dbjTypeNumber(oxo2700)) {
      var oxo1159 = oxo2699 + oxo2700;
      if (dbjTypeNumber(oxo1159)) {
        return oxo1159;
      }
    }
    return null;
  }
  function dbfRemoveBlanks(oxo283: unknown) {
    if (dbjTypeString(oxo283)) {
      var oxo2415 = dbfToList(oxo283);
      var oxo1159 = '';
      for (var oxo534 = 0; oxo534 < oxo2415.length; oxo534++) {
        var oxo2417 = oxo2415[oxo534];
        if (oxo2417 !== '') {
          oxo1159 += oxo2417;
        }
      }
      return oxo1159;
    }
    if (dbjTypeList<string>(oxo283)) {
      var oxo2415 = dbfToList(oxo283);
      var oxo1159a = [];
      for (var oxo534 = 0; oxo534 < oxo2415.length; oxo534++) {
        var oxo2417 = oxo2415[oxo534];
        if (oxo2417 !== '') {
          oxo1159a.push(oxo2417);
        }
      }
      return oxo1159a;
    }
    return null;
  }
  function dbfReplaceAll(
    oxo283: string | null,
    oxo3001: string,
    oxo3007: string,
    oxo3013: boolean
  ) {
    if (
      dbjTypeString(oxo283) &&
      dbjTypeString(oxo3001) &&
      dbjTypeString(oxo3007) &&
      dbjTypeBoolean(oxo3013)
    ) {
      if (oxo283 === '') {
        return '';
      }
      if (oxo3001 === '') {
        return null;
      }

      var oxo3014 = '';
      for (var oxo534 = 0; oxo534 < oxo3001.length; oxo534++) {
        var oxo2226 = oxo3001.charAt(oxo534);
        if (
          oxo2226 === '*' ||
          oxo2226 === '+' ||
          oxo2226 === '?' ||
          oxo2226 === '^' ||
          oxo2226 === '$' ||
          oxo2226 === '.' ||
          oxo2226 === '[' ||
          oxo2226 === ']' ||
          oxo2226 === '{' ||
          oxo2226 === '}' ||
          oxo2226 === '(' ||
          oxo2226 === ')' ||
          oxo2226 === '|' ||
          oxo2226 === '/' ||
          oxo2226 === '\\'
        ) {
          oxo3014 += '\\';
        }
        oxo3014 += oxo2226;
      }

      var oxo3015 = 'g' + (!oxo3013 ? 'i' : '');
      var oxo3016 = new RegExp(oxo3014, oxo3015);
      return oxo283.replace(oxo3016, oxo3007);
    }
    return null;
  }
  function dbfSum(oxo2415: unknown[]) {
    if (dbjTypeList(oxo2415)) {
      var oxo1159 = 0;
      for (var oxo534 = 0; oxo534 < oxo2415.length; oxo534++) {
        var oxo2417 = oxo2415[oxo534];
        if (!dbjTypeNumber(oxo2417)) {
          return null;
        }
        oxo1159 += oxo2417;
      }
      return oxo1159;
    }
    return null;
  }
  function dbfText(oxo283: unknown, oxo182: string | null) {
    if (oxo283 === '') {
      return '';
    }
    if (dbfKnown(oxo182)) {
      oxo182 = dbfToString(oxo182);
    }
    if (oxo182 === 'currencydigits' && oxo283) {
      return Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 0,
        style: 'currency',
      }).format(Number(oxo283));
    }
    if (oxo182 === 'm/d/yyyy' && oxo283) {
      const date = parse(oxo283 as string, 'yyyy-MM-dd', new Date());
      return format(date, 'M/d/yyyy');
    }
    if (oxo182 === '0,0') {
      return Array.isArray(oxo283)
        ? oxo283.map((o) => cxFormatNumber(o))
        : cxFormatNumber(oxo283);
    }
    /*
    if (dbjTypeNumber(oxo283)) {
      return dbjApplyFormatToNumber(oxo283, oxo182);
    }
    */
    /*
    if (dbjTypeDate(oxo283)) {
      return dbjApplyFormatToDate(oxo283, dbjDecodeCEML(oxo182));
    }
    */
    /*
    if (dbjTypeTime(oxo283)) {
      return dbjApplyFormatToTime(oxo283, dbjDecodeCEML(oxo182));
    }
    */
    /*
    if (dbjTypeBoolean(oxo283)) {
      var oxo2860 = dbjSplitVerticalBarAndDecodeCEML(oxo182);
      var oxo2861 = oxo2860.length > 0 ? oxo2860[0] : '';
      var oxo2862 = oxo2860.length > 1 ? oxo2860[1] : '';
      if (oxo2861 === '') {
        oxo2861 = dbl_boolean_true();
      }
      if (oxo2862 === '') {
        oxo2862 = dbl_boolean_false();
      }
      return oxo283 === true ? oxo2861 : oxo2862;
    }
    */
    if (dbjTypeString(oxo283)) {
      var oxo2863 = dbjTypeString(oxo182) ? oxo182.toLowerCase() : '';
      if (
        oxo2863 === 'words' ||
        oxo2863 === 'wordslow' ||
        oxo2863 === 'wth' ||
        oxo2863 === 'wthlow' ||
        oxo2863 === 'nth' ||
        oxo2863 === 'th'
      ) {
        var oxo2864 = dbfToNumber(oxo283);
        if (dbjTypeNumber(oxo2864)) {
          return oxo2864;
        }
      }

      if (oxo2863 === 'nobreak') {
        return dbfReplaceAll(
          dbfReplaceAll(oxo283, ' ', '\u00A0', true),
          '-',
          '\u2011',
          true
        );
      }

      // var oxo2416 = dbfToList(oxo283);
      // if (oxo2416) {
      //   if (!dbfKnown(oxo182)) {
      //     oxo182 = '^L';
      //   }
      //   return oxo2416;
      // }

      return oxo283;
    }
    if (dbjTypeDateList(oxo283)) {
      return dbjApplyFormatToDateList(oxo283);
    }
    if (dbjTypeNumberList(oxo283)) {
      return dbjApplyFormatToNumberList(oxo283);
    }
    if (dbjTypeList(oxo283)) {
      return dbjApplyFormatToList(oxo283, oxo182);
    }
    return null;
  }
  function dbfTimes(oxo2699: number, oxo2700: number) {
    if (dbjTypeNumber(oxo2699) && dbjTypeNumber(oxo2700)) {
      var oxo1159 = oxo2699 * oxo2700;
      if (dbjTypeNumber(oxo1159)) {
        return oxo1159;
      }
    }
    return null;
  }
  function dbfToInteger(oxo283: string) {
    if (dbjTypeBoolean(oxo283)) {
      return oxo283 === true ? 1 : 0;
    }

    return parseInt(oxo283);
    // if (dbjTypeNumber(oxo283)) {
    //   return oxo283 < 0 ? Math.ceil(oxo283) : Math.floor(oxo283);
    // }
    // if (dbjTypeString(oxo283)) {
    //   var oxo2743 = dbjParseFloat(oxo283);
    //   if (dbjTypeNumber(oxo2743)) {
    //     return oxo2743 < 0 ? Math.ceil(oxo2743) : Math.floor(oxo2743);
    //   }
    // }
    // if (dbjTypeBoolean(oxo283)) {
    //   return oxo283 === true ? 1 : 0;
    // }
    // if (dbjTypeDate(oxo283)) {
    //   return dbjToJulienDate(oxo283);
    // }
    // if (dbjTypeList(oxo283)) {
    //   var oxo1159 = [];
    //   for (var oxo534 = 0; oxo534 < oxo283.length; oxo534++) {
    //     var oxo2744 = dbfToInteger(oxo283[oxo534]);
    //     if (dbjNullOrUndefined(oxo2744)) {
    //       return null;
    //     }
    //     oxo1159.push(oxo2744);
    //   }
    //   return oxo1159;
    // }
    // return null;
  }
  function dbfToList(oxo283: string | string[]): Array<string | unknown> {
    if (dbjTypeList(oxo283)) {
      return oxo283;
    }
    if (dbjTypeString(oxo283)) {
      return oxo283.split(dbjNewlineCharacter());
    }
    return [];
  }
  function dbfToNumber(oxo283: unknown): number | number[] | null {
    return toNumber(oxo283);
  }
  function dbfToString(oxo283: any, oxo2746?: string): string | null {
    if (dbjTypeString(oxo283)) {
      return oxo283;
    }
    if (dbjTypeBoolean(oxo283)) {
      return oxo283 === true ? 'true' : 'false';
    }
    if (dbjTypeNumber(oxo283)) {
      var oxo2711 = RoundTo15Places(oxo283);
      var oxo2747 = oxo2711.toString();
      if (oxo2746 && oxo2746.startsWith('Float') && !oxo2747.includes('.')) {
        oxo2747 = oxo2747 + '.0';
      }
      return oxo2747;
    }
    if (dbjTypeDate(oxo283)) {
      var oxo1888 = dbjGetDateYear(oxo283);
      var oxo1889 = dbjGetDateMonth(oxo283);
      var oxo1890 = dbjGetDateDay(oxo283);
      return (
        (oxo1888 < 10
          ? '000'
          : oxo1888 < 100
          ? '00'
          : oxo1888 < 1000
          ? '0'
          : '') +
        oxo1888 +
        '-' +
        (oxo1889 < 10 ? '0' : '') +
        oxo1889 +
        '-' +
        (oxo1890 < 10 ? '0' : '') +
        oxo1890
      );
    }
    if (dbjTypeTime(oxo283)) {
      var oxo2748 = dbjGetTimeHour(oxo283);
      var oxo2749 = dbjGetTimeMinute(oxo283);
      var oxo2750 = dbjGetTimeSecond(oxo283);
      return (
        (oxo2748 < 10 ? '0' : '') +
        oxo2748 +
        ':' +
        (oxo2749 < 10 ? '0' : '') +
        oxo2749 +
        ':' +
        (oxo2750 < 10 ? '0' : '') +
        oxo2750
      );
    }
    if (dbjTypeList(oxo283)) {
      var oxo2415 = [];
      for (var oxo2751 = 0; oxo2751 < oxo283.length; oxo2751++) {
        var oxo2417 = oxo283[oxo2751];
        oxo2415.push(dbfToString(oxo2417, oxo2746));
      }
      return oxo2415.join(dbjNewlineCharacter());
    }
    return null;
  }
  function dbfYear(oxo2342: unknown) {
    if (dbjTypeDate(oxo2342)) {
      return dbjGetDateYear(oxo2342);
    } else if (typeof oxo2342 === 'string') {
      return Number(oxo2342.substring(0, 4));
    }
    return null;
  }
  function dbfYearsBefore(oxo2779: unknown, oxo2793: number) {
    if (dbjTypeDate(oxo2779) && dbjTypeNumber(oxo2793)) {
      return dbjApplyYearsAfter(oxo2779, 0 - oxo2793);
    } else if (typeof oxo2779 === 'string') {
      const date: Array<number | string> = oxo2779.split('-');
      date[0] = Number(date[0]) - oxo2793;
      return date.join('-');
    }
    return null;
  }
  function dbjApplyDifference(oxo2868: unknown[], oxo2869: unknown[]) {
    var oxo1159 = [];
    var oxo706: boolean[] = [];
    var oxo534;
    let oxo2417: string;
    for (oxo534 = 0; oxo534 < oxo2869.length; oxo534++) {
      oxo2417 = oxo2869[oxo534] as string;
      if (dbjNullOrUndefined(oxo706[oxo2417 as any])) {
        oxo706[oxo2417 as any] = true;
      }
    }
    for (oxo534 = 0; oxo534 < oxo2868.length; oxo534++) {
      oxo2417 = oxo2868[oxo534] as string;
      if (dbjNullOrUndefined(oxo706[oxo2417 as any])) {
        oxo1159.push(oxo2417);
      }
    }
    return oxo1159;
  }
  function dbjApplyFormatToDateList(oxo2415: string[]): string[] {
    return oxo2415.map((o) =>
      format(parse(o, 'yyyy-MM-dd', new Date()), 'MMMM d, yyyy')
    );
  }
  function dbjApplyFormatToNumberList(oxo2415: string[]): string[] {
    return oxo2415.map((o) => Number(o).toLocaleString());
  }
  function dbjApplyFormatToList(oxo2415: unknown[], oxo182: string | null) {
    if (!dbjTypeString(oxo182)) {
      oxo182 = ', ';
    } else if (oxo182 === '') {
      return oxo2415;
    }

    var oxo184 = dbjGetPunctuation(oxo182);
    var oxo1500 = oxo184.leading;
    var oxo1501 = oxo184.penultimate;
    var oxo1502 = oxo184.ultimate;

    return dbjApplyPunctuateToList(oxo2415, oxo1500, oxo1501, oxo1502);
  }
  function dbjApplyMod(oxo283: number, oxo2701: number) {
    return ((oxo283 % oxo2701) + oxo2701) % oxo2701;
  }
  function dbjApplyPunctuateToList(
    oxo2415: unknown[],
    oxo1500: string,
    oxo1501: string,
    oxo1502: string
  ) {
    var oxo2416 = [];
    for (var oxo534 = 0; oxo534 < oxo2415.length; oxo534++) {
      var oxo2417 = oxo2415[oxo534];
      if (oxo2417 === null) {
        return null;
      }
      if (typeof oxo2417 !== 'string') {
        oxo2417 = dbfText(oxo2417, '');
      }
      oxo2416.push(oxo2417);
    }
    var oxo1159 = '';

    if (oxo2416.length > 0) {
      oxo1159 += oxo2416[0];
    }

    for (var oxo534 = 1; oxo534 < oxo2416.length - 1; oxo534++) {
      oxo1159 += oxo1500 + oxo2416[oxo534];
    }

    if (oxo2416.length > 1) {
      oxo1159 += oxo1501 + oxo2416[oxo2416.length - 1];
    }

    if (oxo2416.length > 0) {
      oxo1159 += oxo1502;
    }
    return oxo1159;
  }
  function dbjApplyYearsAfter(oxo2779: unknown, oxo2793: unknown) {
    var oxo2785 = dbjGetDateYear(oxo2779);
    var oxo2786 = dbjGetDateMonth(oxo2779);
    var oxo2787 = dbjGetDateDay(oxo2779);
    var oxo2790 = oxo2785 + oxo2793;
    var oxo2789 = oxo2786;
    var oxo2791 = Math.min(oxo2787, dbjLastDayOfMonth(oxo2790, oxo2789));
    return dbjNewDate(oxo2790, oxo2789, oxo2791);
  }
  function dbjCompare(oxo2699: any, oxo2700: any): null | 0 | 1 | -1 {
    if (
      (dbjTypeString(oxo2699) && dbjTypeString(oxo2700)) ||
      (dbjTypeBoolean(oxo2699) && dbjTypeBoolean(oxo2700)) ||
      (dbjTypeNumber(oxo2699) && dbjTypeNumber(oxo2700))
    ) {
      if (oxo2699 < oxo2700) {
        return -1;
      } else if (oxo2699 > oxo2700) {
        return 1;
      } else {
        return 0;
      }
    }
    if (
      (!isNaN(oxo2699) && dbjTypeNumber(oxo2700)) ||
      (dbjTypeNumber(oxo2699) && !isNaN(oxo2700))
    ) {
      if (Number(oxo2699) < Number(oxo2700)) {
        return -1;
      } else if (Number(oxo2699) > Number(oxo2700)) {
        return 1;
      } else {
        return 0;
      }
    }
    if (dbjTypeDate(oxo2699) && dbjTypeDate(oxo2700)) {
      if (dbjGetDateYear(oxo2699) < dbjGetDateYear(oxo2700)) {
        return -1;
      } else if (dbjGetDateYear(oxo2699) > dbjGetDateYear(oxo2700)) {
        return 1;
      } else if (dbjGetDateMonth(oxo2699) < dbjGetDateMonth(oxo2700)) {
        return -1;
      } else if (dbjGetDateMonth(oxo2699) > dbjGetDateMonth(oxo2700)) {
        return 1;
      } else if (dbjGetDateDay(oxo2699) < dbjGetDateDay(oxo2700)) {
        return -1;
      } else if (dbjGetDateDay(oxo2699) > dbjGetDateDay(oxo2700)) {
        return 1;
      } else {
        return 0;
      }
    }
    if (dbjTypeTime(oxo2699) && dbjTypeTime(oxo2700)) {
      if (dbjGetTimeHour(oxo2699) < dbjGetTimeHour(oxo2700)) {
        return -1;
      } else if (dbjGetTimeHour(oxo2699) > dbjGetTimeHour(oxo2700)) {
        return 1;
      } else if (dbjGetTimeMinute(oxo2699) < dbjGetTimeMinute(oxo2700)) {
        return -1;
      } else if (dbjGetTimeMinute(oxo2699) > dbjGetTimeMinute(oxo2700)) {
        return 1;
      } else if (dbjGetTimeSecond(oxo2699) < dbjGetTimeSecond(oxo2700)) {
        return -1;
      } else if (dbjGetTimeSecond(oxo2699) > dbjGetTimeSecond(oxo2700)) {
        return 1;
      } else {
        return 0;
      }
    }
    if (dbjTypeList(oxo2699) && dbjTypeList(oxo2700)) {
      var oxo2896 = oxo2699.length;
      var oxo2897 = oxo2700.length;
      var oxo1159 = dbjCompare(oxo2896, oxo2897);
      var oxo534 = 0;
      while (oxo1159 === 0 && oxo534 < oxo2896 && oxo534 < oxo2897) {
        oxo1159 = dbjCompare(oxo2699[oxo534], oxo2700[oxo534]);
        oxo534 = oxo534 + 1;
      }
      return oxo1159;
    }
    return null;
  }
  function dbjEvalExpr(oxo227: unknown) {
    return typeof oxo227 === 'function' ? oxo227() : oxo227;
  }
  function dbjFromJulienDate(oxo2376: number) {
    var oxo2375 = oxo2376 - 25569;
    var oxo2374 = oxo2375 * (24 * 60 * 60 * 1000);
    var oxo2377 = new Date(oxo2374);
    var oxo1888 = oxo2377.getFullYear();
    var oxo1889 = oxo2377.getMonth() + 1;
    var oxo1890 = oxo2377.getDate();
    var oxo2342 = dbjNewDate(oxo1888, oxo1889, oxo1890);
    return oxo2342;
  }
  function dbjGetCollectableRepetitions(
    oxo2820: any,
    oxo237: string,
    oxo2972: boolean,
    oxo2823: unknown,
    oxo2822: unknown
  ): Array<boolean> | null {
    m_QuantifierExecuted = true;
    var oxo2825: Array<boolean> = [];
    for (var oxo2973 in oxo2820) {
      var oxo453 = oxo2820[oxo2973];
      var oxo2974 = ceq.GetVariableRepetitionsByDomain(oxo453);
      if (oxo2974 === null) {
        if (oxo2972 === true) {
          return null;
        }
      }
      for (var oxo2975 in oxo2974) {
        var oxo454: any = oxo2974[oxo2975];
        if (
          !oxo2825[oxo454] &&
          ceq.IsWithinRepetition(oxo454, oxo237, oxo2972) !== null
        ) {
          oxo2825[oxo454] = true;
        }
      }
    }
    return oxo2825;
  }
  function dbjGetDateDay(oxo2342: any) {
    if (dbjTypeDate(oxo2342)) {
      return oxo2342.day;
    }
  }
  function dbjGetDateMonth(oxo2342: any) {
    if (dbjTypeDate(oxo2342)) {
      return oxo2342.month;
    }
  }
  function dbjGetDateYear(oxo2342: any) {
    if (dbjTypeDate(oxo2342)) {
      return oxo2342.year;
    }
  }
  function dbjGetPunctuation(oxo182: string) {
    var oxo1591 = dbjSplitVerticalBarAndDecodeCEML(oxo182);
    var oxo1500 = oxo1591[0];
    var oxo1501 = oxo1591.length >= 2 ? oxo1591[1] : oxo1500;
    var oxo1502 = oxo1591.length >= 3 ? oxo1591[2] : '';
    return {
      leading: oxo1500 ? oxo1500 : '',
      penultimate: oxo1501 ? oxo1501 : '',
      ultimate: oxo1502 ? oxo1502 : '',
    };
  }
  function dbjGetTimeHour(oxo2366: any) {
    if (dbjTypeTime(oxo2366)) {
      return oxo2366.hour;
    }
  }
  function dbjGetTimeMinute(oxo2366: any) {
    if (dbjTypeTime(oxo2366)) {
      return oxo2366.minute;
    }
  }
  function dbjGetTimeSecond(oxo2366: any) {
    if (dbjTypeTime(oxo2366)) {
      return oxo2366.second;
    }
  }
  function dbjLastDayOfMonth(oxo1888: number, oxo1889: number) {
    return oxo1889 === 2
      ? dbjLeapYear(oxo1888)
        ? 29
        : 28
      : oxo1889 === 4 || oxo1889 === 6 || oxo1889 === 9 || oxo1889 === 11
      ? 30
      : 31;
  }
  function dbjLeapYear(oxo1888: number) {
    return (
      dbjApplyMod(oxo1888, 400) === 0 ||
      (dbjApplyMod(oxo1888, 4) === 0 && dbjApplyMod(oxo1888, 100) !== 0)
    );
  }
  function dbjNewDate(oxo1888: number, oxo1889: number, oxo1890: number) {
    if (dbjTypeInteger(oxo1888) === false) {
      return null;
    }
    if (dbjTypeInteger(oxo1889) === false) {
      return null;
    }
    if (dbjTypeInteger(oxo1890) === false) {
      return null;
    }
    if (oxo1890 > dbjLastDayOfMonth(oxo1888, oxo1889)) {
      return null;
    }
    return {
      year: oxo1888,
      month: oxo1889,
      day: oxo1890,
    };
  }
  function dbjNewlineCharacter() {
    return decodeURIComponent('%0A');
  }
  function dbjNullOrUndefinedOrEmpty(oxo283?: string | null) {
    return oxo283 === null || oxo283 === undefined || oxo283 === '';
  }
  function dbjSplitVerticalBarAndDecodeCEML(oxo2697: string) {
    if (dbjNullOrUndefinedOrEmpty(oxo2697)) {
      return [];
    }
    var oxo1159 = [];
    var oxo534 = 0;
    var oxo2226;
    var oxo2698 = '';
    while (oxo534 < oxo2697.length) {
      oxo2226 = oxo2697.charAt(oxo534);
      if (oxo2226 === '^' && oxo534 < oxo2697.length - 1) {
        oxo534++;
        oxo2226 = oxo2697.charAt(oxo534);
        if (oxo2226 === 'L' || oxo2226 === 'l') {
          oxo2698 += dbjNewlineCharacter();
        } else if (oxo2226 === '|') {
          oxo2698 += oxo2226;
        } else {
          oxo2698 += '^' + oxo2226;
        }
      } else if (oxo2226 === '|') {
        oxo1159.push(oxo2698);
        oxo2698 = '';
      } else {
        oxo2698 += oxo2226;
      }
      oxo534++;
    }
    oxo1159.push(oxo2698);
    return oxo1159;
  }
  function dbjToJulienDate(oxo2342: unknown): number {
    var oxo1888 = dbjGetDateYear(oxo2342);
    var oxo1889 = dbjGetDateMonth(oxo2342);
    var oxo1890 = dbjGetDateDay(oxo2342);
    var oxo2373 = oxo1888 + '/' + oxo1889 + '/' + oxo1890 + ' 00:00:00';
    var oxo2374 = Date.parse(oxo2373);
    var oxo2375 = Math.ceil(oxo2374 / (24 * 60 * 60 * 1000));
    var oxo2376 = oxo2375 + 25569;
    return oxo2376;
  }
  function dbjTypeBoolean(oxo283: unknown): oxo283 is boolean {
    return typeof oxo283 === 'boolean';
  }
  function dbjTypeDate(oxo2342: any): boolean {
    return (
      typeof oxo2342 === 'object' &&
      oxo2342 !== null &&
      oxo2342 !== undefined &&
      oxo2342.year !== undefined
    );
  }
  function dbjTypeDateList(oxo2415: any): oxo2415 is string[] {
    return (
      Array.isArray(oxo2415) &&
      oxo2415.every((d) => {
        try {
          if (isValid(new Date(d))) {
            const date = parse(d, 'yyyy-MM-dd', new Date());
            return !isNaN(date.getTime());
          }
          return false;
        } catch {
          return false;
        }
      })
    );
  }
  function dbjTypeInfinite(oxo283: unknown) {
    return typeof oxo283 === 'number' && !isFinite(oxo283);
  }
  function dbjTypeInteger(oxo283: unknown) {
    return dbjTypeNumber(oxo283) && oxo283 === Math.floor(oxo283);
  }
  function dbjTypeList<T>(oxo2415: any): oxo2415 is Array<T> {
    return (
      typeof oxo2415 === 'object' && oxo2415 && oxo2415.length !== undefined
    );
  }
  function dbjTypeNaN(oxo283: unknown) {
    return typeof oxo283 === 'number' && isNaN(oxo283);
  }
  function dbjTypeNumber(oxo283: unknown): oxo283 is number {
    if (Array.isArray(oxo283)) {
      return false;
    }
    return (
      (typeof oxo283 === 'number' && !isNaN(oxo283) && isFinite(oxo283)) ||
      !isNaN(oxo283 as any)
    );
  }
  function dbjTypeNumberList(oxo2415: any): oxo2415 is string[] {
    return Array.isArray(oxo2415) && oxo2415.every((n) => !isNaN(n));
  }
  function dbjTypeString(oxo283: unknown): oxo283 is string {
    return typeof oxo283 === 'string';
  }
  function dbjTypeTime(oxo2366: any) {
    return (
      typeof oxo2366 === 'object' &&
      oxo2366 !== null &&
      oxo2366 !== undefined &&
      oxo2366.hour !== undefined
    );
  }
  function dbjNullOrUndefined(oxo283: unknown): boolean {
    return oxo283 === null || oxo283 === undefined;
  }
  function oxo162(oxo732: any) {
    var oxo1159 = '';
    for (var oxo534 = 0; oxo534 < oxo732.length; oxo534++) {
      oxo1159 += oxo2222(oxo732[oxo534]);
    }
    return oxo1159;
  }
  function oxo164(oxo155: any) {
    if (oxo155 === '') {
      return [];
    } else if (oxo155 === '[]') {
      return [''];
    } else if (
      oxo155.length > 2 &&
      oxo155.charAt(0) === '[' &&
      oxo155.charAt(oxo155.length - 1) === ']'
    ) {
      var oxo2224 = oxo155.substr(1, oxo155.length - 1).split('][');
      var oxo1159 = [];
      for (var i = 0; i < oxo2224.length; i++) {
        oxo1159[i] = oxo2225(oxo2224[i]);
      }
      return oxo1159;
    } else {
      return [];
    }
  }
  function oxo276(oxo453: unknown): Record<string, string> | null {
    var oxo2128 = null;

    // TODO: Review and fix this.
    /*
  if (typeof ceqGetVariableRepetitionsByDomain === 'function') {
    oxo2128 = ceqGetVariableRepetitionsByDomain(oxo453);
  }

  if (oxo2128 === null && ceqGlobalVariables.PreviewIsInitialised === true) {
    var oxo2128 = [];
    var oxo706 = {};
    for (var oxo974 in ceqTemplateWordings) {
      var oxo975 = oxo976(oxo974);
      var oxo2129 = oxo975.VariableDomains;
      var oxo1410 = oxo2129[oxo453];
      if (oxo1410) {
        var oxo2130 = oxo1345(oxo1410, true, false, false);
        if (oxo2130) {
          for (var i = 0; i < oxo2130.length; i++) {
            var oxo314 = oxo2130[i];
            if (!oxo706[oxo314]) {
              oxo706[oxo314] = true;
              oxo2128.push(oxo314);
            }
          }
        }
      }
    }
  }
  */

    return oxo2128;
  }
  function oxo286(oxo155: string, oxo237: string, oxo285: boolean) {
    var oxo1159 = null;
    if (oxo285 === false && oxo155 === oxo237) {
      oxo1159 = '';
    } else if (
      dbjTypeString(oxo155) &&
      dbjTypeString(oxo237) &&
      oxo155.length > oxo237.length &&
      oxo155.substr(0, oxo237.length) === oxo237
    ) {
      oxo1159 = oxo155.substr(oxo237.length);
    }
    return oxo1159;
  }
  function oxo338(oxo163: unknown, oxo337: unknown) {
    if (oxo163 === '') {
      return oxo337 === true ? '' : null;
    }
    var oxo698 = oxo164(oxo163);
    oxo698.pop();
    return oxo162(oxo698);
  }
  function oxo368(oxo163: unknown, oxo367: unknown) {
    return oxo163 + oxo2222(oxo367);
  }
  function oxo2222(oxo283: any) {
    if (dbjTypeNumber(oxo283)) {
      return '[' + oxo283.toString() + ']';
    }
    if (!dbjTypeString(oxo283)) {
      return '[]';
    }
    var oxo1159 = '';
    var oxo534 = 0;
    var oxo2226 = '';
    oxo1159 += '[';
    while (oxo534 < oxo283.length) {
      oxo2226 = oxo283.charAt(oxo534);
      oxo1159 = oxo1159 + (oxo2226 === ']' ? ']]' : oxo2226);
      oxo534 = oxo534 + 1;
    }
    oxo1159 += ']';
    return oxo1159;
  }
  function oxo2225(oxo283: any) {
    var oxo1159 = '';
    var oxo534 = 0;
    var oxo2226 = '';
    while (oxo534 < oxo283.length) {
      oxo2226 = oxo283.charAt(oxo534);
      oxo534 = oxo534 + 1;
      if (oxo2226 === ']') {
        if (oxo534 < oxo283.length && oxo283.charAt(oxo534) === ']') {
          oxo1159 += ']';
          oxo534 += 1;
        }
      } else {
        oxo1159 += oxo2226;
      }
    }
    return oxo1159;
  }
  function RoundTo15Places(oxo283: number): number {
    return parseFloat(typeof oxo283 === 'number' ? oxo283.toFixed(15) : '0');
  }

  const scope = {
    ceq,
    dbfAnd,
    dbfConcatenate,
    dbfCount,
    dbfDate,
    dbfDaysAfter,
    dbfDifference,
    dbfDistinct,
    dbfEncodeHTML,
    dbfEqual,
    dbfFloor,
    dbfForEach,
    dbfHash,
    dbfIf,
    dbfIfKnownElse,
    dbfInverse,
    dbfKnown,
    dbfKnownTrue,
    dbfList,
    dbfLookup,
    dbfMember,
    dbfMinus,
    dbfMoreThan,
    dbfNot,
    dbfOr,
    dbfPlus,
    dbfRemoveBlanks,
    dbfSum,
    dbfText,
    dbfTimes,
    dbfToInteger,
    dbfToList,
    dbfToString,
    dbfYear,
    dbfYearsBefore,
    rpt,
  };

  try {
    const result = Function(`with (this) { return ${expression}; }`).bind(
      scope
    )();
    //const result = eval(expression);

    if (Array.isArray(result)) {
      return result.map((item) =>
        typeof item === 'function' ? item() : item
      ) as any as T;
    }

    return result;
  } catch (error) {
    console.error(error);
  }

  return null;
};
