/**
[COMMONUI STARTER TEMPLATE: TEMPLATE START / APP ENTRY POINT: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
Before initiating development, or as required / determined during your product development lifecycle,
please take the one (1) OPTIONAL step and only this:
 - Update your environment settings if you do not need intend to use left navigation or any other consistent left element.

By default the left navbar is available.

There is a solitary path involving content projection for additional left navbar needs.

Doing this will disable the left navbar in your solution and you need take no further steps.

The viewport will resize appropriately and emphatically no further action is required on your part. You may re/implment
the left nav at any time by updating your environment settings, taking care to provide appropriate data to the component.

[COMPLIANCE]
Expect this file to be routinely scanned for compliance.

Do not edit or remove this content.

[EXCEPTIONS]
Exceptions to this guidance are not permitted. Simplified - you cannot edit this file.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
*/
/** Imports */
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
/** Component declaration */
@Component({
  selector: 'app-template-start',
  templateUrl: './template-start.component.html',
  styleUrls: ['./template-start.component.css']
})
/** Class */
export class TemplateStartComponent implements OnInit {

  /**
   * Should the left menu be availble for display? It must be explictly disabled via the environemnt settings.
   * Default is true and overriden if set in the env.
   * */
  public envShowLeftMenu = true;
  /** Should the secondary header be availble for display? */
  public envShowSecondaryHeader = false;;

  /** Constructor */
  constructor() {
    //
   }

  /** Lifecycle */
  ngOnInit(): void {
    if (environment) {
      this.envShowSecondaryHeader =environment.appSpecifics.displaySecondaryHeader;
      this.envShowLeftMenu = environment.appSpecifics.enableLeftMenu;
    }
  }

}
