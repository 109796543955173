import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { BehaviorSubject, startWith, Subject, takeUntil } from 'rxjs';
import { ContractExpressService } from 'src/app/core/services';

@Component({
  selector: 'app-questionnaire-field-wrapper',
  templateUrl: './questionnaire-field-wrapper.component.html',
})
export class QuestionnaireFieldWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  destroyed$ = new Subject<boolean>();
  label$ = new BehaviorSubject<string>('');

  constructor(private contractExpressService: ContractExpressService) {
    super();
  }

  ngOnInit(): void {
    if (this.props['promptexpression']) {
      this.initDynamicPrompt();
    } else {
      this.label$.next(this.props.label || '');
    }

    this.saveDefaultCheckboxValues();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initDynamicPrompt(): void {
    this.formControl.root.valueChanges
      .pipe(takeUntil(this.destroyed$), startWith(''))
      .subscribe(() =>
        this.label$.next(
          this.contractExpressService
            .eval<string[]>(this.props['promptexpression'], this.model)
            ?.join('') || ''
        )
      );
  }

  private saveDefaultCheckboxValues(): void {
    const { key, type } = this.field;
    if (this.props['unknown']) {
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
  }
}
