import { Injectable } from '@angular/core';
import { OdataBackendService } from '../odata-backend/odata-backend.service';
import { BehaviorSubject, catchError, map, Observable, take, tap, throwError } from 'rxjs';
import { Matter } from '../../../models/interfaces';
import { MatterWithDescriptionAndNumber } from '../../../models/interfaces/matter';
import { EntityMapperService } from '../entity-mapper/entity-mapper.service';

@Injectable({
  providedIn: 'root'
})
export class MatterService {
  constructor(
    private entityMapperService: EntityMapperService,
    private odataBackendService: OdataBackendService
  ) { }

  private _matters$ = new  BehaviorSubject<MatterWithDescriptionAndNumber[]>([]);

  get matters$(): Observable<MatterWithDescriptionAndNumber[]> {
    return this._matters$.asObservable().pipe();
  }

  set matters(value: MatterWithDescriptionAndNumber[]) {
    this._matters$.next(value);
  }

  searchClientMatters(clientNumber: string, searchTerm: string | null): Observable<MatterWithDescriptionAndNumber[]> {
    const filter = { filter: {
      and : [
        { clientNumber },
        {
          or: [
            `contains(matterNumber, '${searchTerm}')`,
            `contains(MatterDescription, '${searchTerm}')`
          ]
        }
      ]
    }};
    return this.odataBackendService
      .getEntitySet<Matter>('Matters', filter)
      .pipe(
        take(1),
        map(matters => matters.map(matter => this.entityMapperService.mapMatter(matter))),
        tap(matters => this.matters = matters),
        catchError((error) => throwError(error)),
      );
  }
}
