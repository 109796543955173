import { FormArray, FormGroup } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { merge } from 'lodash';
import { filter, tap } from 'rxjs';
import {
  CompanySecuritiesComponent,
  DirectorOptionHoldingsComponent,
  FlatRepeatGroupComponent,
  IpasaComponent,
  MiscQuestionsComponent,
  QuestionnaireGroupWrapper,
  RepeatGroupComponent,
  RepeatTableComponent,
  RightToAcquireComponent,
} from 'src/app/features/questionnaire';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import {
  Questionnaire,
  QuestionnaireGroup,
  QuestionnaireVariable,
  RepeatTableConfig,
} from 'src/app/models/interfaces';
import { evalExpression } from './contract-express.functions';
import { REPEAT_GROUP_SUFFIX } from './contract-express.service';
import {
  getCheckboxFieldConfig,
  getDateFieldConfig,
  getDefaultFieldConfig,
  getFileUploadFieldConfig,
  getMultiCheckboxFieldConfig,
  getNumericFieldConfig,
  getRadioFieldConfig,
  getSelectFieldConfig,
  getTextAreaFieldConfig,
} from './field-configs';

const customRepeatGroups: Record<string, RepeatTableConfig> = {
  company_miscquestionnumber: {
    component: MiscQuestionsComponent,
  },
  signatory_companysecuritiesnumber: {
    component: CompanySecuritiesComponent,
  },
  signatory_companysecuritiesrtanumber: {
    component: RightToAcquireComponent,
  },
  signatory_donumber: {
    component: DirectorOptionHoldingsComponent,
  },
  signatory_form5transactionnumber: {
    component: RepeatTableComponent,
    columns: [
      {
        label: 'Date of transaction',
        key: 'signatory_form5transactiondate',
        width: '12rem',
      },
      {
        label: 'Number of shares',
        key: 'signatory_form5transactionshares',
        width: '12rem',
      },
      {
        label: 'Description of transaction',
        key: 'signatory_form5transactiondesc',
      },
    ],
    labels: {
      add_first: 'Add one or more transactions',
      add_more: 'Add another transaction',
    },
    repeatName: 'Form 5 Transaction',
    summary: {
      primary: {
        field: 'signatory_form5transactiondate',
        format: 'date',
      },
      secondary: {
        field: 'signatory_form5transactionshares',
        format: 'number',
        suffix: 'Shares',
      },
      additional: {
        field: 'signatory_form5transactiondesc',
      },
    },
  },
  signatory_ipasanumber: {
    component: IpasaComponent,
  },
  signatory_nqdefcompnumber: {
    component: RepeatTableComponent,
    columns: [
      {
        label: 'Executive contributions',
        key: 'signatory_nqdefcompexeccontributionslfy',
        width: '13rem',
      },
      {
        label: 'Aggregate earnings',
        key: 'signatory_nqdefcompaggearningslfy',
        width: '13rem',
      },
      {
        label: 'Aggregate withdrawals/ distributions',
        key: 'signatory_nqdefcompaggdistributions',
        width: '13rem',
      },
      {
        label: 'Aggregate balance at FYE',
        key: 'signatory_nqdefcompaggbalancefye',
        width: '13rem',
      },
      {
        label: 'Above-market or preferential earnings on deferred comp.',
        key: 'signatory_nqdefcompabovemkprefearningslfy',
        width: '14rem',
      },
    ],
    labels: {
      add_first: 'Add one or more benefits',
      add_more: 'Add another benefit',
    },
  },
  signatory_pensionplannumber: {
    component: RepeatTableComponent,
    columns: [
      {
        label: 'Pension plan name',
        key: 'signatory_pensionplanname',
      },
      {
        label: 'Years of Credited Service',
        key: 'signatory_pensionplancreditedserviceyears',
        width: '10rem',
      },
      {
        label: 'Present Value of Benefits',
        key: 'signatory_pensionplanaccumbenefitsvalue',
        width: '10rem',
      },
      {
        label: 'Payment Last Fiscal Year',
        key: 'signatory_pensionplanpymtsduringlfy',
        width: '10rem',
      },
      {
        label: 'Change in Acturial Present Value',
        key: 'signatory_pensionplanchangeaccumbenefitsvalue',
        width: '10rem',
      },
    ],
    labels: {
      add_first: 'Add one or more pension plans',
      add_more: 'Add another pension plan',
    },
  },
};

export function getPageConfig(
  model?: QuestionnaireFormModel,
  options?: Record<string, unknown>,
): FormlyFieldConfig[] {
  const [pageData] = ((window as any).cePage || []) as Questionnaire[];
  if (pageData) {
    const { activatedpage, questionnairepages } = pageData;
    const questionnairePage = questionnairepages[activatedpage];
    const { questionnairegroups, usage } = questionnairePage || {};
    return Object.values(questionnairegroups || {})
      .filter((group) => group.layout !== 'offpage')
      .map((group) => {
        const [usageKey] = Object.keys(group.usage).map(Number);
        if (usage[usageKey]) {
          const [reference] = usage[usageKey].references;
          if (reference) {
            const [grouprepeat] = reference;
            group.grouprepeat = grouprepeat;
          }
          const [repeatcontext] = usage[usageKey].repeatcontext;
          const context = repeatcontext
            ? (repeatcontext(model) as number[])
            : undefined;
          if (context) {
            group.repeatcontext = Math.max(...context);
          }
        }
        return mapToFieldGroupConfig(group, model, options);
      });
  }

  return [];
}

function mapToFieldGroupConfig(
  group: QuestionnaireGroup,
  formModel?: QuestionnaireFormModel,
  options?: Record<string, unknown>,
): FormlyFieldConfig {
  const {
    guidanceexpression,
    questionnairevariableorder,
    questionnairevariables,
    title,
    titleexpression,
  } = group;
  const guidance = guidanceexpression
    ? evalExpression<string[]>(guidanceexpression, formModel)?.join('')
    : group.guidance;
  const label = titleexpression
    ? evalExpression<string[]>(titleexpression, formModel)?.join('')
    : title;
  const isListVerifyGroup = label?.includes('_ListVerify');
  const variables = questionnairevariableorder || [];
  const fieldGroup = variables.reduce<FormlyFieldConfig[]>(
    (groups, key, i, fields) => {
      const variable = questionnairevariables[key];
      if (variable.inputmethod === 'GroupRepeat') {
        const children = fields
          .splice(i + 1)
          .reduce<Record<string, QuestionnaireVariable>>(
            (all, field) => ({
              ...all,
              [field]: questionnairevariables[field],
            }),
            {},
          );

        groups.push(
          mapToRepeaterFieldArray(
            group,
            variable,
            children,
            formModel,
            options,
          ),
        );
      } else if (group.repeatcontext && formModel) {
        // if there is a repeatcontext, prepare a new type of a repeater group.
        const children = fields
          .splice(0)
          .reduce<Record<string, QuestionnaireVariable>>(
            (all, field) => ({
              ...all,
              [field]: questionnairevariables[field],
            }),
            {},
          );
        groups.push(mapToRepeaterFlatFieldArray(group, children, formModel));
      } else {
        const required =
          isListVerifyGroup &&
          !key.includes('_verify') &&
          !key.includes('_desc')
            ? false
            : true;
        groups.push(
          mapToFieldConfig(key, variable, required, false, formModel),
        );
      }
      return groups;
    },
    [],
  );

  return {
    fieldGroup,
    props: { guidance, label: label?.replace('_ListVerify', '') },
    validators: {
      listVerify: {
        expression: (formGroup: FormGroup) => {
          const listVariables = questionnairevariableorder.filter(
            (name) => !name.includes('_verify') && !name.includes('_desc'),
          );
          const listVerify = listVariables.length
            ? listVariables.some(
                (name) =>
                  formGroup.get(name)?.value ||
                  formGroup.get(name)?.value === 0 ||
                  formGroup.get(name)?.value === false,
              )
            : true;
          return isListVerifyGroup ? listVerify : true;
        },
      },
    },
    wrappers: [QuestionnaireGroupWrapper],
  };
}

function mapToRepeaterFieldArray(
  {
    includebuttons,
    layout,
    repeattitleexpression,
    repeatcontext,
  }: QuestionnaireGroup,
  parent: QuestionnaireVariable,
  children: Record<string, QuestionnaireVariable>,
  formModel?: QuestionnaireFormModel,
  options?: Record<string, unknown>,
): FormlyFieldConfig {
  const parentKey = parent.name.toLowerCase();
  const repeatRows = repeatcontext || 0;
  const fieldArrayKey = parentKey.concat(REPEAT_GROUP_SUFFIX);
  const repeater =
    layout === 'griddown' || Object.keys(customRepeatGroups).includes(parentKey)
      ? false
      : true;
  const fieldGroup = Object.entries(children).map(([key, variable]) =>
    mapToFieldConfig(key, variable, true, repeater, formModel),
  );
  const childrenVariables = Object.values(children);
  const [firstVariable] = childrenVariables;
  const label = firstVariable?.promptexpression
    ? evalExpression<string[]>(firstVariable.promptexpression, formModel)?.join(
        '',
      )
    : firstVariable?.prompt;
  const repeatTitles: Array<string | string[]> = [];
  if (repeattitleexpression) {
    for (let i = 1; i <= repeatRows; i++) {
      const title =
        evalExpression<string[]>(repeattitleexpression, formModel, i) || [];
      repeatTitles.push(title.join(''));
    }
  }
  const titles = childrenVariables.map(({ prompt, promptexpression }) =>
    promptexpression
      ? evalExpression<string[]>(promptexpression, formModel)?.join('')
      : prompt,
  );
  const enableSummary = options && options['isRespondent'] ? true : false;

  // If there are more answers for griddown form than there are repeat titles,
  // reduce that list to match the number of titles.
  if (
    layout === 'griddown' &&
    !includebuttons &&
    repeatTitles.length &&
    formModel?.hasOwnProperty(fieldArrayKey)
  ) {
    const knownAnswers = formModel[fieldArrayKey] as Record<string, string>[];
    if (knownAnswers?.length > repeatTitles.length) {
      formModel[fieldArrayKey] = knownAnswers.slice(0, repeatTitles.length);
    }
  }

  const repeatComponent = mapToCustomRepeatComponent(parentKey);
  const additionalRepeatGroupProps = mapToCustomRepeatProps(parentKey);

  return {
    key: fieldArrayKey,
    type: repeatComponent,
    fieldArray: {
      fieldGroup,
      fieldGroupClassName: 'row',
    },
    modelOptions: {
      updateOn: 'change',
    },
    expressions: {
      // For field arrays the model is accessible through a parent property.
      // See https://github.com/ngx-formly/ngx-formly/issues/704#issuecomment-364661614
      hide: (fieldConfig: FormlyFieldConfig) =>
        Object.values(parent.usage).some(
          (usage) =>
            !evalExpression<boolean | null>(usage, fieldConfig.parent?.model),
        ),
    },
    hooks: {
      onInit: (fieldConfig: FormlyFieldConfig) => {
        // If there are no known answers to this repeat group,
        // save its initial value (empty array).
        if (!formModel?.hasOwnProperty(parentKey)) {
          fieldConfig.formControl?.markAsDirty();
          fieldConfig.formControl?.updateValueAndValidity();
        }
        return listenToFieldArrayPropertyChanges(fieldConfig);
      },
    },
    props: {
      ...additionalRepeatGroupProps,
      enableSummary,
      includebuttons,
      label,
      layout,
      repeatTitles,
      titles,
    },
    validators: {
      repeatVerify: {
        expression: (formArray: FormArray) => formArray?.value?.length,
      },
    },
  };
}

function mapToRepeaterFlatFieldArray(
  { grouprepeat, repeatcontext, repeattitleexpression }: QuestionnaireGroup,
  children: Record<string, QuestionnaireVariable>,
  formModel: QuestionnaireFormModel,
): FormlyFieldConfig {
  const fieldArrayKey = grouprepeat!.concat(REPEAT_GROUP_SUFFIX);
  const repeatRows = repeatcontext || 0;

  const repeatTitles: Array<string | string[]> = [];
  if (repeattitleexpression) {
    for (let i = 1; i <= repeatRows; i++) {
      const title =
        evalExpression<string[]>(repeattitleexpression, formModel, i) || [];
      repeatTitles.push(title.join(''));
    }
  }

  const fieldGroup = Object.entries(children).map(([key, variable]) =>
    mapToFieldConfig(key, variable, true, false, formModel),
  );

  // If there are more answers than there are repeat rows,
  // reduce that list to match the number of rows.
  if (formModel?.hasOwnProperty(fieldArrayKey)) {
    const knownAnswers = formModel[fieldArrayKey] as Record<string, string>[];
    if (knownAnswers?.length > repeatRows) {
      formModel[fieldArrayKey] = knownAnswers.slice(0, repeatcontext);
    }
  }

  return {
    key: fieldArrayKey,
    type: FlatRepeatGroupComponent,
    fieldArray: {
      fieldGroup,
    },
    modelOptions: {
      updateOn: 'change',
    },
    props: {
      repeatTitles,
    },
  };
}

function mapToFieldConfig(
  key: number | string,
  variable: QuestionnaireVariable,
  required: boolean,
  repeater = false,
  model?: QuestionnaireFormModel,
): FieldTypeConfig {
  const { datatype, depth, inputmethod } = variable;

  const fieldConfig = getDefaultFieldConfig(
    key,
    variable,
    required,
    repeater,
    model,
  );
  if (inputmethod === 'ButtonList' && datatype === 'StringList') {
    return merge(fieldConfig, getMultiCheckboxFieldConfig(variable));
  } else if (inputmethod === 'ButtonList') {
    return merge(fieldConfig, getRadioFieldConfig(variable));
  } else if (datatype === 'Date') {
    return merge(fieldConfig, getDateFieldConfig(variable));
  } else if (inputmethod === 'Checkbox') {
    return merge(fieldConfig, getCheckboxFieldConfig(variable, model));
  } else if (datatype === 'Float' || datatype === 'Integer') {
    return merge(fieldConfig, getNumericFieldConfig(variable));
  } else if (inputmethod === 'EditBox' && depth) {
    return merge(fieldConfig, getTextAreaFieldConfig(variable));
  } else if (inputmethod === 'FileUpload') {
    return merge(fieldConfig, getFileUploadFieldConfig());
  } else if (inputmethod === 'SelectList') {
    return merge(fieldConfig, getSelectFieldConfig(variable, model));
  }

  return fieldConfig;
}

function listenToFieldArrayPropertyChanges(field: FormlyFieldConfig) {
  return field.options?.fieldChanges?.pipe(
    filter((event: FormlyValueChangeEvent) => {
      return (
        event.type === 'expressionChanges' &&
        event.field === field &&
        event['property'] === 'hide' &&
        event.value === true
      );
    }),
    tap(() => {
      const formControl = field.formControl as FormArray;
      while (formControl.length > 1) {
        formControl.removeAt(formControl.length - 1, { emitEvent: false });
      }
      if (formControl.length) {
        Object.keys(formControl.at(0).getRawValue()).forEach((key) => {
          formControl.at(0).get(key)?.setValue('', { emitEvent: false });
        });
      }
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
    }),
  );
}

function mapToCustomRepeatComponent(key: keyof typeof customRepeatGroups) {
  if (Object.keys(customRepeatGroups).includes(key)) {
    return customRepeatGroups[key].component;
  }

  return RepeatGroupComponent;
}

function mapToCustomRepeatProps(key: keyof typeof customRepeatGroups) {
  if (Object.keys(customRepeatGroups).includes(key)) {
    const { columns, labels, repeatName, summary } = customRepeatGroups[key];
    return { columns, labels, repeatName, summary };
  }

  return {};
}
