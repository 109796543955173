<div class="input-group" style="width: 160px">
  <span *ngIf="hasCurrencyPrefix" class="input-group-text">$</span>
  <input
    type="text"
    [formControl]="formControl"
    class="form-control"
    [formlyAttributes]="field"
    [class.is-invalid]="showError"
    mask="separator"
    thousandSeparator=","
  />
</div>
