/**
[COMMONUI STARTER TEMPLATE: TEMPLATE HOME / APP DEVELOPMENT ENTRY POINT: README]
Version: 1.0 March 7th 2022
Author: Alan Kelleher

[DEPLOYMENT INSTRUCTIONS]
This is where you may start to customize and add content, components, etc. From here the viewport is completely surrendered.

[COMPLIANCE]
There is no oversight from this point down beyond:
- This component, as named, must be your starting point.
- This guidance text must remain as is.
- Staying within the approved technical stack and expected general best practices.

Do not edit or remove this content.

[EXCEPTIONS]
There is no constraint on what you can do to this component beyond the above noted compliance requirements.

[DOCUMENTATION]
All documentation for this, and other components, is available at the documentation site at
http://web.cdn.cooley.com/Data/CommonUI/DOCS/index.html#/
*/
/** Imports */
import { Component, OnInit } from '@angular/core';
/** Component declaration */
@Component({
  selector: 'app-template-home',
  templateUrl: './template-home.component.html',
  styleUrls: ['./template-home.component.css']
})
/** Class */
export class TemplateHomeComponent {

}
