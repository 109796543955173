/** Imports */
import { Component, OnInit } from '@angular/core';
import { templateFooterMenuService } from '../../_framing/_services/template-footer-menu.service';
/** Component declaration */
@Component({
  selector: 'app-base-footer-appmenu',
  templateUrl: './base-footer-appmenu.component.html',
  styleUrls: ['./base-footer-appmenu.component.css']
})
/** Class */
export class BaseFooterAppmenuComponent implements OnInit {

  /** Demo menu */
  public footerLinksApp: any;

  /** Construct */
  constructor(
    private templateFooterMenuService: templateFooterMenuService
  ) {
    //
  }

  /** Lifecycle */
  ngOnInit(): void {
    /**
     * App specific displayed links.
     * All open in the same page and can be href or route links.
     * */
    this.footerLinksApp = {
      "data": [
        {
          "text": "Home",
          "route": "home",
        },
        {
          "text": "Help",
          "route": "help",
        },
      ]
    }

    this.setFooterLinksAppMenu();
  }

  /** setFooterLinksAppMenu */
  private setFooterLinksAppMenu() {
    try {
      this.templateFooterMenuService.footerMenuData = this.footerLinksApp;
    } catch (error) {
      console.log(error);
    }
  }

}
