import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { delay } from 'lodash';
import { ToastData } from 'src/app/models/interfaces';
import { ToastComponent } from '../../components/toast/toast.component';

export const TOAST_DATA = new InjectionToken<{}>('TOAST_DATA');

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  show(useValue: ToastData, hideDelay: number = 30000): void {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: TOAST_DATA, useValue }],
    });
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);
    const overlayRef = this.overlay.create();
    overlayRef.attach(toastPortal);

    delay(() => overlayRef.dispose(), hideDelay);
  }
}
