import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { Roles } from 'src/app/models/enums';
import { Person, Project } from 'src/app/models/interfaces';
import { ActiveProjectService, CurrentUserService } from '..';

@Injectable({
  providedIn: 'root',
})
export class ActiveProjectUserRoleService {
  constructor(
    private activeProjectService: ActiveProjectService,
    private currentUserService: CurrentUserService,
  ) {}

  get isClientAdmin$(): Observable<boolean> {
    return combineLatest([
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(
        ([project, user]) =>
          project?.projectPeople.some(
            ({ personId, role }) =>
              personId === user?.id && role?.roleName === Roles.ClientAdmin,
          ) || false,
      ),
    );
  }

  get isClientCollaborator$(): Observable<boolean> {
    return combineLatest([
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(
        ([project, user]) =>
          project?.projectPeople.some(
            ({ personId, role }) =>
              personId === user?.id &&
              role?.roleName === Roles.ClientCollaborator,
          ) || false,
      ),
    );
  }

  get isCooleyUser$(): Observable<boolean> {
    return combineLatest([
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([project, user]) => {
        // If currentUser is AppAdmin, assign them a CooleyUser role.
        if (user?.appRole === 'AppAdmin') {
          return true;
        }

        return (
          project?.projectPeople.some(
            ({ personId, role }) =>
              personId === user?.id && role?.roleName === Roles.CooleyUser,
          ) || false
        );
      }),
    );
  }

  get isRespondent$(): Observable<boolean> {
    return combineLatest([
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(
        ([project, user]) =>
          project?.projectPeople.some(
            ({ personId, role }) =>
              personId === user?.id && role?.roleName === Roles.Respondent,
          ) || false,
      ),
    );
  }

  get projectPersonId(): string | null {
    const project = this.activeProjectService.project;
    const user = this.currentUserService.currentUser;
    return (
      user?.projectPeople.find(({ projectId }) => projectId === project?.id)
        ?.id || null
    );
  }

  get projectPersonId$(): Observable<string | null> {
    return combineLatest([
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(
        ([project, user]) =>
          user?.projectPeople.find(({ projectId }) => projectId === project?.id)
            ?.id || null,
      ),
    );
  }

  /**
   * DEPRECATED: RespondentId should no longer be derived from the current user. Instead it should be a URL parameter.
   */
  // get respondentId(): string | null {
  //   const project = this.activeProjectService.project;
  //   const user = this.currentUserService.currentUser;

  //   return this.getRespondentId(project, user);
  // }

  // get respondentId$(): Observable<string | null> {
  //   return combineLatest([
  //     this.activeProjectService.project$,
  //     this.currentUserService.currentUser$,
  //   ]).pipe(map(([project, user]) => this.getRespondentId(project, user)));
  // }

  get roles$(): Observable<Roles[]> {
    return combineLatest([
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([project, currentUser]) => {
        if (!project) {
          return [];
        }

        // If currentUser is AppAdmin, assign them a CooleyUser role.
        if (currentUser?.appRole === 'AppAdmin') {
          return [Roles.CooleyUser];
        }

        return project.projectPeople
          .filter(({ personId }) => personId === currentUser?.id)
          .map(({ role }) => role!.roleName);
      }),
    );
  }

  private getRespondentId(project?: Project | null, user?: Person | null) {
    const currentProjectRole = user?.projectPeople.find(
      ({ projectId }) => projectId === project?.id,
    );

    if (!currentProjectRole) {
      return null;
    }

    if (currentProjectRole.respondent?.id) {
      return currentProjectRole.respondent.id;
    }

    if (
      currentProjectRole.role?.roleName === Roles.RespondentDelegate &&
      currentProjectRole.delegates?.length
    ) {
      const delegate = currentProjectRole.delegates.find(
        (delegate) => delegate.personId === currentProjectRole.personId,
      );

      if (delegate) {
        return delegate.respondentId;
      }
    }

    return null;
  }
}
