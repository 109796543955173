/** Imports */
import { Component, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { templateHeaderMenuService } from '../_services/template-header-menu.service';
import { TemplateHeaderService } from '../_services/template-header.service';
/** Component declaration */
@Component({
  selector: 'app-template-header',
  templateUrl: './template-header.component.html',
  styleUrls: ['./template-header.component.css'],
})
/** Class */
export class TemplateHeaderComponent implements OnInit {
  showHeader$ = this.templateHeaderService.showHeader$;
  /** Boolean to display when ready */
  public showHeader = true;
  /** Displayed title string */
  @Output() topNavMenu = {};
  /** Env data object for header */
  @Output() env = {};
  /** Error condition menu */
  public errorConditionMenu: any;
  /** Display help */
  public displayHelp = true;

  /** Constructor */
  constructor(
    private templateHeaderMenuService: templateHeaderMenuService,
    private templateHeaderService: TemplateHeaderService
  ) {
    /** Display help? */
    this.displayHelp = environment.appSpecifics.displayTopHelp.display;
    try {
      this.templateHeaderMenuService._topMenuData$.subscribe((value) => {
        if (value) {
          this.processTopMenu(value);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /** Lifecycle */
  ngOnInit(): void {
    this.env = environment;
    this.errorConditionMenu = {
      data: [
        {
          label: 'Error condition menu',
          showLabel: false,
          data: [
            {
              text: 'An error occured fetching the top menu',
              route: 'home',
            },
          ],
        },
      ],
    };
  }

  /** processTopMenu */
  private processTopMenu(menu: any) {
    try {
      /** Set the top menu */
      this.topNavMenu = menu;
      /** Show the header */
      this.showHeader = true;
    } catch (error) {
      console.log(error);
      /** Display the error in the header */
      this.topNavMenu = this.errorConditionMenu;
      /** Show the header. */
      this.showHeader = true;
    }
  }
}
