<div
  class="position-fixed bottom-0 start-0 px-3"
  [class.d-none]="closed$ | async"
>
  <div
    class="alert"
    [ngClass]="{
      'alert-primary': data.type === 'info',
      'alert-success': data.type === 'success'
    }"
    role="alert"
    style="border-left-width: 4px; width: 17.5rem"
  >
    <button
      type="button"
      class="btn-close float-end"
      aria-label="Close"
      style="margin: -1rem"
      (click)="close()"
    ></button>
    <div class="d-flex pe-3">
      <div class="me-2">
        <svg
          *ngIf="data.type === 'info'"
          ibmIconInformationFilled
          class="text-primary me-1"
          fill="currentColor"
          size="16"
        ></svg>
        <svg
          *ngIf="data.type === 'success'"
          ibmIconCheckmarkFilled
          class="text-success me-1"
          fill="currentColor"
          size="16"
        ></svg>
      </div>
      <div class="text-break">
        <div class="fw-bold fs-5">{{ data.header }}</div>
        <div class="fs-5" [innerHTML]="data.text"></div>
      </div>
    </div>
  </div>
</div>
