import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldInput } from '@ngx-formly/bootstrap/input';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, of, Subject, Subscription, switchMap } from 'rxjs';
import {
  ActivePageService,
  ActiveProjectService,
  ResourcesService,
} from 'src/app/core/services';
import { Resource, RespondentPage } from 'src/app/models/interfaces';

interface Option {
  id: string;
  name?: string | null;
}

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
})
export class FileInputComponent extends FormlyFieldInput implements OnDestroy {
  uploadForm = new FormGroup({
    resource: new FormControl<Resource | null>(null),
    uploadFile: new FormControl<File | null>(null),
    applyTo: new FormControl<'single' | 'multiple'>('single', {
      nonNullable: true,
    }),
    respondents: new FormControl<string[]>([], {
      nonNullable: true,
    }),
  });
  modalRef?: BsModalRef;
  resources$ = this.activeProjectService.project$.pipe(
    switchMap((project) =>
      project ? this.resourcesService.getProjectResources(project.id) : of([])
    ),
    map((resources) =>
      resources.filter(({ sourceUploadId }) => sourceUploadId === null)
    )
  );
  respondents$ = this.activePageService.respondentPages$.pipe(
    map((pages) => this.mapRespondentPagesToOptions(pages))
  );
  submitting$ = new Subject<boolean>();
  subscription = new Subscription();

  get fieldValue(): string {
    return this.model[this.field.key as string];
  }
  set fieldValue(value: string) {
    this.model[this.field.key as string] = value;
  }

  get formDisabled(): boolean {
    const { resource, uploadFile } = this.uploadForm.value;
    return !(resource || uploadFile);
  }

  get respondentsControl() {
    return this.uploadForm.controls.respondents;
  }

  get showFileUpload(): boolean {
    const { value } = this.uploadForm.controls.resource;
    return value && !value.id ? true : false;
  }

  get showRespondentsList(): boolean {
    return this.uploadForm.controls.applyTo.value === 'multiple';
  }

  constructor(
    private activePageService: ActivePageService,
    private activeProjectService: ActiveProjectService,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private resourcesService: ResourcesService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeModal(): void {
    this.modalRef?.hide();
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length) {
      const file = target.files[0];
      this.uploadForm.controls.uploadFile.setValue(file);
    }
  }

  openFile(uploadId?: string) {
    const project = this.activeProjectService.project;
    if (project) {
      const resourceId$ = uploadId
        ? of(uploadId)
        : this.resourcesService
            .getProjectResources(project.id)
            .pipe(
              map(
                (resources) =>
                  resources.find(({ fileName }) => fileName === this.fieldValue)
                    ?.id
              )
            );
      resourceId$
        .pipe(
          switchMap((id) =>
            id ? this.resourcesService.downloadResource(id) : of(null)
          )
        )
        .subscribe((data) => {
          if (data) {
            const fileURL = URL.createObjectURL(data);
            window.open(fileURL, '_blank');
          }
        });
    }
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  remove(): void {
    this.formControl.markAsDirty();
    this.formControl.setValue('');
  }

  submitForm(): void {
    this.submitting$.next(true);

    const { applyTo, resource, respondents, uploadFile } =
      this.uploadForm.getRawValue();
    const questionVariableName = this.field.key!.toString();
    const respondentId = this.route.snapshot.paramMap.get(
      'respondentId'
    ) as string;
    const respondentIds =
      applyTo === 'multiple'
        ? Array.from(new Set(respondents?.concat([respondentId])))
        : [respondentId];
    if (uploadFile) {
      const formData = new FormData();
      formData.append('file', uploadFile, uploadFile.name);
      respondentIds.forEach((id) => formData.append('respondentIds[]', id));
      if (questionVariableName) {
        formData.append('questionVariableName', questionVariableName);
      }
      this.subscription.add(
        this.resourcesService.postResource(formData).subscribe(() => {
          this.fieldValue = uploadFile.name;
          this.cdr.detectChanges();
          this.closeModal();
        })
      );
    } else if (resource) {
      this.subscription.add(
        this.resourcesService
          .applyExhibits(resource.id, respondentIds, questionVariableName)
          .subscribe(() => {
            this.fieldValue = resource.fileName;
            this.cdr.detectChanges();
            this.closeModal();
          })
      );
    }
  }

  toggleRespondent(event: Event, respondentId: string): void {
    const element = event.target as HTMLInputElement;
    const respondents = new Set(this.respondentsControl.value);
    if (element.checked) {
      respondents.add(respondentId);
    } else {
      respondents.delete(respondentId);
    }
    this.respondentsControl.setValue(Array.from(respondents));
  }

  private mapRespondentPagesToOptions(pages: RespondentPage[]) {
    return pages.reduce<Option[]>((relevant, { cePageStatus, respondent }) => {
      if (cePageStatus !== 'irrelevant') {
        relevant.push({
          id: respondent.id,
          name: respondent.respondentEntityName || respondent.person?.name,
        });
      }

      return relevant;
    }, []);
  }
}
