import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type SigningProgress = [number, number];
type SigningStage = 'loaded' | 'signing' | 'complete' | null;

@Injectable({
  providedIn: 'root',
})
export class SigningService {
  private _progress = new BehaviorSubject<SigningProgress | null>(null);
  get progress$() {
    return this._progress.asObservable();
  }
  get progress() {
    return this._progress.getValue();
  }
  set progress(value: SigningProgress | null) {
    this._progress.next(value);
  }

  private _stage = new BehaviorSubject<SigningStage>(null);
  get stage$() {
    return this._stage.asObservable();
  }
  get stage() {
    return this._stage.getValue();
  }
  set stage(value: SigningStage) {
    this._stage.next(value);
  }

  signedBlob: Blob | null = null;

  constructor() {}
}
