<div class="container-fluid">
  <div class="row">
    <div class="col-11">
      <h1 class="text-primary">"{{ displayedSearchString }}"</h1>
    </div>
    <div class="col-1">
      <button type="button" class="btn-close"aria-label="Close" (click)="closeSearchDisplay();"></button>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <app-base-search-body [searchTerm]="displayedSearchString"></app-base-search-body>
    </div>
  </div>
</div>
